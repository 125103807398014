import { Box, TextField, styled } from "@mui/material";

export const ReportItemBlock = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export const StylesReportTextField = styled(TextField)({
  width: "30%",
  height: "10px",

  "& .MuiInputBase-input": {
    padding: "0 4px",

    textAlign: "right",
    WebkitAppearance: "textfield",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});
