import React from "react";

const DataPickerIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.22727 4.5C5.72104 4.5 4.5 5.72104 4.5 7.22727V16.7727C4.5 18.279 5.72104 19.5 7.22727 19.5H16.7727C18.279 19.5 19.5 18.279 19.5 16.7727V7.22727C19.5 5.72104 18.279 4.5 16.7727 4.5H7.22727ZM7.6108 8.59091C6.85768 8.59091 6.24716 9.20143 6.24716 9.95455V16.3892C6.24716 17.1423 6.85768 17.7528 7.6108 17.7528H16.3892C17.1423 17.7528 17.7528 17.1423 17.7528 16.3892V9.95455C17.7528 9.20143 17.1423 8.59091 16.3892 8.59091H7.6108Z"
        fill="#9BA2BF"
      />
      <rect
        x="7.92773"
        y="10.4446"
        width="3.5475"
        height="2.04545"
        rx="1.02273"
        fill="#9BA2BF"
      />
      <rect
        x="12.5254"
        y="10.4446"
        width="3.5475"
        height="2.04545"
        rx="1.02273"
        fill="#9BA2BF"
      />
      <rect
        x="7.92773"
        y="13.9901"
        width="3.5475"
        height="2.04545"
        rx="1.02273"
        fill="#9BA2BF"
      />
      <rect
        x="12.5254"
        y="13.9901"
        width="3.5475"
        height="2.04545"
        rx="1.02273"
        fill="#9BA2BF"
      />
    </svg>
  );
};

export default DataPickerIcon;
