import Nav from "./nav";
import { Stack } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import { Main } from "./styles";
import { useContext } from "react";
import Loader from "../../components/common/loader";
import { UserCTX } from "../../contexts/userContext/UserCTX";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";

const DashboardLayout = () => {
  const { isUserLoggedIn } = useContext(UserCTX);
  const { loading } = useContext(globalDataCTX);

  return (
    <Stack direction={"row"}>
      {!isUserLoggedIn &&  <Navigate to="/" />}
      <Nav />
      <Main>
        {loading && <Loader />}
        <Outlet />
      </Main>
    </Stack>
  );
};

export default DashboardLayout;
