import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Enum_inputs, ipDataForUpdateType } from "./types";
import { useMutation } from "@apollo/client";
import { ADD_IP_TO_WHITE_LIST } from "../../graphql/ADD_IP_TO_WHITE_LIST";
import {
  addIPToWhiteList,
  addIPToWhiteListVariables,
} from "../../graphql/__generated__/addIPToWhiteList";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";
import { useLocation } from "react-router-dom";
import {
  updateWhiteListIP,
  updateWhiteListIPVariables,
} from "../../graphql/__generated__/updateWhiteListIP";
import { UPDATE_WHITE_LIST_IP } from "../../graphql/UPDATE_WHITE_LIST_IP";
import { useKeyboardEvent } from "../../../../utils/useKeyboard";

export const useAddIpToWhiteList = () => {
  const location = useLocation();

  const ipDataForUpdate = location?.state
    ?.ipForUpdate as ipDataForUpdateType | null;

  const { setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);

  const [reason, setreason] = useState(
    ipDataForUpdate ? ipDataForUpdate.reason : ""
  );
  const [allowedAccountCount, setAllowedAccountCount] = useState(
    ipDataForUpdate ? ipDataForUpdate.allowedAccountCount : "2"
  );

  const [ip, setIp] = useState(ipDataForUpdate ? ipDataForUpdate.ip : "");

  const [
    addIpToWhiteListMutation,
    { data: creatData, error: createIpError, loading: createIpLoading },
  ] = useMutation<addIPToWhiteList, addIPToWhiteListVariables>(
    ADD_IP_TO_WHITE_LIST
  );

  const [
    updateIpData,
    { data: dataUpdate, loading: updateLoading, error: updateError },
  ] = useMutation<updateWhiteListIP, updateWhiteListIPVariables>(
    UPDATE_WHITE_LIST_IP
  );

  useEffect(() => {
    if (
      (creatData && !createIpError && !createIpLoading) ||
      (dataUpdate && !updateLoading && !updateError)
    ) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg(
        `IP-адрес успешно ${creatData ? "добавлен" : "обновлен"}.`
      );
      creatData && resetInputsValues();
    }
    if (createIpError || updateError) {
      const errorText = createIpError?.message || updateError?.message;
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(errorText || "");
    }
  }, [
    creatData,
    createIpError,
    createIpLoading,
    dataUpdate,
    setNotificationEvent,
    setNotificationMsg,
    updateError,
    updateLoading,
  ]);

  const isDisabledButton = reason && ip;

  const resetInputsValues = () => {
    setreason("");
    setIp("");
  };

  const addIpToWhiteListHandler = () => {
    addIpToWhiteListMutation({
      variables: {
        input: {
          allowedAccountCount: Number(allowedAccountCount),
          ip,
          reason,
        },
      },
    });
  };

  const updateIpDataHandler = () => {
    ipDataForUpdate &&
      updateIpData({
        variables: {
          input: {
            id: ipDataForUpdate?.id,
            allowedAccountCount: Number(allowedAccountCount),
            reason,
          },
        },
      });
  };

  const onChangeInputsHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    input: Enum_inputs
  ) => {
    switch (input) {
      case Enum_inputs.reason:
        setreason(event.target.value);
        break;
      case Enum_inputs.allowed_account_count:
        setAllowedAccountCount(event.target.value);
        break;
      case Enum_inputs.ip:
        setIp(event.target.value);
        break;
      default:
        break;
    }
  };

  useKeyboardEvent(
    "Enter",
    ipDataForUpdate ? updateIpDataHandler : addIpToWhiteListHandler
  );

  return {
    allowedAccountCount,
    reason,
    ip,
    onChangeInputsHandler,
    addIpToWhiteListHandler,
    updateIpDataHandler,
    isDisabledButton,
    isUpdateFlag: !!ipDataForUpdate,
  };
};
