import { mockDataPromoCodesHeaderTypes } from "./types";

export const mockDataPromoCodesHeader: mockDataPromoCodesHeaderTypes[] = [
  {
    name: "name",
  },
  {
    name: "amount",
  },
  {
    name: "type",
  },
  {
    name: "affected objects",
  },
  {
    name: "formula",
  },
  {
    name: "lifetime Period",
  },
];
