import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { FC } from "react";
import { GroupAvatarsProps } from "./types";

const GroupAvatars: FC<GroupAvatarsProps> = ({ selectedUsers }) => {
  return (
    <AvatarGroup max={3}>
      {selectedUsers?.map((user) => (
        <Avatar key={user.user.id} alt="image name" src={user.user.image.onex} />
      ))}
    </AvatarGroup>
  );
};

export default GroupAvatars;
