import React, { FC } from "react";
import {
  StyledOutlinedInput,
  StyledSearchBlock,
  StyledSearchPanelWrapper,
} from "../../../../styles/common/styles";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { SearchPaymentPanelProps } from "./types";
import {
  PaymentMethod,
  PaymentProvider,
  PaymentState,
} from "../../../../globalTypes";
import { acceptOnlyNumbers } from "../../../../utils/numbersUtils";

const SearchPaymentPanel: FC<SearchPaymentPanelProps> = ({
  searchId,
  loadingPage,
  setSearchId,
  getPaymentsHandler,
  searchPaymentType,
  setSearchPaymentType,
  resetSearchFilters,
  paymentStatus,
  setPaymentStatus,
  paymentMethod,
  setPaymentMethod,
}) => {
  const handlerSearchByID = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchId(event.target.value);
  };

  function handleChange<T>(
    event: SelectChangeEvent,
    callback: (value: T | null) => void
  ) {
    callback(event.target.value as T | null);
  }

  return (
    <StyledSearchPanelWrapper>
      <StyledSearchBlock>
        <StyledOutlinedInput
          placeholder="Поиск по ID клиента"
          onChange={(event) => handlerSearchByID(event)}
          value={searchId}
          onInput={acceptOnlyNumbers}
          endAdornment={
            <InputAdornment position="end">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputAdornment>
          }
        />

        <Box sx={{ minWidth: 220 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Оплата</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={searchPaymentType || ""}
              label="Оплата"
              sx={{ borderRadius: "12px" }}
              onChange={(event) =>
                handleChange<PaymentProvider>(event, setSearchPaymentType)
              }
            >
              <MenuItem value={PaymentProvider.SomPayments}>
                {PaymentProvider.SomPayments}
              </MenuItem>
              <MenuItem value={PaymentProvider.CloudPayments} disabled>
                {PaymentProvider.CloudPayments}
              </MenuItem>
              <MenuItem value={PaymentProvider.SoftlinePayments}>
                {PaymentProvider.SoftlinePayments}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ minWidth: 220 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Статус оплаты</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={paymentStatus || ""}
              label="Статус оплаты"
              sx={{ borderRadius: "12px" }}
              onChange={(event) =>
                handleChange<PaymentState>(event, setPaymentStatus)
              }
            >
              <MenuItem value={PaymentState.Created}>
                {PaymentState.Created}
              </MenuItem>
              <MenuItem value={PaymentState.Failed}>
                {PaymentState.Failed}
              </MenuItem>
              <MenuItem value={PaymentState.InProgress}>
                {PaymentState.InProgress}
              </MenuItem>
              <MenuItem value={PaymentState.Success}>
                {PaymentState.Success}
              </MenuItem>
              <MenuItem value={PaymentState.Refunded}>
                {PaymentState.Refunded}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ minWidth: 220 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Способ оплаты</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={paymentMethod || ""}
              label="Способ оплаты"
              sx={{ borderRadius: "12px" }}
              onChange={(event) =>
                handleChange<PaymentMethod>(event, setPaymentMethod)
              }
            >
              <MenuItem value={PaymentMethod.NewCard}>
                {PaymentMethod.NewCard}
              </MenuItem>
              <MenuItem value={PaymentMethod.SavedCard}>
                {PaymentMethod.SavedCard}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Button onClick={resetSearchFilters} disabled={loadingPage}>Сбросить</Button>
        <Button variant="contained" color="info" onClick={getPaymentsHandler} disabled={loadingPage}>
          Поиск
        </Button>
      </StyledSearchBlock>
    </StyledSearchPanelWrapper>
  );
};

export default SearchPaymentPanel;
