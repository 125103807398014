import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DataPickerIcon from "../../../../../../assets/icons/DataPickerIcon";

const DateTimeEditor = ({ onValueChange, values, valuesKey }: any) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        views={["year", "day", "hours", "minutes"]}
        inputFormat={"MMM DD.YYYY HH:mm:ss"}
        components={{
          OpenPickerIcon: DataPickerIcon,
          LeftArrowButton: undefined,
          RightArrowButton: undefined,
        }}
        renderInput={(props) => <TextField {...props} />}
        value={values || null}
        ampm={false}
        onChange={(newValue) => {
          onValueChange(valuesKey, new Date(newValue), true);
        }}
        disableMaskedInput
      />
    </LocalizationProvider>
  );
};

export default DateTimeEditor;
