import { useLazyQuery, useQuery } from "@apollo/client"
import { GET_AFFISE_OFFERS } from "../../graphql/GET_AFFISE_OFFERS"
import { getAffiseOffersForSubPage } from "../../graphql/__generated__/getAffiseOffersForSubPage"
import { SelectChangeEvent } from "@mui/material"
import { useContext, useState, useEffect, useMemo } from "react"
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX"
import { notificationEventType } from "../../../../contexts/globalContext/types"
import { bindAffisePromocodeInput } from "../../../../globalTypes"
import { BIND_PROMOCODE_TO_AFFISE } from "../../graphql/BIND_PROMOCODE_TO_AFFISE.graphql"
import { bindPromocodeToAffiseVariables } from "../../graphql/__generated__/bindPromocodeToAffise"
import { useLocation } from "react-router-dom"

export const useSubPagePrompJoinAffise = () => {
    const { state } = useLocation();
    const promocodeForAffise = state.code;

    const { data: dataEffiseOffers } = useQuery<getAffiseOffersForSubPage>(GET_AFFISE_OFFERS)

    const { setNotificationEvent, setNotificationMsg } =
        useContext(globalDataCTX);
    const [offerID, setOfferID] = useState("");
    const [affiliateId, setaffiliateId] = useState("");

    const [bindPromocodeToAffise, { error, loading }] = useLazyQuery<
        bindAffisePromocodeInput,
        bindPromocodeToAffiseVariables
    >(BIND_PROMOCODE_TO_AFFISE);

    const handleChange = (event: SelectChangeEvent, typeOnChange: string) => {
        if (typeOnChange === "offerID") {
            setOfferID(event.target.value as string);
        } else {
            setaffiliateId(event.target.value as string);
        }
    };

    useEffect(() => {
        if (error) {
            setNotificationEvent(notificationEventType.error);
            setNotificationMsg(error.message);
        }
    }, [error, setNotificationEvent, setNotificationMsg]);

    const joinAffiseToPromoCode = () => {
        if (!promocodeForAffise) {
            return;
        }

        bindPromocodeToAffise({
            variables: {
                input: {
                    promocode: promocodeForAffise,
                    offerId: offerID,
                    affiliateId: affiliateId,
                },
            },
        });

        setOfferID("");
        setaffiliateId("");
    };

    const getPartnersForOffer = useMemo(() => {
        return (
            offerID &&
            dataEffiseOffers?.getAffiseOffers?.filter((dataAffise) => dataAffise.Id === offerID)
        );
    }, [dataEffiseOffers, offerID]);

    const countOfPartners =
        (getPartnersForOffer && getPartnersForOffer[0].AllowedAffiliates?.length) ||
        0;

    const countOfOffer = dataEffiseOffers?.getAffiseOffers?.length || 0;


    return {
        dataEffiseOffers: dataEffiseOffers?.getAffiseOffers,
        countOfOffer,
        offerID,
        handleChange,
        countOfPartners,
        affiliateId,
        getPartnersForOffer,
        joinAffiseToPromoCode,
        loading
    }
}