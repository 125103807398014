import React, { FC } from "react";
import { InputLabel, MenuItem, FormControl } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BonusTypesSelectProps } from "./types";

const SelectBonusType: FC<BonusTypesSelectProps> = ({
  setFieldValue,
  valuesKey,
  initialValue,
  dataBonuses,
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    setFieldValue(valuesKey, event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Имя бонуса</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={initialValue}
        label="Имя бонуса"
        onChange={handleChange}
      >
        {dataBonuses?.getBonuses.map((bonus) => {
          return (
            <MenuItem key={bonus?.id} value={bonus?.id}>
              {bonus?.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default SelectBonusType;
