import React from "react";
import { useButtonTemplates } from "./useButtonTemplates";
import { HeaderTitle } from "../../styles/common/styles";
import { Box, Card, CardContent, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomButtonWithDelete from "./components/customButtonWithDelete";
import { useNavigate } from "react-router-dom";

const ButtonTemplates = () => {
  const { templatesData, deleteButtonTemplate } = useButtonTemplates();
  const navigate = useNavigate();
  return (
    <Box>
      <HeaderTitle>Шаблоны кнопок платежной формы</HeaderTitle>
      <Box mt={5}>
        {templatesData?.map(
          ({ ID, buttons, type }) => {
            return (
              <Card sx={{ mb: 2, p: 2 }} key={ID}>
                <Typography fontWeight={600} textTransform={"capitalize"} fontSize={25}>
                  {type}
                </Typography>
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box display={"grid"} gridTemplateColumns={"repeat(5, auto)"} gap={2}>
                    {buttons?.map((btn) => {
                      return (
                        <Box
                          key={btn.id}
                          onClick={() =>
                            navigate("update", {
                              state: {
                                buttonForUpdate: {
                                  ID,
                                  type,
                                  buttonData: btn,
                                },
                              },
                            })
                          }
                        >
                          <CustomButtonWithDelete
                            btnTitle={`${btn.minutesAmount || btn.moneyAmount
                              } ${btn.minutesAmount ? "мин." : "руб."}`}
                            onDelete={() => deleteButtonTemplate(btn.id)}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    onClick={() =>
                      navigate("create", {
                        state: { createButtonData: { ID, type } },
                      })
                    }
                  >
                    <AddCircleOutlineIcon
                      color="info"
                      fontSize="medium"
                      sx={{ cursor: "pointer" }}
                    />
                  </Box>
                </CardContent>
              </Card>
            );
          }
        )}
      </Box>
    </Box>
  );
};

export default ButtonTemplates;
