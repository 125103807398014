import React, { FC } from "react";
import { TableBody, TableRow } from "@mui/material";
import { StyledTableCellBody } from "./styles";
import { BonusTableBodyProps } from "./types";

const BonusTableBody: FC<BonusTableBodyProps> = ({ bonusesData }) => {
  return (
    <TableBody>
      {bonusesData?.getBonuses?.map((item, idx) => {
        return (
          <TableRow key={idx}>
            <StyledTableCellBody>{item?.name}</StyledTableCellBody>
            <StyledTableCellBody>{item?.amount}</StyledTableCellBody>
            <StyledTableCellBody>{item?.type}</StyledTableCellBody>
            <StyledTableCellBody>
              {item?.affectedObjects.join(", ")}
            </StyledTableCellBody>
            <StyledTableCellBody>{item?.formula}</StyledTableCellBody>
            <StyledTableCellBody>{item?.lifetimePeriod}</StyledTableCellBody>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default BonusTableBody;
