import React from "react";
import { Box, Button } from "@mui/material";
import BasicModal from "../../../common/modal";
import { ModalForDeleteCashBackProps } from "./types";
import { useModalForDeleteCashBack } from "./useModalForDeleteCashBack";

const ModalForDeleteCashBack = ({
  isOpenModal,
  toggleOpenCloseModal,
  idForDeleteRule,
  refetchGetCashBack,
}: ModalForDeleteCashBackProps) => {
  const { deleteCashBackHandler } = useModalForDeleteCashBack(
    idForDeleteRule,
    toggleOpenCloseModal,
    refetchGetCashBack
  );

  return (
    <BasicModal
      isOpen={isOpenModal}
      handelClose={toggleOpenCloseModal}
      title="Вы действительно хотите удалить ?"
    >
      <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
        <Button variant="contained" onClick={toggleOpenCloseModal}>
          Отменить
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={deleteCashBackHandler}
        >
          Удалить
        </Button>
      </Box>
    </BasicModal>
  );
};

export default ModalForDeleteCashBack;
