import headerNavDate from "./constants";
import { Box } from "@mui/material";
import { useTabPanel } from "./useTabPanel";
import {
  StyledTabsContainer,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "./styles";

const StatusTabs = () => {
  const { handleChange, statusFilter } = useTabPanel();

  return (
    <StyledTabsContainer>
      <Box>
        <StyledToggleButtonGroup
          value={statusFilter?.[0] || 0}
          exclusive
          onChange={handleChange}
          aria-label="text alignment"
        >
          {headerNavDate.map((item) => (
            <StyledToggleButton key={item.title} value={item.value || 0}>
              {item.icon}
              {item.title}
            </StyledToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Box>
    </StyledTabsContainer>
  );
};

export default StatusTabs;
