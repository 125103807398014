export const REFRESH_TOKEN = `
  mutation refreshToken($refreshToken: String!) {
    refreshToken(input: {refreshToken: $refreshToken}) {
      tokens {
        accessToken
        refreshToken
      }
    }
  }
`;
