import React, { useMemo, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { ExpertTrialSelectCheckmarksProps } from "./types";
import { Box, Button, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import {
  updateExpertTrialAllowanceParams,
  updateExpertTrialAllowanceParamsVariables,
} from "../../../../../graphql/__generated__/updateExpertTrialAllowanceParams";
import { UPDATE_TRIALS } from "../../../../../graphql/UPDATE_TRIALS.graphql";
import Done from "@mui/icons-material/Done";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ExpertTrialSelectCheckmarks = ({
  trialAllowance,
  expertId,
}: ExpertTrialSelectCheckmarksProps) => {
  const initialOptions = useMemo(
    () => [
      { id: 0, name: "Video", trial: trialAllowance?.video },
      { id: 1, name: "Chat", trial: trialAllowance?.chat },
      { id: 2, name: "Phone", trial: trialAllowance?.phone },
    ],
    [trialAllowance]
  );

  // Get initial selected options based on trialAllowance
  const initialSelected = React.useMemo(
    () =>
      initialOptions
        .filter((option) => option.trial)
        .map((option) => option.name),
    [initialOptions]
  );

  const [personName, setPersonName] = React.useState<string[]>(initialSelected);
  const [options, setOptions] = React.useState(initialOptions);

  const [
    updateExpertTrials,
    { data: dataUpdateExpertTrials, error: errorUpdateExpertTrials },
  ] = useMutation<
    updateExpertTrialAllowanceParams,
    updateExpertTrialAllowanceParamsVariables
  >(UPDATE_TRIALS);

  useEffect(() => {
    setOptions(initialOptions);
    setPersonName(initialSelected);
  }, [initialOptions, initialSelected]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === "string" ? value.split(",") : value;

    setPersonName(selectedValues);

    // Update the options to set trial to true for selected options
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        selectedValues.includes(option.name)
          ? { ...option, trial: true }
          : { ...option, trial: false }
      )
    );
  };

  const changeExpertTrials = () => {
    updateExpertTrials({
      variables: {
        input: {
          id: expertId,
          video: options[0].trial,
          chat: options[1].trial,
          phone: options[2].trial,
        },
      },
    });
  };

  return (
    <>
      <Box display={"flex"} mb={12} gap={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">Trial</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {options.map((option) => (
              <MenuItem
                key={option.id}
                value={option.name}
                disabled={option.id === 2}
              >
                <Checkbox checked={personName.indexOf(option.name) > -1} />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          fullWidth
          variant="contained"
          color={dataUpdateExpertTrials ? "success" : "info"}
          endIcon={dataUpdateExpertTrials && <Done />}
          onClick={changeExpertTrials}
        >
          {dataUpdateExpertTrials
            ? "Триал успешно изменён"
            : "сохранить триал "}
        </Button>
      </Box>
      <Typography mb={2} gap={2} color={"red"}>
        {errorUpdateExpertTrials && errorUpdateExpertTrials.message}
      </Typography>
    </>
  );
};

export default ExpertTrialSelectCheckmarks;
