import { gql } from "@apollo/client";

export const GET_BONUSES_LIST_FOR_CACH_BACK = gql`
  query getBonusesListForCacheBackCreateUpdate($filterBonus: BonusFilter!) {
    getBonuses(filter: $filterBonus) {
      id
      name
    }
  }
`;
