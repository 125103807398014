import React, { useContext } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { $colorVariables } from "../../../../../styles/colorVariables";
import ReviewsCTX from "../../../../../contexts/reviewsConext/ReviewsCTX";
import { ReviewsActionTypes } from "../../../../../contexts/reviewsConext/types";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const PinCheckbox = () => {
  const {
    dispatch,
    state: { isPinned },
  } = useContext(ReviewsCTX);

  const toogleIsPinnedStatus = () => {
    dispatch({
      type: ReviewsActionTypes.IS_PINNED,
    });
  };

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Checkbox
        {...label}
        checked={!!isPinned}
        onChange={toogleIsPinnedStatus}
      />

      <Typography sx={{ color: $colorVariables.$blue03 }}>
        Закреплённые
      </Typography>
    </Box>
  );
};

export default PinCheckbox;
