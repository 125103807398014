import { gql } from "@apollo/client";

export const UPDATE_EXPERT = gql`
  mutation updateExpert($input: updateExpertInput!, $updateNewInput: UpdateExpertInputNew!) {
    updateExpert(input: $input) {
      id
      uuid
      name
      description
      image(size: 100) {
        onex
      }
      cover {
        horizontal1x
      }
      rates {
        chat
        call
      }
      gender
      userID
      rating
      skills {
        name
        badge
      }
    }

    updateExpertNew(input: $updateNewInput)
  }
`;
