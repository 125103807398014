import { statusFilterDataTypes } from "./types";

export const statusFilterData: statusFilterDataTypes[] = [
  {
    title: "Активный",
    value: 1,
  },
  {
    title: "Не активный ",
    value: 2,
  },
  {
    title: "Все",
    value: 3,
  },
];

export default statusFilterData;
