export const checkAmountFreeMinutesCount = (text: string): string | null => {
  if (/[a-zA-Z]/.test(text)) {
    return "Ошибка: количество минут может содержать только числа";
  }

  const number = parseFloat(text);
  if (isNaN(number) || number < 0) {
    return "Ошибка: введено отрицательное число";
  }

  return null; // Возвращаем null, если текст прошел проверку
};

export const limitAmount = (amount: string) => {
  if (Number(amount) > 10000 || Number(amount) < -10000) {
    return false;
  } else {
    return true;
  }
};

export const filteredText = (text: string) => {
  const filtered = text.replace(/[^A-Za-zА-Яа-я0-9.,()\s]/g, "");
  return filtered;
};
