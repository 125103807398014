import * as React from "react";
import { Table, TableBody, Button } from "@mui/material";
import ReviewsTableHead from "./reviewsTableHead";
import ReviewsTableBody from "./reviewsTableBody";
import FiltersPanel from "./filtersPanel";
import { useReviewsTable } from "./useReviewsTable";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { $colorVariables } from "../../styles/colorVariables";
import { StyledButtonUpIcon } from "./styles";
import { HeaderTitle, StyledTableContainer } from "../../styles/common/styles";
import IconComponent from "../common/icon";

const CustomizedTables = () => {
  const {
    getMoreReviews,
    scrollToUp,
    allReviewsData,
    reviewsCount,
    showScrollBtn,
    isDisabledGetMoreReview,
    loadingGlobal,
  } = useReviewsTable();

  return (
    <>
      <HeaderTitle>Отзывы</HeaderTitle>
      <FiltersPanel />
      <StyledTableContainer>
        <Table
          aria-label="customized table"
          size="medium"
          sx={{ borderCollapse: "inherit !important" }}
        >
          <ReviewsTableHead
            ReviewsCount={reviewsCount}
            loading={loadingGlobal}
          />
          <TableBody>
            <ReviewsTableBody node={allReviewsData} loading={loadingGlobal} />
          </TableBody>
        </Table>
      </StyledTableContainer>

      <Button
        sx={{ marginTop: "16px" }}
        variant="contained"
        onClick={getMoreReviews}
        disabled={isDisabledGetMoreReview}
      >
        Загрузить еще 20
      </Button>
      {showScrollBtn && (
        <StyledButtonUpIcon variant="outlined" onClick={scrollToUp}>
          <IconComponent icon={faArrowUp} color={$colorVariables.$white} />
        </StyledButtonUpIcon>
      )}
    </>
  );
};

export default CustomizedTables;
