import React, { FC } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { TabsReportPanelProps } from "./types";

const TabsReportPanel: FC<TabsReportPanelProps> = ({
  a11yProps,
  handleChange,
  tabIndex,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Создать отчет" {...a11yProps(0)} />
          <Tab label="Обновить отчет" {...a11yProps(1)} />
        </Tabs>
      </Box>
    </Box>
  );
};

export default TabsReportPanel;
