import * as React from "react";
import Paper from "@mui/material/Paper";
import { useSchedule } from "./useSchedule";

import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import {
  StyledOverlayModalComponent,
  StyledTimeScaleTickCellComponent,
  StyledWeekViewTimeScaleLabel,
} from "./styles";
import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  DragDropProvider,
  Toolbar,
  DateNavigator,
  TodayButton,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { SchedulePanelPropsTypes } from "./types";
import TimeTableCell from "../components/timeTableCell";
import DayScaleCell from "../components/dayScaleCell";
import NavigationButtonComponent from "../components/navigationButtonComponent";
import DateTimeEditor from "../components/dateTimeEditor";

const SchedulePanel: React.FC<SchedulePanelPropsTypes> = ({ scheduleData }) => {
  const {
    expertScheduleData,
    onCommitChanges,
    currentDate,
    setCurrentDate,
    loadingData,
  } = useSchedule(scheduleData);

  return (
    <Paper>
      <Scheduler
        data={expertScheduleData}
        height={"auto"}
        firstDayOfWeek={1}
        locale={"ru-RU"}
      >
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={setCurrentDate}
          defaultCurrentDate={currentDate}
        />
        <EditingState onCommitChanges={onCommitChanges} />
        <IntegratedEditing />
        <WeekView
          cellDuration={60}
          timeTableCellComponent={(props) => {
            return <TimeTableCell {...props} isLoading={loadingData} />;
          }}
          dayScaleCellComponent={DayScaleCell}
          timeScaleLabelComponent={(props) => (
            <StyledWeekViewTimeScaleLabel {...props} />
          )}
          timeScaleTickCellComponent={(props) => (
            <StyledTimeScaleTickCellComponent {...props} />
          )}
        />
        <Toolbar />
        <DateNavigator navigationButtonComponent={NavigationButtonComponent} />
        <TodayButton messages={{ today: "Текущий день" }} />
        <Appointments />
        <AppointmentTooltip showOpenButton showDeleteButton showCloseButton />
        <AppointmentForm
          booleanEditorComponent={() => null}
          textEditorComponent={() => null}
          labelComponent={() => null}
          dateEditorComponent={DateTimeEditor}
          overlayComponent={StyledOverlayModalComponent}
        />
        <DragDropProvider />
        <CurrentTimeIndicator
          indicatorComponent={(props) => {
            return <CurrentTimeIndicator.Indicator {...props} />;
          }}
        />
      </Scheduler>
    </Paper>
  );
};

export default SchedulePanel;
