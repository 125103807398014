import { styled } from "@mui/system";

export const StyledContent = styled("div")({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});
