import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { $colorVariables } from "../../../styles/colorVariables";

export const StyledTableCell = styled(TableCell)(() => ({
  maxWidth: "200px",
  minWidth: "100px",

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: $colorVariables.$blue07,
    color: $colorVariables.$blue02,
  },
}));
