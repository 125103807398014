import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import { $colorVariables } from "../../../../../../../styles/colorVariables";

export const StyledSubmitButton = styled(Button)({
  color: $colorVariables.$blue01,
  backgroundColor: $colorVariables.$blue06,
  "&:hover": {
    backgroundColor: $colorVariables.$blue06,
  },
});

export const StyledDeactivateButton = styled(Button)({
  color: $colorVariables.$red,
  backgroundColor: $colorVariables.$redTransparent,
  "&:hover": {
    backgroundColor: $colorVariables.$redTransparent,
  },
});

export const StyledButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  height: "max-content"
});
