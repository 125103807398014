import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import ReviewsCTX from "../../../contexts/reviewsConext/ReviewsCTX";
import { ReviewsActionTypes } from "../../../contexts/reviewsConext/types";
import { GET_ALL_EXPERTS_ADMIN_DASHBOARD } from "../../experts/graphql/GET_ALL_EXPERTS_ADMIN_DASHBOARD.graphql";
import { getExpertsAdminDashboard, getExpertsAdminDashboardVariables } from "../../experts/graphql/__generated__/getExpertsAdminDashboard";

export const useFiltersPanel = () => {
  const { dispatch } = useContext(ReviewsCTX);
  const [allExperts, { data: allExpertsData }] = useLazyQuery<
    getExpertsAdminDashboard,
    getExpertsAdminDashboardVariables
  >(GET_ALL_EXPERTS_ADMIN_DASHBOARD);

  const resetFiltersHandler = () => {
    dispatch({
      type: ReviewsActionTypes.RESET_FILTERS,
    });
  };

  useEffect(() => {
    allExperts();
  }, [allExperts]);

  return {
    allExperts: allExpertsData?.getExpertsAdminDashboard,
    resetFiltersHandler,
  };
};
