import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomButton } from './styles';

const CustomButtonWithDelete = ({ btnTitle, onDelete }: any) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <CustomButton variant="contained">
        {btnTitle}
      </CustomButton>

      {hovered && (
        <IconButton
          size="small"
          style={{
            position: 'absolute',
            top: '-13px',
            right: '-5px',
            backgroundColor: 'red',
          }}
          onClick={(event) => {
            event.stopPropagation()
            onDelete()
          }}
        >
          <CloseIcon sx={{ color: "white", fontWeight: 900 }} fontSize='small' />
        </IconButton>
      )}
    </div>
  );
};

export default CustomButtonWithDelete;
