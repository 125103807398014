import React from "react";
import { faFileAudio, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { IconButton, Typography, Button, Box } from "@mui/material";
import { $colorVariables } from "../../../../../../../../styles/colorVariables";
import { StyledDivider } from "../../../../../../../../styles/common/styles";
import IconComponent from "../../../../../../../common/icon";
import { StyledContainerUploadElements } from "../styles";
import { useUploadAudio } from "./useUploadAudio";
import { UploadAudioProps } from "./types";

const UploadAudio = ({ expertId, currentAudio }: UploadAudioProps) => {
  const {
    handleAudioFileChange,
    uploadAudioHandler,
    resetStateAudioFileHandler,
    audioFile,
    loadingUpdate,
  } = useUploadAudio(expertId);

  return (
    <StyledContainerUploadElements>
      <Box width={"100%"}>
        <StyledDivider textAlign="right" sx={{ mt: "0px" }}>Загрузить аудио приветствие</StyledDivider>
        <IconButton color="primary" component="label">
          <input
            hidden
            accept="audio/*"
            type="file"
            onChange={handleAudioFileChange}
          />
          <Typography sx={{ mr: 2 }}>Загрузить файл</Typography>
          <IconComponent icon={faFileAudio} />
        </IconButton>

        {audioFile && (
          <Typography
            sx={{
              color: $colorVariables.$blue02,
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            File name: {audioFile?.name}
          </Typography>
        )}

        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Button
            onClick={resetStateAudioFileHandler}
            disabled={!audioFile}
            variant="contained"
          >
            <Typography>{!loadingUpdate ? "reset" : "loading..."}</Typography>
          </Button>
          <Button
            onClick={uploadAudioHandler}
            disabled={!audioFile}
            variant="contained"
          >
            <Typography sx={{ mr: 2 }}>
              {!loadingUpdate ? "Сохранить" : "loading..."}
            </Typography>
            <IconComponent
              icon={faPaperPlane}
              size={"2x"}
              title="send"
              cursor={"pointer"}
            />
          </Button>
          {currentAudio && <audio src={currentAudio} controls style={{ height: "40px" }} />}
        </Box>
      </Box>
    </StyledContainerUploadElements>
  );
};

export default UploadAudio;
