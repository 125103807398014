import React, { FC } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { StyledOutlinedInput } from "../../styles";
import { BlockUserContentModalProps } from "./types";
import { DatePicker } from "@mui/x-date-pickers-pro";
import DataPickerIcon from "../../../../assets/icons/DataPickerIcon";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { filteredText } from "../../utils";
import { useBlockUserContentModal } from "./useBlockUserContentModal";

const BlockUserContentModal: FC<BlockUserContentModalProps> = ({
  initUserData,
  singleEditClientData,
}) => {
  const {
    blockUserHandler,
    currentStatusClientData,
    unBlockUserHandler,
    setReasonBlockClient,
    blockUntilDateClient,
    reasonBlockClient,
    setBlockUntilDateClient,
    setIsBlockForever,
  } = useBlockUserContentModal(initUserData, singleEditClientData);
  const tomorrow = dayjs().add(1, "day");

  const shouldDisableDate = (date: any) => {
    return date.isBefore(tomorrow, "day");
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
        <StyledOutlinedInput
          placeholder="Опишите причину"
          onChange={(event) =>
            setReasonBlockClient(filteredText(event.target.value))
          }
          value={reasonBlockClient}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            components={{
              OpenPickerIcon: DataPickerIcon,
            }}
            inputFormat="DD.MM.YYYY"
            value={blockUntilDateClient || null}
            onChange={(e: Dayjs | null) =>
              setBlockUntilDateClient(dayjs(e).format())
            }
            shouldDisableDate={shouldDisableDate}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>

        <FormControlLabel
          sx={{ fontSize: "7px" }}
          label="Навсегда"
          control={
            <Checkbox
              onChange={(event) => setIsBlockForever(event.target.checked)}
            />
          }
        />

        <Chip
          label={currentStatusClientData?.isBlocked ? "Неактивный" : "Активный"}
          color={currentStatusClientData?.isBlocked ? "error" : "success"}
          sx={{
            height: "unset",
            padding: "4px 12px",
            fontSize: "15px",
          }}
        />

        <Button
          variant="contained"
          disabled={!!currentStatusClientData?.isBlocked}
          onClick={blockUserHandler}
          sx={{ minWidth: "unset" }}
        >
          Блокировать
        </Button>
        <Button
          variant="contained"
          disabled={!currentStatusClientData?.isBlocked}
          onClick={unBlockUserHandler}
          sx={{ minWidth: "unset" }}
        >
          Разблокировать
        </Button>
      </Box>
    </>
  );
};

export default BlockUserContentModal;
