import * as Yup from "yup"; //BONUS

export const validationSchema = Yup.object({
  name: Yup.string()
    .required("Укажите имя")
    .max(256, "Имя должно содержать не более 256 букв, цифр и пробелов."),
    // .matches(
    //   /^[a-zA-Z0-9\s]+$/,
    //   "Имя может содержать только буквы, цифры и пробелы."
    // ),
  type: Yup.string().required("type is required"),
  affectedObjects: Yup.array()
    .test("notEmptyArr", "affected objects is required", (value) => {
      return value?.length! > 0;
    })
    .required("affected objects is required"),

  formula: Yup.string().required("formula is required"),
  amount: Yup.number()
    .integer()
    .min(0, "Сумма должна быть целым числом, большим или равным 0.")
    .required("Требуется сумма"),
  lifetimePeriod: Yup.number()
    .integer()
    .min(0, "Сумма должна быть целым числом, большим или равным 0.")
    .required("Требуется Срок жизни в днях"),
});
