import { gql } from "@apollo/client";

export const CREATE_EXPERT = gql`
  mutation addExpert($input: NewExpert!) {
    addExpert(input: $input) {
      id
      uuid
      name
      description
      image(size: 100) {
        onex
      }
      cover {
        horizontal1x
      }
      rates {
        chat
        call
      }
      gender
      userID
      rating
      skills {
        name
        badge
      }
      minSessionDuration
    }
  }
`;
