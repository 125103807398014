import { useQuery } from "@apollo/client";
import { GET_ALL_IPS_FROM_WHITE_LIST } from "./graphql/GET_ALL_IPS_FROM_WHITE_LIST";
import {
  getWhiteListIPs,
  getWhiteListIPsVariables,
} from "./graphql/__generated__/getWhiteListIPs";
import { useContext, useEffect, useState } from "react";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";

export const useIpAddresses = () => {
  const { setLoading } = useContext(globalDataCTX);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>();

  const {
    data,
    error,
    loading,
    refetch: refetchGetIpsData,
  } = useQuery<getWhiteListIPs, getWhiteListIPsVariables>(
    GET_ALL_IPS_FROM_WHITE_LIST,
    {
      variables: { limit: 10, filter: {}, page },
    }
  );

  useEffect(() => {
    if (loading) {
      setLoading(loading);
    }

    if (data && !error && !error) {
      setTotalPages(data.getWhiteListIPs.pagination.totalPages);
      setPage(data.getWhiteListIPs.pagination.page);
      setLoading(false);
    }
  }, [data, error, loading, setLoading]);

  return {
    dataIpsFromWhiteList: data?.getWhiteListIPs.ips,
    refetchGetIpsData,
    setPage,
    totalPages,
    page,
    isLoading: loading,
  };
};
