import React from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCreateUpdateCashBackForm } from "./useCreateUpdateCashBackForm";
import { Enum_inputs } from "./types";
import { HeaderTitle } from "../../../schedule/allSchedules/styles";
import { MenuProps } from "./constats";

const CreateUpdateCashBackForm = () => {
  const {
    lowerBoundary,
    upperBoundary,
    bonusesListData,
    isDisabledButton,
    isUpdatePage,
    bonusId,
    isActiveCashBack,
    onChangeInputsHandler,
    createUpdateHandler,
  } = useCreateUpdateCashBackForm();

  return (
    <>
      <Divider textAlign="right">
        <HeaderTitle>
          {isUpdatePage
            ? "Обновить правила кэшбэка"
            : "Добавить правила кэшбэка"}
        </HeaderTitle>
      </Divider>
      <Box display={"flex"} width={"100%"}>
        <Card sx={{ minWidth: "50%" }}>
          <CardActionArea>
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <TextField
                value={lowerBoundary}
                label="Нижняя граница"
                variant="outlined"
                onChange={(event) =>
                  onChangeInputsHandler(event, Enum_inputs.LOWER_BOUNDARY)
                }
              />
              <TextField
                value={upperBoundary}
                label="Верхняя граница"
                variant="outlined"
                onChange={(event) =>
                  onChangeInputsHandler(event, Enum_inputs.UPPER_BOUNDARY)
                }
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Имя бонуса
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Имя бонуса"
                  value={bonusId}
                  onChange={(event) =>
                    onChangeInputsHandler(event, Enum_inputs.BONUS_ID)
                  }
                  MenuProps={MenuProps}
                >
                  {bonusesListData?.map((bonus) => {
                    return (
                      <MenuItem key={bonus?.id} value={bonus?.id}>
                        {bonus?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {isUpdatePage && (
                <Box display={"flex"} alignItems={"center"}>
                  <Typography>Активно</Typography>
                  <Checkbox
                    checked={isActiveCashBack}
                    onChange={(event) =>
                      onChangeInputsHandler(event, Enum_inputs.IS_ACTIVE)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Box>
              )}
            </CardContent>
          </CardActionArea>
          <CardActions sx={{ float: "right", marginRight: 1 }}>
            <Button
              size="medium"
              variant="contained"
              onClick={createUpdateHandler}
              disabled={!isDisabledButton}
            >
              {`${isUpdatePage ? "Обновить" : "Добавить"}`}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </>
  );
};

export default CreateUpdateCashBackForm;
