import React from "react";
import { useCashback } from "./useCashback";
import { Box } from "@mui/material";
import { HeaderTitle } from "../../styles/common/styles";
import CashBackTableBody from "./components/cashBackTableBody";
import SearchBar from "./components/searchBar";
import ModalForDeleteCashBack from "./components/modalForDeleteCashBack";

const Cashback = () => {
  const {
    casheBackData,
    loadingPage,
    refetchGetCashBack,
    toggleOpenCloseModal,
    isOpenModal,
    setIdForDeleteRule,
    idForDeleteRule,
  } = useCashback();

  return (
    <Box>
      <HeaderTitle>Правила кэшбэка</HeaderTitle>
      <SearchBar refetchGetCashBack={refetchGetCashBack} />
      <CashBackTableBody
        cashBackData={casheBackData}
        isLoading={loadingPage}
        toggleOpenCloseModal={toggleOpenCloseModal}
        setIdForDeleteRule={setIdForDeleteRule}
      />
      <ModalForDeleteCashBack
        isOpenModal={isOpenModal}
        toggleOpenCloseModal={toggleOpenCloseModal}
        idForDeleteRule={idForDeleteRule}
        refetchGetCashBack={refetchGetCashBack}
      />
    </Box>
  );
};

export default Cashback;
