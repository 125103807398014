import React, { FC } from "react";
import { IconWithTextLabelProps } from "./types";
import IconComponent from "../../../../../../common/icon";

const IconWithTextLabel: FC<IconWithTextLabelProps> = ({ text, icon }) => {
  return (
    <>
      {text} <IconComponent icon={icon} />
    </>
  );
};

export default IconWithTextLabel;
