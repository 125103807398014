import {
  Appointments,
  Scheduler,
  WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Paper } from "@mui/material";
import DayScaleCell from "../components/dayScaleCell";
import TimeTableCell from "../components/timeTableCell";
import {
  StyledTimeScaleTickCellComponent,
  StyledWeekViewTimeScaleLabel,
} from "../scheduler/styles";

const SchedulePublished = ({ scheduleData }: any) => {
  return (
    <Paper sx={{ mt: "40px", width: "100%" }}>
      <Scheduler
        data={scheduleData}
        height={"auto"}
        firstDayOfWeek={1}
        locale={"ru-RU"}
      >
        <WeekView
          cellDuration={60}
          timeTableCellComponent={TimeTableCell}
          dayScaleCellComponent={DayScaleCell}
          intervalCount={2}
          timeScaleLabelComponent={(props) => (
            <StyledWeekViewTimeScaleLabel {...props} />
          )}
          timeScaleTickCellComponent={(props) => (
            <StyledTimeScaleTickCellComponent {...props} />
          )}
        />
        <Appointments />
      </Scheduler>
    </Paper>
  );
};

export default SchedulePublished;
