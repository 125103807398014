import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  Skeleton,
} from "@mui/material";
import moment from "moment";
import { StyledTableCell } from "../../../experts/expertsTableHead/styles";
import IpsTableHeader from "../ipTableHead";
import { useNavigate } from "react-router-dom";
import { IpsListComponentProps } from "./types";

const IpsListComponent = ({
  dataIpsFromWhiteList,
  isLoading,
}: IpsListComponentProps) => {
  const navigate = useNavigate();
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderTopRightRadius: "16px",
        borderTopLeftRadius: "16px",
        mt: "24px",
      }}
    >
      <Table
        aria-label="customized table"
        size="medium"
        sx={{ borderCollapse: "inherit !important" }}
      >
        <IpsTableHeader />
        <TableBody>
          {dataIpsFromWhiteList?.map(
            (
              { allowedAccountCount, createdAt, id, ip, reason, updatedAt },
              idx
            ) => {
              return (
                <TableRow
                  key={idx}
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("update-ip", {
                      state: {
                        ipForUpdate: {
                          allowedAccountCount,
                          id,
                          ip,
                          reason,
                        },
                      },
                    })
                  }
                >
                  {!isLoading ? (
                    <>
                      <StyledTableCell>{ip}</StyledTableCell>
                      <StyledTableCell>
                        {moment(createdAt).format("DD.MM.YYYY")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(updatedAt).format("DD.MM.YYYY")}
                      </StyledTableCell>
                      <StyledTableCell>{allowedAccountCount}</StyledTableCell>
                      <StyledTableCell>{reason}</StyledTableCell>
                    </>
                  ) : (
                    <>
                      <StyledTableCell key={idx} align="left">
                        <Skeleton animation="wave" />
                      </StyledTableCell>

                      <StyledTableCell key={idx} align="left">
                        <Skeleton animation="wave" />
                      </StyledTableCell>

                      <StyledTableCell key={idx} align="left">
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                    </>
                  )}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IpsListComponent;
