import React from "react";
import SubPagePromosList from "../../../components/promo/subPages/subPagePromosList";
import SubPagePrompJoinAffise from "../../../components/promo/subPages/subPagePrompJoinAffise";
import SubPageCreateUpdatePromo from "../../../components/promo/subPages/subPageCreateUpdatePromo";

export const SubRoutPromosList = () => {
  return <SubPagePromosList />;
};

export const SubRoutJoinPromoToEffise = () => {
  return <SubPagePrompJoinAffise />;
};

export const SubRoutCreateUpdatePromo = () => {
  return <SubPageCreateUpdatePromo />;
};
