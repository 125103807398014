import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import { GET_PROMOS } from "./graphql/GET_PROMOS.graphql";
import {
  getPromos,
  getPromosVariables,
} from "./graphql/__generated__/getPromos";
import { useKeyboardEvent } from "../../utils/useKeyboard";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { GENERATE_PROMO_CODES_FILE } from "./graphql/GENERATE_PROMO_CODES_FILE.graphql";
import {
  generatePromoCodesFile,
  generatePromoCodesFileVariables,
} from "./graphql/__generated__/generatePromoCodesFile";
import { notificationEventType } from "../../contexts/globalContext/types";
import { getAccessToken } from "../../sevices/securityService/securityServiceHelper";
import { StatusFilterEnum } from "./components/statusSearchBar/types";

export const usePromoCodes = () => {
  const [searchPromoByName, setSearchPromoByName] = useState("");
  const [statusFilter, setStatusFilter] = useState<StatusFilterEnum[]>([
    StatusFilterEnum.ALL,
  ]);

  const { setLoading, setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);

  const [getPromosMutation, { data: dataPromos, loading: promoLoading }] =
    useLazyQuery<getPromos, getPromosVariables>(GET_PROMOS);

  useEffect(() => {
    setLoading(promoLoading);
  }, [promoLoading, setLoading]);

  const [
    generatePromoCodesFile,
    { data: datafile, loading: loadingFile, error: errorFile },
  ] = useMutation<generatePromoCodesFile, generatePromoCodesFileVariables>(
    GENERATE_PROMO_CODES_FILE
  );

  useEffect(() => {
    getPromosMutation({
      variables: {
        input: {
          status:
            statusFilter[0] === StatusFilterEnum.ALL ? null : statusFilter,
        },
      },
    });
  }, [getPromosMutation, searchPromoByName, statusFilter]);

  const getFilteredPromos = () => {
    getPromosMutation({
      variables: {
        input: {
          name: searchPromoByName,
          status:
            statusFilter[0] === StatusFilterEnum.ALL ? null : statusFilter,
        },
      },
    });
  };

  const downloadFile = useCallback(
    async (fileName: string, fileKey: string) => {
      try {
        const accessToken = getAccessToken();

        const apiUrl = `https://api.lunaro.ru/promo-code/download/file`;

        // Use URLSearchParams to build query parameters
        const queryParams = new URLSearchParams({
          name: fileName,
          key: fileKey,
        });

        const url = `${apiUrl}?${queryParams.toString()}`;

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to download file: ${response.statusText}`);
        }

        const blob = await response.blob();

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error during file download:", error);
        setNotificationEvent(notificationEventType.error);
        setNotificationMsg(`Ошибка загрузки файла`);
      }
    },
    [setNotificationEvent, setNotificationMsg]
  );

  useEffect(() => {
    if (datafile && !loadingFile && !errorFile) {
      const { name, key } = datafile?.generatePromoCodesFile;
      downloadFile(name, key);
    }
  }, [datafile, downloadFile, errorFile, loadingFile]);

  const generateFile = (promoId: string) => {
    generatePromoCodesFile({ variables: { promoID: promoId } });
  };

  const handleChangeStatusBar = (
    event: React.SyntheticEvent,
    newValue: StatusFilterEnum
  ) => {
    setStatusFilter([newValue]);
  };

  const resetFilterForPromo = () => {
    setSearchPromoByName("");
    setStatusFilter([StatusFilterEnum.ALL]);
  };

  useKeyboardEvent("Enter", getFilteredPromos);
  useKeyboardEvent("Escape", resetFilterForPromo);

  return {
    dataPromos,
    searchPromoByName,
    statusFilter,
    resetFilterForPromo,
    getFilteredPromos,
    setSearchPromoByName,
    generateFile,
    handleChangeStatusBar,
  };
};
