import React from "react";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Stack } from "@mui/material";
import { $colorVariables } from "../../../styles/colorVariables";
import IconComponent from "../icon";
import { ControlButtonsProps } from "./types";
import {
  StyledDoneButton,
  StyledDeletButton,
} from "../../reviews/reviewsControllersGroupButtons/styles";

const ControlButtons = ({
  firstClickHandler,
  secondClickHandler,
  successIcon = faCheck,
  errorIcon = faXmark,
}: ControlButtonsProps) => {
  return (
    <Stack direction="row" spacing={1}>
      <StyledDoneButton variant="contained" onClick={firstClickHandler}>
        <IconComponent color={$colorVariables.$white} icon={successIcon} />
      </StyledDoneButton>
      <StyledDeletButton variant="contained" onClick={secondClickHandler}>
        <IconComponent color={$colorVariables.$white} icon={errorIcon} />
      </StyledDeletButton>
    </Stack>
  );
};

export default ControlButtons;
