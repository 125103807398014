import React, { FC } from "react";
import { InputLabel, MenuItem, FormControl } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BonusTypesSelectProps } from "./types";
import { BonusType } from "../../../../../../globalTypes";

const BonusTypeSelectInput: FC<BonusTypesSelectProps> = ({
  filterBonuses,
  setFilterBonuses,
}) => {
  const handleChange = (event: SelectChangeEvent<any>) => {
    setFilterBonuses({
      ...filterBonuses,
      type: event.target.value,
    });
  };

  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">Тип бонуса</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={filterBonuses.type}
        label="Тип бонуса"
        sx={{ height: "40px", width: "130px" }}
        onChange={handleChange}
      >
        <MenuItem value={"All"}>{"All"}</MenuItem>
        <MenuItem value={BonusType.sale}>{BonusType.sale}</MenuItem>
        <MenuItem value={BonusType.deposit}>{BonusType.deposit}</MenuItem>
      </Select>
    </FormControl>
  );
};
export default BonusTypeSelectInput;
