import { DateNavigator } from '@devexpress/dx-react-scheduler';
import React from 'react'
import { StyledDateNavigator } from './styles';

const NavigationButtonComponent = (
    props: DateNavigator.NavigationButtonProps
  ) => {
    return <StyledDateNavigator {...props} />;
  };

export default NavigationButtonComponent
