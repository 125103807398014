import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useRef, useState } from "react";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import ReviewsCTX from "../../contexts/reviewsConext/ReviewsCTX";
import { initialCountReviews } from "./constants";
import { GET_ALL_REVIEWS } from "./graphql/GET_ALL_REVIEWS.graphql";
import {
  getAllReviews,
  getAllReviewsVariables,
} from "./graphql/__generated__/getAllReviews";

export const useReviewsTable = () => {
  const { setLoading, loading } = useContext(globalDataCTX);
  const [countReviews, setCountReviews] = useState<number>(initialCountReviews);
  const [showScrollBtn, setShowScrollBtn] = useState<boolean>(false);
  const [isDisabledGetMoreReview, setIsDisabledGetMoreReview] =
    useState<boolean>(false);
  const { state } = useContext(ReviewsCTX);
  const currentScrollWindow = useRef<number>(0);

  const [getData, { data: reviewsdata, loading: reviewsLoading }] =
    useLazyQuery<getAllReviews, getAllReviewsVariables>(GET_ALL_REVIEWS);

  const allReviewsData = reviewsdata?.getAllReviews?.edges;
  const reviewsCount = reviewsdata?.getAllReviews?.totalCount;

  useEffect(() => {
    setLoading(reviewsLoading);

    if (reviewsdata) {
      const hasNextPage = reviewsdata.getAllReviews.pageInfo.hasNextPage;
      setIsDisabledGetMoreReview(!hasNextPage);
      scrollToCurrentPosition();
    }
  }, [reviewsdata, reviewsLoading, setLoading]);

  useEffect(() => {
    let dateFilter;
    const { earlierThan, laterThan } = state?.dateFilter || {};
    const { statusFilter, expertFilter, gradeFilter, isPinned } = state || {};

    if (earlierThan || laterThan) {
      dateFilter = {
        ...(earlierThan && { earlierThan }),
        ...(laterThan && { laterThan }),
      };
    }

    getData({
      variables: {
        first: countReviews,
        filter: {
          dateFilter:
            (dateFilter && {
              earlierThan: dateFilter.earlierThan,
              laterThan: dateFilter.laterThan,
            }) ||
            undefined,
          statusFilter: statusFilter,
          expertFilter: expertFilter,
          gradeFilter: gradeFilter,
          isPinned: isPinned,
        },
      },
    });
  }, [countReviews, getData, state]);

  const scrollToCurrentPosition = () => {
    return window.scroll({
      top: currentScrollWindow.current,
    });
  };

  const getMoreReviews = () => {
    setCountReviews((prevCount) => prevCount + initialCountReviews);
  };

  const scrollToUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const getScrollWindow = () => {
    if (window.scrollY > 0) {
      setShowScrollBtn(true);
      currentScrollWindow.current = window.scrollY;
    } else {
      setShowScrollBtn(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", getScrollWindow);
    return () => {
      window.removeEventListener("scroll", getScrollWindow);
    };
  }, []);

  return {
    allReviewsData,
    reviewsCount,
    isDisabledGetMoreReview,
    reviewsLoading,
    showScrollBtn,
    getMoreReviews,
    scrollToUp,
    loadingGlobal: loading,
  };
};
