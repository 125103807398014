import { SelectChangeEvent } from "@mui/material";
import { useContext } from "react";
import ReviewsCTX from "../../../../../contexts/reviewsConext/ReviewsCTX";
import { ReviewsActionTypes } from "../../../../../contexts/reviewsConext/types";

export const useGradeSelect = () => {
  const {
    dispatch,
    state: { gradeFilter },
  } = useContext(ReviewsCTX);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch({
      type: ReviewsActionTypes.FILTER_GRADE,
      payload: { grade: event.target.value },
    });
  };

  return { handleChange, gradeFilter };
};
