import { gql } from "@apollo/client";

export const GET_SESSION_BY_ADMIN = gql`
  query getSessionByAdmin ($sessionId : Int!) {
    getSessionByAdmin(sessionID: $sessionId){
        id
        status
        expert{
        name
        image(size: 100){
            onex
        }
        }
        client{
        name
        image(size:100){
            onex
        }
        }
    }
}
`;
