import React from "react";
import { useSubPagePromosList } from "./useSubPagePromosList";
import PromoCodesItem from "../../components/promoCodesItem";
import { Box, Divider } from "@mui/material";
import BackButton from "../../../common/BackButton";
import { HeaderTitle } from "../../../../styles/common/styles";

const SubPagePromosList = () => {
  const { dataPromoCodes, promoName } = useSubPagePromosList();

  return (
    <>
      <BackButton />
      <Box mt={5} sx={{ width: "100%" }}>
        <Divider textAlign="center">
          <HeaderTitle>Названия промо- {promoName}</HeaderTitle>
        </Divider>
        <Box mt={5}>
          {dataPromoCodes?.getPromoCodes.map((promoCode) => {
            return (
              <PromoCodesItem
                key={promoCode?.id}
                code={promoCode?.code}
                // setPromocodeForAffise={setPromocodeForAffise}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default SubPagePromosList;
