import fetch from "isomorphic-fetch";
import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  from,
  fromPromise,
  InMemoryCache,
  Observable,
  Operation,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
// @ts-ignore
import { createUploadLink } from "apollo-upload-client";
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
  triggerAuthorizationForm,
} from "../securityService/securityServiceHelper";
import { REFRESH_TOKEN } from "../../layout/auth/login/graphql/REFRESH_TOKEN.graphql";

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}query`,
  fetch: (uri: RequestInfo, options: RequestInit | undefined) => {
    return fetch(uri, options).catch((error) => {
      throw new Error(JSON.parse(error));
    });
  },
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      if (graphQLErrors[0].extensions.code === "2") {
        return updateToken(forward, operation);
      }

      graphQLErrors.forEach(({ message, extensions, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const accessToken = getAccessToken();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  link: from([authMiddleware, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

function updateToken(
  forward: {
    (operation: Operation): Observable<FetchResult>;
    (operation: Operation): Observable<FetchResult>;
    (arg0: any): any;
  },
  operation: Operation
) {
  return fromPromise(
    fetch(`${process.env.REACT_APP_API_URL}query`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        query: REFRESH_TOKEN,
        variables: {
          refreshToken: getRefreshToken(),
        },
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        const { accessToken = "", refreshToken = "" } = result.data
          ? result.data.refreshToken.tokens
          : {};

        accessToken ? setAccessToken(accessToken) : removeAccessToken();
        refreshToken ? setRefreshToken(refreshToken) : removeRefreshToken();

        !refreshToken && triggerAuthorizationForm();
      })
  ).flatMap(() => {
    return retryFetch(forward, operation);
  });
}

function retryFetch(
  forward: {
    (operation: Operation): Observable<FetchResult>;
    (arg0: any): any;
  },
  operation: Operation
) {
  const oldHeaders = operation.getContext().headers;
  operation.setContext({
    headers: {
      ...oldHeaders,
      Authorization: getAccessToken() ? `Bearer ${getAccessToken()}` : null,
    },
  });

  return forward(operation);
}
