import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledBlockInput = styled(Box)({
  // width: "50%",
  marginBottom: "16px"
});

export const StyledStackRowInputs = styled(Stack)({
  alignItems: "center",
  marginBottom: "20px",
});
