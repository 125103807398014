import { useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import {
    getPromoCodes,
    getPromoCodesVariables,
} from "../../graphql/__generated__/getPromoCodes";
import { GET_PROMO_CODES } from "../../graphql/GET_PROMO_CODES.graphql";
import { useContext, useEffect } from "react";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";

export const useSubPagePromosList = () => {
    const { promoId } = useParams();
    const { state } = useLocation()
    const { setLoading } = useContext(globalDataCTX)


    const { data: dataPromoCodes, loading: promoCodesLoading } = useQuery<
        getPromoCodes,
        getPromoCodesVariables
    >(GET_PROMO_CODES, {
        variables: {
            input: {
                promoID: promoId
            }
        }
    });

    useEffect(() => {
        setLoading(!!promoCodesLoading)
    }, [promoCodesLoading, setLoading])

    return { dataPromoCodes, promoName: state.promoName || "" };
};
