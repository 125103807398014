import { Box, styled } from "@mui/material";

export const StyledBlockUploadElements = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  gap: "25px",
  alignItems: "start"
});

export const StyledContainerUploadElements = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  border: "1px solid grey",
  padding: "15px",
  borderRadius: "0 0 20px 20px",
  borderTop: "unset",
  marginBottom: "30px"
});
