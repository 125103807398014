import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { GET_USER } from "./graphql/GET_USER.graphql";
import {
  getUserInfo,
  getUserInfoVariables,
} from "./graphql/__generated__/getUserInfo";
import {
  editTypesEnum,
  getUserInfoType,
  openModalType,
  singleEditClientType,
} from "./types";
import { ADD_FREE_MINUTES } from "./graphql/ADD_FREE_MINUTES.graphql";
import {
  addFreeMinutesVariables,
  addFreeMinutes,
} from "./graphql/__generated__/addFreeMinutes";
import { notificationEventType } from "../../contexts/globalContext/types";
import { useKeyboardEvent } from "../../utils/useKeyboard";
import { getErrorByCode, getErrorCode } from "../../utils/errorUtils";
import {
  getUserReferralStatus,
  getUserReferralStatusVariables,
} from "./graphql/__generated__/getUserReferralStatus";
import { GET_USER_REFERRAL_STATUS } from "./graphql/GET_USER_REFERRAL_STATUS.graphql";

export const useClient = () => {
  const [searchTextId, setSearchTextId] = useState("");
  const [blockUntilDateClient, setBlockUntilDateClient] = useState<string>("");
  const [reasonForFreeMinutes, setReasonForFreeMinutes] = useState("");
  const [freeMinutesAmount, setFreeMinutesAmount] = useState("");
  const [users, setUsers] = useState<getUserInfoType[] | []>([]);
  const [selectedUsers, setSelectedUsers] = useState<getUserInfoType[] | []>(
    []
  );

  const [openModal, setOpenModal] = useState<openModalType>({
    modalName: editTypesEnum.CLOSED,
    status: false,
  });

  const [singleEditClientData, setSingleEditClientData] =
    useState<singleEditClientType>({ id: 0, image: "" });

  const isSomeEditClient = selectedUsers.length >= 2;

  const { setLoading, setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);

  const [getUser, { data: userData, loading: userLoading, error: userError }] =
    useLazyQuery<getUserInfo, getUserInfoVariables>(GET_USER, {
      fetchPolicy: "network-only",
    });

  const [getUserReferralStatus, { data: userDataReferralStatus }] =
    useLazyQuery<getUserReferralStatus, getUserReferralStatusVariables>(
      GET_USER_REFERRAL_STATUS
    );

  const [
    addFreeMinutes,
    {
      data: addFreeMinutesData,
      loading: addFreeMinutesLoading,
      error: addFreeMinutesError,
    },
  ] = useMutation<addFreeMinutes, addFreeMinutesVariables>(ADD_FREE_MINUTES);

  const getUserHandler = () => {
    if (searchTextId.trim() && !Number.isNaN(Number(searchTextId))) {
      getUser({
        variables: {
          getUserInfo: { UserID: Number(searchTextId) },
        },
      });
      getUserReferralStatus({
        variables: {
          userID: Number(searchTextId),
        },
      });
      setSearchTextId("");
    } else {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg("нужно указать ID клиента цифрами");
    }
  };

  useKeyboardEvent("Enter", getUserHandler);

  useEffect(() => {
    const loading = addFreeMinutesLoading || userLoading;
    setLoading(loading);

    switch (true) {
      case Boolean(userError):
        setNotificationEvent(notificationEventType.error);
        setNotificationMsg(getErrorByCode(getErrorCode(userError)) || "");
        break;
      case Boolean(addFreeMinutesError):
        setNotificationEvent(notificationEventType.error);
        setNotificationMsg("Ошибка: баланс БМ нельзя увести ниже нуля" || "");
        break;
      case Boolean(addFreeMinutesData):
        setNotificationEvent(notificationEventType.success);
        setNotificationMsg("Успех");
        break;
      default:
        break;
    }
  }, [
    addFreeMinutesData,
    addFreeMinutesError,
    addFreeMinutesLoading,
    setLoading,
    setNotificationEvent,
    setNotificationMsg,
    userError,
    userLoading,
  ]);

  useEffect(() => {
    const newUser = userData?.getUserInfo;

    if (newUser && !userLoading && !userError) {
      // Проверяем, существует ли пользователь в списке
      const existingUser = users.find(
        (user) => user.user.id === newUser.user.id
      );

      if (existingUser) {
        // Если пользователь уже существует в списке, обновляем его данные
        const updatedUsers = users.map((user) => {
          if (user.user.id === newUser.user.id) {
            return {
              ...user,
              ...newUser,
            };
          }
          return user;
        });
        setUsers(updatedUsers);
      } else {
        // Если пользователь не существует в списке, добавляем его
        const userWithChecked = {
          ...newUser,
          checked: false,
        };
        setUsers((prevUsers) => [...prevUsers, userWithChecked]);
      }
    }
  }, [userData, userError, userLoading]);

  const removeUserHandler = (useId: number) => {
    const newListUsers = users.filter((user) => user.user.id !== useId);
    setUsers(newListUsers);
  };

  const checkUserHandler = (useId: number) => {
    const updatedUsers = users.map((user) => {
      if (user.user.id === useId) {
        const newStatusUserChecked = !user.checked;
        return { ...user, checked: newStatusUserChecked };
      }
      return user;
    });
    setUsers(updatedUsers);
  };

  useEffect(() => {
    const selectedUsersData = users.filter((user) => user.checked);
    setSelectedUsers(selectedUsersData);
  }, [users]);

  const openModalHandler = (typeModal: editTypesEnum) => {
    setOpenModal({ status: true, modalName: typeModal });
    setReasonForFreeMinutes("");
    setFreeMinutesAmount("");
    setSingleEditClientData({ image: "", id: 0 });
  };

  const closeModalHandler = () => {
    setOpenModal({ status: false, modalName: editTypesEnum.CLOSED });
  };

  const setFreeMinutersHandler = () => {
    const checkedIds = selectedUsers.map((user) => user.user.id);
    addFreeMinutes({
      variables: {
        input: {
          Amount: Number(freeMinutesAmount),
          Reason: reasonForFreeMinutes,
          userIDs:
            openModal.modalName === editTypesEnum.SOME_EDIT
              ? [...checkedIds]
              : [singleEditClientData?.id],
        },
      },
      refetchQueries: [
        {
          query: GET_USER,
          variables: { getUserInfo: { UserID: singleEditClientData.id } },
        },
      ],
    });
    setReasonForFreeMinutes("");
    setFreeMinutesAmount("");
  };
  return {
    searchTextId,
    openModal,
    userData: users,
    isSomeEditClient,
    reasonForFreeMinutes,
    freeMinutesAmount,
    selectedUsers,
    singleEditClientData,
    blockUntilDateClient,
    initUserData: userData,
    userDataReferralStatus,
    setBlockUntilDateClient,
    setReasonForFreeMinutes,
    setFreeMinutesAmount,
    setSearchTextId,
    getUserHandler,
    removeUserHandler,
    checkUserHandler,
    openModalHandler,
    setFreeMinutersHandler,
    setSingleEditClientData,
    closeModalHandler,
  };
};
