import { ReviewFilter } from "../../globalTypes";
import { ReviewsActionTypes } from "./types";

const reviewsReducer = (
  state: ReviewFilter,
  action: { payload: any; type: ReviewsActionTypes }
) => {
  switch (action.type) {
    case ReviewsActionTypes.FILTER_EXPERT:
      return {
        ...state,
        expertFilter: [action.payload.expertId],
      };
    case ReviewsActionTypes.FILTER_STATUS:
      return {
        ...state,
        statusFilter: action.payload.status,
      };
    case ReviewsActionTypes.FILTER_GRADE:
      return {
        ...state,
        gradeFilter: [action.payload.grade],
      };
    case ReviewsActionTypes.EARLIER_THAN:
      return {
        ...state,
        dateFilter: {
          ...state?.dateFilter,
          earlierThan: action.payload,
        },
      };
    case ReviewsActionTypes.LATER_THAN:
      return {
        ...state,
        dateFilter: {
          ...state?.dateFilter,
          laterThan: action.payload,
        },
      };
    case ReviewsActionTypes.IS_PINNED:
      return {
        ...state,
        isPinned: !state.isPinned,
      };
    case ReviewsActionTypes.RESET_FILTERS:
      return {
        ...state,
        dateFilter: {
          earlierThan: null,
          laterThan: null,
        },
        expertFilter: [],
        clientFilter: [],
        gradeFilter: [],
        isPinned: false,
      };
    default:
      return state;
  }
};
export default reviewsReducer;
