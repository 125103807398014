import { Typography, styled } from "@mui/material";
import { $colorVariables } from "../../../../styles/colorVariables";

export const StylesContainerPromosCodes = styled(Typography)({
  borderBottom: `1px solid ${$colorVariables.$blue01} `,
  marginBottom: "15px",
  minWidth: "50%",
  width: "100%",
  paddingBottom: "4px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
