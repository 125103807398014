import React, { FC } from "react";
import { FormButtonsPanelProps } from "./types";
import { StyledButtonContainer, StyledSubmitButton } from "./styles";

const FormButtonsPanel: FC<FormButtonsPanelProps> = ({ isUpdateForm }) => {
  return (
    <StyledButtonContainer>
      <StyledSubmitButton type="submit" variant="contained">
        {isUpdateForm ? "обновить эксперта" : "добавить эксперта "}
      </StyledSubmitButton>
    </StyledButtonContainer>
  );
};

export default FormButtonsPanel;
