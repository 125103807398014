import { gql } from "@apollo/client";

export const GENERATE_PROMO_CODES_FILE = gql`
  mutation generatePromoCodesFile($promoID: String!) {
    generatePromoCodesFile(promoID: $promoID) {
      name
      key
    }
  }
`;
