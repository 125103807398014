export enum StatusFilterEnum {
  ACTIVE = "active",
  CREATED = "created",
  IN_ACTIVE = "inactive",
  ALL = "ALL",
}

export type StatusFilterBarDataType = {
  title: string;
  value: StatusFilterEnum | number;
};
