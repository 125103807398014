import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const NAV_Width = 110;

export const Main = styled(Box)({
  overflow: "auto",
  minHeight: "100vh",
  padding: "24px 32px 24px 32px",
  width: `calc(100% - ${NAV_Width}px)`,
  backgroundColor: "#f9fafb",
  marginLeft: "auto",
});
