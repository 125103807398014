import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { FC } from "react";
import { SingleDataPickerProps } from "./types";

const SingleDataPicker: FC<SingleDataPickerProps> = ({
  setFieldValue,
  values,
  placeholder = "YYYY-MM-DD",
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        inputFormat={placeholder}
        label="Дата начала карьеры"
        toolbarPlaceholder="careerStartDate"
        value={values || null}
        onChange={(e: Dayjs | null) =>
          setFieldValue("careerStartDate", dayjs(e).format("YYYY-MM-DD"), true)
        }
        renderInput={(params) => <TextField fullWidth {...params} />}
      />
    </LocalizationProvider>
  );
};

export default SingleDataPicker;
