import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../../reviews/filtersPanel/statusTabs/styles";
import { SearchBarProps } from "./types";
import statusFilterData from "./constants";
import { StyledSearchBarWrapper } from "../../../../styles/common/styles";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

const SearchBar = ({ refetchGetCashBack }: SearchBarProps) => {
  const navigate = useNavigate();
  const [filterIsActive, setFilterIsActive] = useState<boolean | null>(null);
  const [filterTabNumber, setFilterTabNumber] = useState<number>(3);

  const resetStatus = () => {
    setFilterTabNumber(3);
    setFilterIsActive(null);
  };

  const onChange = (event: any, newValue: number) => {
    setFilterTabNumber(newValue);

    switch (newValue) {
      case 1:
        setFilterIsActive(true);
        break;
      case 2:
        setFilterIsActive(false);
        break;

      default:
        setFilterIsActive(null);
    }
  };

  return (
    <StyledSearchBarWrapper direction="row">
      <Box display={"flex"} gap={2}>
        <Box>
          <StyledToggleButtonGroup
            value={filterTabNumber || 3}
            exclusive
            onChange={onChange}
            aria-label="text alignment"
          >
            {statusFilterData.map((item) => (
              <StyledToggleButton key={item.title} value={item.value || 0}>
                {item.title}
              </StyledToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </Box>
        <Button variant="contained" color="info" onClick={resetStatus}>
          Сбросить
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() =>
            refetchGetCashBack({ filter: { isActive: filterIsActive } })
          }
        >
          Поиск
        </Button>
      </Box>
      <Button
        variant="contained"
        color="info"
        onClick={() => navigate("create")}
      >
        <AddIcon sx={{ mr: 1 }} />
        Добавить новые правила кэшбэка
      </Button>
    </StyledSearchBarWrapper>
  );
};

export default SearchBar;
