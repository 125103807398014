import * as Yup from "yup";

export const validationUpdatePromoSchema = Yup.object({
  name: Yup.string()
    .required("Укажите имя")
    .max(256, "Имя должно содержать не более 256 букв, цифр и пробелов.")
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "Имя может содержать только буквы, цифры и пробелы."
    ),

  startDate: Yup.string(),
  endDate: Yup.string(),
});

export const validationCreatePromoSchema = Yup.object({
  name: Yup.string()
    .required("Укажите имя")
    .max(256, "Имя должно содержать не более 256 букв, цифр и пробелов.")
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "Имя может содержать только буквы, цифры и пробелы."
    ),
  redeemAbility: Yup.string(),
  generatePattern: Yup.string()
    .matches(
      /^[a-zA-Z0-9]{1,255}$/,
      "Допускается только английские буквы и цифры без пробелов"
    ),
  generateCount: Yup.number()
    .nullable()
    .integer()
    .min(0, "Количество должно быть целым числом, большим или равным 0.")
    .required("Количество клиентов, которые могут использовать этот промокод"),

  codeLength: Yup.number()
    .integer("Количество должно быть целым числом.")
    .max(10, "Количество должно быть меньше или равно 10.")
    .required("Обязательное поле"),

  startDate: Yup.string().required("Требуется дата начала промо "),
  endDate: Yup.string().required("Требуется дата окончания промо "),
});
