import React, { FC } from "react";
import { InputLabel, MenuItem, FormControl } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { PromoTypeSelectInputProps } from "./types";
import { RedeemAbility } from "../../../../../../globalTypes";

const PromoTypeSelectInput: FC<PromoTypeSelectInputProps> = ({
  setFieldValue,
  valuesKey,
  initialValue,
}) => {
  const handleChange = (event: SelectChangeEvent<RedeemAbility>) => {
    setFieldValue(valuesKey, event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Тип кампании</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={initialValue}
        label="Тип кампании"
        onChange={handleChange}
      >
        <MenuItem value={RedeemAbility.one_time}>
          один код для всех пользователей
        </MenuItem>
        <MenuItem value={RedeemAbility.one_per_user}>
          коды для майндбокса
        </MenuItem>
      </Select>
    </FormControl>
  );
};
export default PromoTypeSelectInput;
