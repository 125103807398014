import { useContext, useEffect, useState } from "react";
import { ExpertCoverImageType } from "../../../../../../../../globalTypes";
import { useMutation } from "@apollo/client";
import {
  uploadExpertCoverImage,
  uploadExpertCoverImageVariables,
} from "../../../../../../graphql/__generated__/uploadExpertCoverImage";
import { UPLOAD_EXPERTS_IMAGES } from "../../../../../../graphql/UPLOAD_EXPERTS_IMAGES.graphql";
import globalDataCTX from "../../../../../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../../../../../contexts/globalContext/types";
import { GET_EXPERT_FOR_UPDATE } from "../../../../../graphql/GET_EXPERT_FOR_UPDATE";

export const useUploadImages = (expertId: number) => {
  const { setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);
  const [fileImage, setfileImage] = useState<File | null>(null);
  const [coverType, setCoverType] = useState<ExpertCoverImageType | null>(null);

  const [
    uploadImage,
    { data: uploadImageData, error: errorImage, loading: loadingImage },
  ] = useMutation<uploadExpertCoverImage, uploadExpertCoverImageVariables>(
    UPLOAD_EXPERTS_IMAGES
  );

  const resetStateAudioFileHandler = () => {
    setfileImage(null);
    setCoverType(null);
  };

  useEffect(() => {
    if (uploadImageData && !errorImage && !loadingImage) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Загружено");
    }

    if (errorImage) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(errorImage.message);
    }
  }, [
    errorImage,
    loadingImage,
    setNotificationEvent,
    setNotificationMsg,
    uploadImageData,
  ]);

  const uploadImageHandler = () => {
    uploadImage({
      variables: {
        expertID: expertId,
        cover: {
          coverType: coverType!,
          file: fileImage,
        },
      },
      refetchQueries: [
        {
          query: GET_EXPERT_FOR_UPDATE,
          variables: { id: Number(expertId) }
        },
      ],
    });
  };

  const handleInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setfileImage(e.target.files[0]);
      // Сбрасываем значение input после обработки файла
      e.target.value = '';
    }
  };

  const handleCoverTypeChange = (event: any) => {
    setCoverType(event?.target.value);
  };

  return {
    uploadImageHandler,
    fileImage,
    coverType,
    loadingImage,
    resetStateAudioFileHandler,
    handleInputFileChange,
    handleCoverTypeChange,
    disabledResetButton: !!fileImage || !!coverType,
  };
};
