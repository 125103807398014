import { ApolloQueryResult, useMutation } from "@apollo/client";
import { DELETE_CHASHBACK_RULES } from "../../graphql/DELETE_CHASHBACK_RULES";
import {
  deleteCashbackRule,
  deleteCashbackRuleVariables,
} from "../../graphql/__generated__/deleteCashbackRule";
import { useContext, useEffect } from "react";
import {
  getCashbackRulesVariables,
  getCashbackRules,
} from "../../graphql/__generated__/getCashbackRules";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";

export const useModalForDeleteCashBack = (
  ruleID: number | null,
  toggleOpenCloseModal: () => void,
  refetchGetCashBack: (
    variables?: Partial<getCashbackRulesVariables> | undefined
  ) => Promise<ApolloQueryResult<getCashbackRules>>
) => {
  const { setLoading, setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);

  const [deleteCashBackMutation, { data, error, loading }] = useMutation<
    deleteCashbackRule,
    deleteCashbackRuleVariables
  >(DELETE_CHASHBACK_RULES);

  const deleteCashBackHandler = () => {
    if (ruleID) {
      deleteCashBackMutation({ variables: { input: { ruleID } } });
    }
  };

  useEffect(() => {
    setLoading(loading);
    if (data && !error && !loading) {
      refetchGetCashBack();
      toggleOpenCloseModal();
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Правило успешно удалено");
    }
    if (error) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(error.message);
    }
  }, [data, error, loading, refetchGetCashBack, setLoading, setNotificationEvent, setNotificationMsg]);

  return { deleteCashBackHandler };
};
