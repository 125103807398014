const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
const COOKIE_ACCEPTED = "cookie_accepted";

export const isBrowser = () => typeof window !== "undefined";

const token_changed = isBrowser() ? new CustomEvent("token_changed") : null;
const token_expired = isBrowser() ? new CustomEvent("token_expired") : null;

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);
export const getCookieAccepted = () => localStorage.getItem(COOKIE_ACCEPTED);

export const setAccessToken = (value: string) => {
  localStorage.setItem(ACCESS_TOKEN, value);
  token_changed && document.dispatchEvent(token_changed);
};
export const setRefreshToken = (value: string) =>
  localStorage.setItem(REFRESH_TOKEN, value);

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  token_changed && document.dispatchEvent(token_changed);
};
export const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN);

export const setCookieAccepted = (value: any) => {
  localStorage.setItem(COOKIE_ACCEPTED, value);
};
export const removeCookieAccepted = () =>
  localStorage.removeItem(COOKIE_ACCEPTED);

export const triggerAuthorizationForm = () => {
  token_expired && document.dispatchEvent(token_expired);
};