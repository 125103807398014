import { useMutation } from "@apollo/client";
import { useState, ChangeEvent, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Enum_inputs } from ".";
import { PaymentButtonType } from "../../../../globalTypes";
import {
  createPaymentFormButtonTemplate,
  createPaymentFormButtonTemplateVariables,
} from "../../graphql/__generated__/createPaymentFormButtonTemplate";
import { CREATE_PAYMENT_FORM_BUTTON_TEMPLATES } from "../../graphql/CREATE_PAYMENT_FORM_BUTTON_TEMPLATES";
import { UPDATE_PAYMENT_FORM_BUTTON_TEMPLATES } from "../../graphql/UPDATE_PAYMENT_FORM_BUTTON_TEMPLATES";
import {
  updatePaymentFormButtonTemplate,
  updatePaymentFormButtonTemplateVariables,
} from "../../graphql/__generated__/updatePaymentFormButtonTemplate";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";

export const useCreateUpdateTemlateForm = () => {
  const location = useLocation();
  const { setNotificationEvent, setLoading, setNotificationMsg } =
    useContext(globalDataCTX);
  const isUpdatePage = !!location?.state?.buttonForUpdate;
  const createData = location?.state?.createButtonData;
  const updateData = location?.state?.buttonForUpdate;

  const typeLocationPayment = createData?.type || updateData?.type;
  const isProfileLocation = typeLocationPayment === "profile";

  const determineButtonType = (): PaymentButtonType => {
    if (isUpdatePage) return updateData?.buttonData?.buttonType;
    if (isProfileLocation) return PaymentButtonType.moneyOnly;
    return PaymentButtonType.moneyWithMinutes;
  };

  const [buttonType, setButtonType] = useState<PaymentButtonType>(
    determineButtonType()
  );
  const [minutesAmount, setMinutesAmount] = useState<string | undefined>(
    isUpdatePage ? updateData?.buttonData?.minutesAmount : null
  );
  const [moneyAmount, setMoneyAmount] = useState<string | undefined>(
    isUpdatePage ? updateData?.buttonData?.moneyAmount : null
  );

  const [
    createPaymentButtonMutation,
    {
      data: createDataMutation,
      error: createErrorMutation,
      loading: createLoadingMutation,
    },
  ] = useMutation<
    createPaymentFormButtonTemplate,
    createPaymentFormButtonTemplateVariables
  >(CREATE_PAYMENT_FORM_BUTTON_TEMPLATES);

  const [
    updatePaymentButtonMutation,
    {
      data: updateDataMutation,
      error: updateErrorMutation,
      loading: updateLoadingMutation,
    },
  ] = useMutation<
    updatePaymentFormButtonTemplate,
    updatePaymentFormButtonTemplateVariables
  >(UPDATE_PAYMENT_FORM_BUTTON_TEMPLATES);

  const createUpdateHandler = () => {
    createPaymentButtonMutation({
      variables: {
        input: {
          ButtonType: buttonType,
          paymentFormID: updateData?.ID || createData?.ID,
          MinutesAmount: minutesAmount ? Number(minutesAmount) : null,
          MoneyAmount: moneyAmount ? Number(moneyAmount) : null,
        },
      },
    });
  };

  const updateUpdateHandler = () => {
    updatePaymentButtonMutation({
      variables: {
        input: {
          buttonID: updateData?.buttonData?.id,
          buttonType,
          minutesAmount: minutesAmount ? Number(minutesAmount) : null,
          moneyAmount: moneyAmount ? Number(moneyAmount) : null,
        },
      },
    });
  };

  useEffect(() => {
    const isLoading = updateLoadingMutation || createLoadingMutation;
    setLoading(isLoading);

    if (updateDataMutation || createDataMutation) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg(
        `${updateDataMutation ? "Обновлено" : "Добавлено"} успешно`
      );
    }

    if (createErrorMutation || updateErrorMutation) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(
        createErrorMutation?.message || updateErrorMutation?.message || ""
      );
    }
  }, [
    createDataMutation,
    createErrorMutation,
    createLoadingMutation,
    setLoading,
    setNotificationEvent,
    setNotificationMsg,
    updateDataMutation,
    updateErrorMutation,
    updateLoadingMutation,
  ]);

  const onChangeInputsHandler = (
    event: ChangeEvent<HTMLInputElement> | any,
    input: Enum_inputs
  ) => {
    switch (input) {
      case Enum_inputs.BUTTON_TYPE:
        setButtonType(event.target.value);
        break;
      case Enum_inputs.MINUTES_AMOUNT:
        setMinutesAmount(event.target.value);
        break;
      case Enum_inputs.MONEY_AMOUNT:
        setMoneyAmount(event.target.value);
        break;
      default:
        break;
    }
  };

  return {
    isUpdatePage,
    createData,
    updateData,
    minutesAmount,
    onChangeInputsHandler,
    buttonType,
    moneyAmount,
    isProfileLocation,
    createUpdateHandler,
    updateUpdateHandler,
  };
};
