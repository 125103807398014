import React from "react";
import { Box, Pagination } from "@mui/material";
import { PaginationProps } from "./types";

const PaginationExample = ({ setPage, totalPages, page }: PaginationProps) => {
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        mt: "20px",
      }}
    >
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
      />
    </Box>
  );
};

export default PaginationExample;
