import * as React from "react";
import { Button, Menu, Avatar, Box, Typography, MenuItem } from "@mui/material";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Rating from "./components/raiting";
import { Stack } from "@mui/system";
import { AuthorUserOptionsProps } from "./types";
import { useAuthorUserOptions } from "./useAuthorUserOptions";
import IconComponent from "../../common/icon";

const AuthorUserOptions: React.FC<AuthorUserOptionsProps> = ({
  isExpert,
  clientImg,
  clientId,
  expert,
}) => {
  const { open, anchorEl, handleClick, handleClose } = useAuthorUserOptions();

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          padding: "0",
          display: "block",
        }}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <Avatar
            src={isExpert ? expert?.image.twox : clientImg}
            alt="userAvatar"
            variant="rounded"
            sx={{ margin: "4px" }}
          />
          <IconComponent icon={open ? faChevronUp : faChevronDown} />
        </Stack>

        {isExpert && (
          <Box textAlign={"left"} maxWidth={70}>
            <Typography fontSize={"12px"} color={"black"}>
              {expert?.name}
            </Typography>
            <Rating rating={expert?.rating} fontWeight={600} />
          </Box>
        )}
        {!isExpert && <Typography>{clientId}</Typography>}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>Все отзывы клиента</MenuItem>
        <MenuItem onClick={handleClose}>Профиль клиента</MenuItem>
      </Menu>
    </>
  );
};

export default AuthorUserOptions;
