import React, { FC } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { ReportTemplateBlockProps } from "./types";
import { ReportItemBlock, StylesReportTextField } from "./styles";
import { formatNumber } from "../../../../utils/numbersUtils";

const ReportTemplateBlock: FC<ReportTemplateBlockProps> = ({
  reportSpendsCountData,
  setReportSpendsCountData,
}) => {
  return (
    <Card sx={{ width: "50%" }}>
      <CardContent>
        <ReportItemBlock>
          <Typography>Дата</Typography>
          <Typography>{reportSpendsCountData?.Date || "--"}</Typography>
        </ReportItemBlock>

        <ReportItemBlock>
          <Typography>Новые пользователи</Typography>
          <Typography>
            {reportSpendsCountData?.NewUsersCount?.toString() || "--"}
          </Typography>
        </ReportItemBlock>

        <ReportItemBlock>
          <Typography>Выручка</Typography>
          <Typography>
            {reportSpendsCountData?.Revenue?.toString() || "--"}
          </Typography>
        </ReportItemBlock>

        <ReportItemBlock>
          <Typography>Пополнения</Typography>
          <Typography>
            {reportSpendsCountData?.PurchaseCount?.toString() || "--"}
          </Typography>
        </ReportItemBlock>

        <ReportItemBlock>
          <Typography>Затраты на экспертов</Typography>
          <StylesReportTextField
            value={formatNumber(reportSpendsCountData.expertSpendsCount) || ""}
            type="text"
            inputMode="numeric"
            onChange={(e) => {
              const enteredValue = e.target.value.replace(/\D/g, ""); // Удаляем все символы, кроме цифр
              setReportSpendsCountData({
                ...reportSpendsCountData,
                expertSpendsCount: Number(enteredValue),
              });
            }}
          />
        </ReportItemBlock>

        <ReportItemBlock>
          <Typography>Затраты на маркетинг</Typography>
          <StylesReportTextField
            value={
              formatNumber(reportSpendsCountData.marketingSpendsCount) || ""
            }
            type="text"
            inputMode="numeric"
            onChange={(e) => {
              const enteredValue = e.target.value.replace(/\D/g, ""); // Удаляем все символы, кроме цифр
              setReportSpendsCountData({
                ...reportSpendsCountData,
                marketingSpendsCount: Number(enteredValue),
              });
            }}
          />
        </ReportItemBlock>
      </CardContent>
    </Card>
  );
};

export default ReportTemplateBlock;
