import React, { FC } from "react";
import GroupAvatars from "../groupAvatars/GroupAvatars";
import { Button, InputAdornment, Typography } from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ClientSearchPanelProps } from "./types";
import { $colorVariables } from "../../../../styles/colorVariables";
import { editTypesEnum } from "../../types";
import {
  StyledAvatarsBlock,
} from "./styles";
import { StyledOutlinedInput, StyledSearchBlock, StyledSearchPanelWrapper } from "../../../../styles/common/styles";
import IconComponent from "../../../common/icon";

const ClientSearchPanel: FC<ClientSearchPanelProps> = ({
  setSearchTextId,
  getUserHandler,
  openModalHandler,
  searchTextId,
  isSomeEditClient,
  selectedUsers,
}) => {
  const handlerSearchText = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchTextId(event.target.value);
  };

  return (
    <StyledSearchPanelWrapper>
      <StyledSearchBlock>
        <StyledOutlinedInput
          placeholder="Поиск по ID клиента"
          onChange={handlerSearchText}
          value={searchTextId}
          endAdornment={
            <InputAdornment position="end">
              <IconComponent icon={faMagnifyingGlass} />
            </InputAdornment>
          }
        />
        <Button variant="contained" color="info" onClick={getUserHandler}>
          Поиск
        </Button>
        {isSomeEditClient && (
          <Button
            variant="contained"
            color="info"
            onClick={() => openModalHandler(editTypesEnum.SOME_EDIT)}
          >
            редактировать несколько клиентов
          </Button>
        )}
      </StyledSearchBlock>
      {!!selectedUsers.length && (
        <StyledAvatarsBlock>
          <Typography mr={2} color={$colorVariables.$white}>
            Выбран
          </Typography>
          <GroupAvatars selectedUsers={selectedUsers} />
        </StyledAvatarsBlock>
      )}
    </StyledSearchPanelWrapper>
  );
};

export default ClientSearchPanel;
