import * as Yup from "yup";

export const validationSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 charaters")
    .required("Password is required"),
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  gender: Yup.string().required("Gender is required"),
  title: Yup.string().required("Title is required"),
  careerStartDate: Yup.string().required("CareerStartDate is required"),
  chatRate: Yup.number().required("ChatRate is required"),
  videoCallRate: Yup.number().required("VideoCallRate is required"),
  phoneCallRate: Yup.number().required("PhoneCallRate is required"),
  tg: Yup.string().required("Telegram is required"),

  scopes: Yup.array()
    .test("notEmptyArr", "Scopes is required", (value) => {
      return value?.length! > 0;
    })
    .required("Scopes is required"),

  skills: Yup.array()
    .test("notEmptyArr", "skills is required", (value) => {
      return value?.length! > 0;
    })
    .required("Skills is required"),

  methods: Yup.array()
    .test("notEmptyArr", "methods is required", (value) => {
      return value?.length! > 0;
    })
    .required("Methods is required"),
});

export const validationSchemaUpdate = Yup.object({
  email: Yup.string().email("Email is invalid"),
  password: Yup.string().min(6, "Password must be at least 6 charaters"),
  name: Yup.string(),
  description: Yup.string(),
  gender: Yup.string(),
  title: Yup.string(),
  careerStartDate: Yup.string(),
  chatRate: Yup.number(),
  videoCallRate: Yup.number(),
  phoneCallRate: Yup.number(),
  tg: Yup.string(),
  scopes: Yup.array(),
  skills: Yup.array(),
  methods: Yup.array(),
});
