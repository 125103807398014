import { Box, Container, Typography } from "@mui/material";
import LoginForm from "../../layout/auth/login";
import { StyledContent } from "./styles";


export default function LoginPage() {
  return (
    <Box>
      <Container maxWidth="sm">
        <StyledContent>
          <Typography variant="h4" gutterBottom>
            Sign in to Lunaro Admin
          </Typography>
          <LoginForm />
        </StyledContent>
      </Container>
    </Box>
  );
}
