import React from 'react'
import { StyledInstructionsContainer } from './styles'

const InstructionsBlock = () => {
  return (
    <StyledInstructionsContainer>
      &bull; Ip может быть в белом списке и в черном списке.<br />
      &bull; Черный список приоритетнее.<br />
      &bull; Для Ip в белом списке можно задать количество регистраций с этого Ip.<br />
    </StyledInstructionsContainer>
  )
}

export default InstructionsBlock
