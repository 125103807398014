import { StyledCloseButton, StyleModalContant } from "./styles";
import { Avatar, Stack, Typography, Modal } from "@mui/material";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ModalProps } from "./types";
import { FC } from "react";
import { $colorVariables } from "../../../styles/colorVariables";
import IconComponent from "../icon";

const BasicModal: FC<ModalProps> = ({
  children,
  isOpen,
  handelClose,
  title,
  description,
  imgSrc,
  width = "auto",
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={handelClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <StyleModalContant
        sx={{ width: width, maxWidth: "90%", maxHeight: "90%" }}
      >
        <StyledCloseButton onClick={handelClose}>
          <IconComponent icon={faXmark} size="xl" />
        </StyledCloseButton>
        {title && (
          <Stack direction="row" mb={"10px"}>
            <Typography
              fontSize={25}
              color={$colorVariables.$blue01}
              fontWeight={"600"}
            >
              {title}
            </Typography>
            {imgSrc && <Avatar variant="rounded" src={imgSrc} sx={{ ml: 4 }} />}
          </Stack>
        )}
        {description && (
          <Typography sx={{ mb: "50px", color: $colorVariables.$blue01 }}>
            {description}
          </Typography>
        )}
        {children}
      </StyleModalContant>
    </Modal>
  );
};

export default BasicModal;
