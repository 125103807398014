import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { $colorVariables } from "../../../styles/colorVariables";

export const HeaderTitle = styled(Typography)({
  color: $colorVariables.$blue01,
  fontSize: "24px",
  fontWeight: "600",
  marginBottom: '20px'
});
