import { useReducer } from "react";
import ReviewsCTX, { ReviewsConextInitialState } from "./ReviewsCTX";
import reviewsReducer from "./reviewsReducer";
import { ReviewsCTX_Props } from "./types";

const ReviewsCTXPrvidertApp = ({ children }: ReviewsCTX_Props) => {
  const [state, dispatch] = useReducer(
    reviewsReducer,
    ReviewsConextInitialState
  );

  return (
    <ReviewsCTX.Provider value={{ state, dispatch }}>
      {children}
    </ReviewsCTX.Provider>
  );
};

export default ReviewsCTXPrvidertApp;
