import React from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { HeaderTitle } from "../../../schedule/allSchedules/styles";
import { PaymentButtonType } from "../../../../globalTypes";
import { useCreateUpdateTemlateForm } from "./useCreateUpdateTemlateForm";

export enum Enum_inputs {
  BUTTON_TYPE = "button_type",
  MINUTES_AMOUNT = "minutes_amount",
  MONEY_AMOUNT = "money_amount",
}

const CreateUpdateTemlateForm = () => {
  const {
    isUpdatePage,
    createData,
    updateData,
    minutesAmount,
    onChangeInputsHandler,
    buttonType,
    createUpdateHandler,
    moneyAmount,
    updateUpdateHandler,
    isProfileLocation,
  } = useCreateUpdateTemlateForm();

  return (
    <Box>
      <Divider textAlign="right">
        <HeaderTitle>
          {isUpdatePage ? "Обновить " : "Добавить "}
          {createData?.type || updateData?.type}
        </HeaderTitle>
      </Divider>
      <Box display={"flex"} width={"100%"}>
        <Card sx={{ minWidth: "50%" }}>
          <CardActionArea>
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <TextField
                value={minutesAmount}
                label="Минуты"
                variant="outlined"
                onChange={(event) =>
                  onChangeInputsHandler(event, Enum_inputs.MINUTES_AMOUNT)
                }
                disabled={isProfileLocation}
              />
              <TextField
                value={moneyAmount}
                label="Деньги"
                variant="outlined"
                onChange={(event) =>
                  onChangeInputsHandler(event, Enum_inputs.MONEY_AMOUNT)
                }
                disabled={!isProfileLocation}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Тип кнопки оплаты
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Тип кнопки оплаты"
                  value={buttonType}
                  onChange={(event) =>
                    onChangeInputsHandler(event, Enum_inputs.BUTTON_TYPE)
                  }
                >
                  <MenuItem
                    value={PaymentButtonType.moneyOnly}
                    disabled={!isProfileLocation}
                  >
                    {PaymentButtonType.moneyOnly}
                  </MenuItem>
                  <MenuItem
                    value={PaymentButtonType.moneyWithMinutes}
                    disabled={isProfileLocation}
                  >
                    {PaymentButtonType.moneyWithMinutes}
                  </MenuItem>
                </Select>
              </FormControl>
            </CardContent>
          </CardActionArea>
          <CardActions sx={{ float: "right", marginRight: 1 }}>
            <Button
              size="medium"
              variant="contained"
              onClick={isUpdatePage ? updateUpdateHandler : createUpdateHandler}
            >
              {`${isUpdatePage ? "Обновить" : "Добавить"}`}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
};

export default CreateUpdateTemlateForm;
