import { useState } from "react";
import { useMutation, QueryOptions } from "@apollo/client";
import { GET_ALL_REVIEWS } from "../graphql/GET_ALL_REVIEWS.graphql";
import { UNPIN_REVIEW } from "../graphql/UNPIN_REVIEW";
import {
  unpinReview,
  unpinReviewVariables,
} from "../graphql/__generated__/unpinReview";
import {
  pinReview,
  pinReviewVariables,
} from "../graphql/__generated__/pinReview";
import { PIN_REVIEW } from "../graphql/PIN_REVIEW";

export const usePinReviewsController = (reviewID: number) => {
  const [show, setShow] = useState(false);

  const [unPinReviewMutation] = useMutation<unpinReview, unpinReviewVariables>(
    UNPIN_REVIEW,
    {
      variables: {
        reviewID,
      },
      refetchQueries: [
        {
          query: GET_ALL_REVIEWS,
        } as QueryOptions,
      ],
    }
  );

  const [pinReviewMutation] = useMutation<pinReview, pinReviewVariables>(
    PIN_REVIEW,
    {
      variables: {
        reviewID,
      },
      refetchQueries: [
        {
          query: GET_ALL_REVIEWS,
        } as QueryOptions,
      ],
    }
  );

  const toggleShowControlButton = () => {
    setShow((prevStatus) => {
      return !prevStatus;
    });
  };

  const pinReviewHandler = () => {
    pinReviewMutation();
    setShow(!show);
  };

  const unPinReviewHandler = () => {
    unPinReviewMutation();
    setShow(!show);
  };

  return {
    unPinReviewHandler,
    pinReviewHandler,
    toggleShowControlButton,
    show,
  };
};
