import React, { FC } from "react";
import { SessionCardProps } from "./types";
import {
  Card,
  Box,
  Typography,
  CardContent,
  TextField,
  CardActions,
  Button,
  Avatar,
} from "@mui/material";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import IconComponent from "../../../common/icon";

const SessionCard: FC<SessionCardProps> = ({
  reason,
  setReason,
  sessionData,
  isReadyToChangeStatus,
  endSessionHandler,
  removeSessionCard,
}) => {
  const { expert, client, status } = sessionData.getSessionByAdmin;
  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            textAlign: "left",
          }}
        >
          <Avatar
            src={expert.image.onex}
            alt="userAvatar"
            variant="rounded"
            sx={{
              height: 60,
              width: 60,
              objectFit: "contain",
              borderRadius: "10px",
            }}
          />
          <Typography fontSize={13} mt={2}>
            {sessionData?.getSessionByAdmin.expert.name}
          </Typography>
        </Box>
        <Typography gutterBottom variant="h6">
          {`Статус сессии  ${status.toUpperCase()}`}
        </Typography>

        <Box
          sx={{
            textAlign: "right",
          }}
        >
          <Avatar
            src={client.image.onex}
            alt="userAvatar"
            variant="rounded"
            sx={{
              height: 60,
              width: 60,
              objectFit: "contain",
              borderRadius: "10px",
            }}
          />
          <Typography fontSize={13} mt={2}>
            {client.name}
          </Typography>
        </Box>
      </Box>
      <CardContent>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Причина "
          variant="outlined"
          value={reason}
          onChange={(event) => setReason(event.target.value)}
        />
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          size="small"
          disabled={!isReadyToChangeStatus}
          onClick={endSessionHandler}
        >
          Завершить сессию
        </Button>
        <Button
          sx={{ alignItems: "flex-end" }}
          onClick={removeSessionCard}
          title="Убрать из списка"
        >
          <IconComponent icon={faCircleXmark} color="red" size="xl" beat />
        </Button>
      </CardActions>
    </Card>
  );
};

export default SessionCard;
