import React, { FC } from "react";
import { InputLabel, MenuItem, FormControl } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ExpertDisplayStatusSelectProps } from "./types";
import { ExpertDisplayStatus } from "../../../../../../../globalTypes";

const ExpertDisplayStatusSelect: FC<ExpertDisplayStatusSelectProps> = ({
  initialValue,
  setExpertDisplayStatus
}) => {
  const handleChange = (event: SelectChangeEvent<ExpertDisplayStatus>) => {
    setExpertDisplayStatus(event.target.value as ExpertDisplayStatus);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">
        Display status
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={initialValue}
        onChange={handleChange}
        label="Display status"
      >
        <MenuItem value={ExpertDisplayStatus.visible_for_all}>{ExpertDisplayStatus.visible_for_all}</MenuItem>
        <MenuItem value={ExpertDisplayStatus.visible_for_admin}>{ExpertDisplayStatus.visible_for_admin}</MenuItem>
        <MenuItem value={ExpertDisplayStatus.visible_false}>{ExpertDisplayStatus.visible_false}</MenuItem>

      </Select>
    </FormControl>
  );
};
export default ExpertDisplayStatusSelect;
