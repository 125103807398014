import { gql } from "@apollo/client";

export const DELETE_REVIEW = gql`
  mutation deleteReview($reviewID: Int!) {
    deleteReview(reviewID: $reviewID)
  }
`;



