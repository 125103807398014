import React from "react";
import { TableHead, TableRow, Skeleton } from "@mui/material";
import { StyledTableCell } from "../../../experts/expertsTableHead/styles";
import { ipSectionsNamesForTabelHeader } from "./constants";

const IpsTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        {ipSectionsNamesForTabelHeader.map(({ name }, idx) => {
          return true ? (
            <StyledTableCell key={idx} align="left">
              {name}
            </StyledTableCell>
          ) : (
            <StyledTableCell key={idx} align="left">
              <Skeleton animation="wave" />
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default IpsTableHeader;
