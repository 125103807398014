import { gql } from "@apollo/client";

export const CORRECT_BALANCE = gql`
  mutation correctBalance($input: correctBalanceInput!) {
    correctBalance(input: $input) {
      amount
      currency
    }
  }
`;
