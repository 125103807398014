import {
  getUserInfo_getUserInfo,
} from "./graphql/__generated__/getUserInfo";

export interface getUserInfoType extends getUserInfo_getUserInfo {
  checked: boolean;
}

export enum editTypesEnum {
  SOME_EDIT = "SOME_EDIT",
  SINGLE_EDIT = "SINGLE_EDIT",
  CLOSED = "CLOSED",
}

export type singleEditClientType = {
  id: number;
  image: string;
};

export type openModalType = {
  modalName: editTypesEnum;
  status: boolean;
};

export enum TypeInputNameEnum {
  REASON = "REASON",
  AMOUNT = "AMOUNT",
}
