import { StyledTableCellBody } from "./styles";
import Raiting from "../../reviews/autherOptions/components/raiting";
import { Avatar, Button, Skeleton, TableRow, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, Fragment } from "react";
import { ExpertsTableBodyProps } from "./types";
import { useNavigate } from "react-router-dom";
import React from "react";

const ExpertsTableBody: FC<ExpertsTableBodyProps> = ({
  allExpertData,
  loadingExperts,
}) => {
  const navigate = useNavigate();

  const navigationToPagesHandler = (event: any, to: string, state?: any) => {
    event.stopPropagation();
    navigate(to, { state });
  };

  return (
    <>
      {allExpertData?.map((item, idx) => {
        return (
          <Fragment key={idx}>
            {loadingExperts ? (
              <TableRow 
              >
                <StyledTableCellBody>
                  <Skeleton variant="text" animation="wave" />
                </StyledTableCellBody>
                <StyledTableCellBody>
                  <Skeleton animation="wave" />
                </StyledTableCellBody>
                <StyledTableCellBody>
                  <Skeleton variant="text" animation="wave" />
                </StyledTableCellBody>
                <StyledTableCellBody>
                  <Skeleton variant="text" animation="wave" />
                </StyledTableCellBody>
              </TableRow>
            ) : (
              <TableRow
                onClick={(event) =>
                  navigationToPagesHandler(event, `details/${item?.node.id}`)
                }
                sx={{ cursor: "pointer" }}
              >
                <StyledTableCellBody>{item?.node?.id}</StyledTableCellBody>
                <StyledTableCellBody>
                  <Stack direction="row" alignItems="center">
                    <Avatar variant="rounded" src={item?.node?.image?.onex} />
                    <Typography ml={1}>{item?.node?.name}</Typography>
                  </Stack>
                </StyledTableCellBody>
                <StyledTableCellBody>
                  <Stack direction="row">
                    <Raiting fontWeight={600} rating={item?.node?.rating} />
                    <Typography marginLeft={"10px"}>
                      {item?.node?.reviewsCount}
                    </Typography>
                    <Typography marginLeft={"4px"}> отзывов</Typography>
                  </Stack>
                </StyledTableCellBody>
                <StyledTableCellBody status={item?.node.displayStatus}>
                  {item?.node.displayStatus}
                </StyledTableCellBody>
                <StyledTableCellBody status={item?.node.activityStatus}>
                  {item?.node.activityStatus}
                </StyledTableCellBody>

                <StyledTableCellBody>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ textTransform: "capitalize" }}
                    onClick={(event) =>
                      navigationToPagesHandler(
                        event,
                        `${item?.node.id}`,
                        item?.node
                      )
                    }
                  >
                    Расписание
                  </Button>
                </StyledTableCellBody>
              </TableRow>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default ExpertsTableBody;
