import React, { FC } from "react";
import { BalanceClientContentModalProps } from "./types";
import { Box, Button } from "@mui/material";
import { acceptOnlyNumbers } from "../../../../utils/numbersUtils";
import { StyledOutlinedInput } from "../../styles";
import { TypeInputNameEnum } from "../../types";
import { filteredText } from "../../utils";
import { useBalanceClientContentModal } from "./useBalanceClientContentModal";
import Loader from "../../../common/loader";

const BalanceClientContentModal: FC<BalanceClientContentModalProps> = ({
  singleEditClientData,
}) => {
  const {
    setAmountBalanceClientHandler,
    balanceClient,
    setBalanceClient,
    reasonBalanceClient,
    setReasonBalanceClient,
    controlBalanceLoading,
  } = useBalanceClientContentModal(singleEditClientData);

  const changeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: TypeInputNameEnum
  ) => {
    const textInput = event.target.value;

    if (type === TypeInputNameEnum.REASON) {
      setReasonBalanceClient(filteredText(textInput));
    } else {
      setBalanceClient(textInput);
    }
  };

  const isNegativeNumber = balanceClient.trim().slice(0, 1) === "-";
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <StyledOutlinedInput
        placeholder="Опишите причину"
        onChange={(e) => changeHandler(e, TypeInputNameEnum.REASON)}
        value={reasonBalanceClient}
      />
      <StyledOutlinedInput
        placeholder="Количество рублей"
        onChange={(e) => changeHandler(e, TypeInputNameEnum.AMOUNT)}
        value={`${balanceClient}`}
        onInput={acceptOnlyNumbers}
      />
      <Button
        disabled={!balanceClient || controlBalanceLoading}
        variant="contained"
        onClick={setAmountBalanceClientHandler}
      >
        {isNegativeNumber ? " Снимать " : " Добавить "}
        {controlBalanceLoading && <Loader />}
      </Button>
    </Box>
  );
};

export default BalanceClientContentModal;
