import { gql } from "@apollo/client";

export const GET_AFFISE_OFFERS = gql`
  query getAffiseOffersForSubPage{
    getAffiseOffers {
      Id
      Status
      Privacy
      Title
      AllowedAffiliates {
        Id
        Email
      }
    }
  }
`;
