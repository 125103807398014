import { gql } from "@apollo/client";

export const GET_REPORT = gql`
  query getReport($input: getReportInput!) {
    getReport(input: $input) {
      Date
      NewUsersCount
      Revenue
      PurchaseCount
      ExpertSpends
      MarketingSpends
    }
  }
`;
