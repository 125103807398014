import Paper from "@mui/material/Paper";
import {
  ViewState,
  GroupingState,
  IntegratedGrouping,
  IntegratedEditing,
  EditingState,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Resources,
  Appointments,
  AppointmentTooltip,
  GroupingPanel,
  DayView,
  DragDropProvider,
  AppointmentForm,
  CurrentTimeIndicator,
  Toolbar,
  DateNavigator,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import moment from "moment";
import TimeTableCell from "../components/timeTableCell";
import DayScaleCell from "../components/dayScaleCell";
import {
  StyledOverlayModalComponent,
  StyledTimeScaleTickCellComponent,
  StyledWeekViewTimeScaleLabel,
} from "../scheduler/styles";
import { useAllSchedule } from "./useAllSchedule";
import SelectExperts from "./components/selectExperts/SelectExperts";
import SelectIntervalCount from "./components/selectIntervalCount";
import { Box, Button } from "@mui/material";
import { HeaderTitle } from "./styles";
import { grouping, locations } from "./constants";
import DateTimeEditor from "../components/dateTimeEditor";
import NavigationButtonComponent from "../components/navigationButtonComponent";

const SchedulesOfSeveralExperts = () => {
  const {
    dataSchedule,
    selectExpert,
    setSelectExpert,
    owners,
    dataExperts,
    loading,
    commitChanges,
    selectIntervalCount,
    setSelectIntervalCount,
    getExpertsScheduleHandler,
  } = useAllSchedule();

  const resourcesData = [
    {
      fieldName: "members",
      title: "Members",
      instances: owners || [],
      allowMultiple: true,
    },
    {
      fieldName: "roomId",
      title: "Location",
      instances: locations,
    },
  ];

  return (
    <>
      <HeaderTitle>Расписание для нескольких экспертов</HeaderTitle>
      <Paper sx={{ p: 1 }}>
        <Box display={"flex"} component={"div"} m={2}>
          <SelectExperts
            selectExpert={selectExpert}
            setSelectExpert={setSelectExpert}
            dataExperts={dataExperts}
          />
          <SelectIntervalCount
            selectIntervalCount={selectIntervalCount}
            setSelectIntervalCount={setSelectIntervalCount}
          />
          <Button
            variant="outlined"
            size="small"
            sx={{ ml: 2, height: "55px" }}
            onClick={getExpertsScheduleHandler}
          >
            Применить Фильтр
          </Button>
        </Box>

        <Scheduler
          data={dataSchedule}
          firstDayOfWeek={1}
          locale={"ru-RU"}
          height={"auto"}
        >
          <ViewState defaultCurrentDate={moment().toDate()} />
          <EditingState onCommitChanges={commitChanges} />
          <GroupingState grouping={grouping} />

          <DayView
            intervalCount={selectIntervalCount}
            cellDuration={60}
            // timeTableCellComponent={TimeTableCell}
            timeTableCellComponent={(props) => {
              return <TimeTableCell {...props} isLoading={loading} />;
            }}
            dayScaleCellComponent={DayScaleCell}
            timeScaleLabelComponent={(props) => (
              <StyledWeekViewTimeScaleLabel {...props} />
            )}
            timeScaleTickCellComponent={(props) => (
              <StyledTimeScaleTickCellComponent {...props} />
            )}
          />
          <Toolbar />
          <DateNavigator
            navigationButtonComponent={NavigationButtonComponent}
          />
          <TodayButton messages={{ today: "Текущий день" }} />
          <Appointments />
          <Resources data={resourcesData} />
          <IntegratedGrouping />
          <IntegratedEditing />
          <AppointmentTooltip showOpenButton showDeleteButton />
          {selectExpert.length > 0 && (
            <AppointmentForm
              booleanEditorComponent={() => null}
              textEditorComponent={() => null}
              labelComponent={() => null}
              overlayComponent={StyledOverlayModalComponent}
              dateEditorComponent={DateTimeEditor}
              resourceEditorComponent={() => null}
            />
          )}
          <GroupingPanel />
          <DragDropProvider />
          <CurrentTimeIndicator
            indicatorComponent={(props) => {
              return <CurrentTimeIndicator.Indicator {...props} />;
            }}
          />
        </Scheduler>
      </Paper>
    </>
  );
};

export default SchedulesOfSeveralExperts;
