import { gql } from "@apollo/client";

export const UPDATE_AFFILIATE_REFERRAL_PARTICIPATION = gql`
  mutation updateAffiliateReferralParticipation(
    $userID: Int!
    $status: ReferralProgramStatus!
  ) {
    updateAffiliateReferralParticipation(userID: $userID, status: $status)
  }
`;
