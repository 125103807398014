import { Box, ButtonBase } from "@mui/material";
import { styled } from "@mui/system";
import { $colorVariables } from "../../../styles/colorVariables";

export const StyledCloseButton = styled(ButtonBase)({
  position: "absolute",
  right: "15px",
  top: "15px",
});

export const StyleModalContant = styled(Box)({
  position: "relative",
  backgroundColor: $colorVariables.$white,
  boxShadow: "0 0 20px #000000",
  padding: '20px',
  borderRadius: "20px",
  overflow: "auto",
  "::-webkit-scrollbar": {
    display: "none",
  },
});
