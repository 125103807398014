import { useEffect } from "react";

export const useKeyboardEvent = (
  keyName: string,
  keyboardEventFunction: () => void
) => {
  useEffect(() => {
    const eventHandler = (event: KeyboardEvent) => {
      if (event.key === keyName) {
        keyboardEventFunction();
      }
    };

    document.addEventListener("keydown", eventHandler);

    return () => {
      document.removeEventListener("keydown", eventHandler);
    };
  }, [keyName, keyboardEventFunction]);
};
