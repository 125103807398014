import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DataPickerIcon from "../../../../assets/icons/DataPickerIcon";

const DateTimeEditor = ({ onValueChange, value }: any) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        views={["year", "day", "hours", "minutes"]}
        components={{
          OpenPickerIcon: DataPickerIcon,
          LeftArrowButton: undefined,
          RightArrowButton: undefined,
        }}
        renderInput={(props) => (
          <TextField {...props} sx={{ width: 270, p: 2 }} />
        )}
        value={value}
        inputFormat="MM/DD/YY HH:mm"
        ampm={false}
        onChange={(newValue) => {
          onValueChange(new Date(newValue as string) );
        }}
      />
    </LocalizationProvider>
  );
};

export default DateTimeEditor;
