import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useGradeSelect } from "./useGradeSelect";
import { gradeData } from "./constants";
import { StyledGradeContainer } from "./styles";
import { $colorVariables } from "../../../../../styles/colorVariables";

const GradeSelect = () => {
  const { handleChange, gradeFilter } = useGradeSelect();

  return (
    <StyledGradeContainer>
      <FormControl fullWidth size="small">
        <InputLabel id="grade-select" sx={{ color: $colorVariables.$blue03 }}>
          Оценка
        </InputLabel>
        <Select
          labelId="grade-select"
          id="demo-simple-select"
          value={`${gradeFilter?.length ? gradeFilter?.[0] : ""}`}
          label="Оценка"
          onChange={handleChange}
          sx={{ borderRadius: "12px" }}
        >
          {gradeData?.map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </StyledGradeContainer>
  );
};

export default GradeSelect;
