import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconComponentProps } from "./types";

const IconComponent: FC<IconComponentProps> = ({
  icon,
  color,
  beat,
  title,
  cursor,
  style,
  onClick,
  width,
  height,
}) => {
  return (
    <FontAwesomeIcon
      icon={icon}
      color={color}
      beat={beat}
      title={title}
      cursor={cursor}
      style={style}
      onClick={onClick}
      width={width}
      height={height}
    />
  );
};

export default IconComponent;
