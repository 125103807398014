import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import { GET_SCHEDULE } from "../graphql/GET_SCHEDULE.graphql";
import {
  getSchedule,
  getScheduleVariables,
} from "../graphql/__generated__/getSchedule";
import { indigo } from "@mui/material/colors";
import {
  getAllExpertsData,
  getAllExpertsDataVariables,
  getAllExpertsData_getExperts_edges_node,
} from "../graphql/__generated__/getAllExpertsData";
import { GET_ALL_EXPERTS_DATA } from "../graphql/GET_EXPERTS_DATA.graphgl";
import { ChangeSet } from "@devexpress/dx-react-scheduler";
import {
  createScheduleInterval,
  createScheduleIntervalVariables,
} from "../graphql/__generated__/createScheduleInterval";
import { CREATE_SCHEDULE_INTERVAL } from "../graphql/CREATE_SCHEDULE_INTERVAL.graphql";
import { ScheduleStatus, ScheduleType } from "../../../globalTypes";
import {
  deleteScheduleIntervals,
  deleteScheduleIntervalsVariables,
} from "../graphql/__generated__/deleteScheduleIntervals";
import { DELETE_SCHEDULE_INTERVAL } from "../graphql/DELETE_SCHEDULE_INTERVAL.graphql";
import { startDateLastWeek } from "./components/constants";
import { UPDATE_SCHEDULE_INTERVAL } from "../graphql/UPDATE_SCHEDULE_INTERVAL.graphql";
import {
  updateScheduleInterval,
  updateScheduleIntervalVariables,
} from "../graphql/__generated__/updateScheduleInterval";
import { notificationEventType } from "../../../contexts/globalContext/types";
import globalDataCTX from "../../../contexts/globalContext/globalDataCTX";
import { dataScheduleTypes, ownersTypes } from "./types";

export const useAllSchedule = () => {
  const [dataSchedule, setDataSchedule] = useState<dataScheduleTypes[]>([]);
  const [selectIntervalCount, setSelectIntervalCount] = useState<number>(1);
  const [expertUuid, setExpertUuid] = useState<string[]>([]);
  const [owners, setOwners] = useState<ownersTypes[]>([]);
  const { setNotificationMsg, setLoading, setNotificationEvent, loading } =
    useContext(globalDataCTX);

  const [selectExpert, setSelectExpert] = useState<
    getAllExpertsData_getExperts_edges_node[]
  >([]);

  const { data: dataExperts, loading: expertsLoading } = useQuery<
    getAllExpertsData,
    getAllExpertsDataVariables
  >(GET_ALL_EXPERTS_DATA);

  const [
    createInterval,
    { data: dataCreate, loading: createLoading, error: createError },
  ] = useMutation<createScheduleInterval, createScheduleIntervalVariables>(
    CREATE_SCHEDULE_INTERVAL
  );

  const [
    deleteSchedule,
    { data: dataDelete, loading: deleteLoading, error: deleteError },
  ] = useMutation<deleteScheduleIntervals, deleteScheduleIntervalsVariables>(
    DELETE_SCHEDULE_INTERVAL
  );

  const [
    updateInterval,
    { loading: updateIntervalLoading, error: updateIntervalError },
  ] = useMutation<updateScheduleInterval, updateScheduleIntervalVariables>(
    UPDATE_SCHEDULE_INTERVAL
  );

  const [
    getSchedule,
    { data: expertsSchedules, loading: scheduleLoading, error: errorSchedule },
  ] = useLazyQuery<getSchedule, getScheduleVariables>(GET_SCHEDULE);

  const getExpertsScheduleHandler = () => {
    expertUuid.length > 0 &&
      getSchedule({
        variables: {
          filter: {
            ExpertFilter: expertUuid,
            DateFilter: {
              laterThan: startDateLastWeek,
            },
          },
        },
      });
  };

  useEffect(() => {
    setLoading(
      createLoading ||
        updateIntervalLoading ||
        deleteLoading ||
        scheduleLoading ||
        expertsLoading
    );
    if (createError || updateIntervalError || deleteError || errorSchedule) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(
        createError?.message ||
          updateIntervalError?.message ||
          deleteError?.message ||
          errorSchedule?.message ||
          ""
      );
    } else if (dataDelete || dataCreate) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Успех");
    } else {
      setNotificationEvent(notificationEventType.noEvent);
    }
  }, [
    createLoading,
    updateIntervalLoading,
    expertsLoading,
    scheduleLoading,
    deleteLoading,
    createError,
    updateIntervalError,
    errorSchedule,
    deleteError,
    dataCreate,
    dataDelete,
    setLoading,
    setNotificationEvent,
    setNotificationMsg,
    expertUuid,
  ]);

  useEffect(() => {
    if (!selectExpert) {
      return;
    }

    const expertUuidData: string[] = [];
    const dataOwners = selectExpert.map((item, idx) => {
      expertUuidData.push(item?.uuid);
      return {
        text: `${item?.name}`,
        id: idx + 1,
        color: indigo,
      };
    });

    setOwners(dataOwners);
    setExpertUuid(expertUuidData);
  }, [selectExpert]);

  useEffect(() => {
    const changeDataScheduleData = expertsSchedules?.getSchedule?.map(
      (item) => {
        const index = expertUuid.indexOf(item?.expertID);
        return {
          ...item,
          members: [index + 1],
          roomId: 1,
        } as dataScheduleTypes;
      }
    );
    setDataSchedule(changeDataScheduleData || []);
  }, [expertsSchedules, owners, expertUuid]);

  const commitChanges = useCallback(
    ({ added, changed, deleted }: ChangeSet) => {
      if (added) {
        const { startDate, endDate } = added;
        const currentEditExpertUuid = expertUuid?.[added.members[0] - 1];

        const input = {
          start: startDate,
          end: endDate,
          expertID: currentEditExpertUuid,
          status: ScheduleStatus.ScheduleStatusScheduled,
          type: ScheduleType.ScheduleTypePublic,
        };

        createInterval({
          variables: { input },
          refetchQueries: [
            {
              query: GET_SCHEDULE,
              variables: {
                filter: {
                  ExpertFilter: expertUuid,
                  DateFilter: {
                    laterThan: startDateLastWeek,
                  },
                },
              },
            },
          ],
        });
      }
      if (changed) {
        // Find the appointment to be updated
        const changedID = Object.keys(changed)[0];
        const oldAppointment = dataSchedule?.find(
          (appointment: { id: string | number }) => appointment.id === changedID
        );

        // Update the appointment directly
        const updatedAppointment = {
          ...oldAppointment,
          ...changed[changedID],
          startDate: changed[changedID]?.startDate || oldAppointment?.startDate,
          endDate: changed[changedID]?.endDate || oldAppointment?.endDate,
        };

        // Update the appointment on the server
        updatedAppointment &&
          updateInterval({
            variables: {
              input: {
                id: updatedAppointment?.id,
                start: updatedAppointment.startDate,
                end: updatedAppointment.endDate,
                status: ScheduleStatus.ScheduleStatusScheduled,
                type: ScheduleType.ScheduleTypePublic,
              },
            },
            refetchQueries: [
              {
                query: GET_SCHEDULE,
                variables: {
                  filter: {
                    ExpertFilter: expertUuid,
                    DateFilter: {
                      laterThan: startDateLastWeek,
                    },
                  },
                },
              },
            ],
          });
      }
      if (deleted) {
        deleteSchedule({
          variables: {
            input: [deleted],
          },
          refetchQueries: [
            {
              query: GET_SCHEDULE,
              variables: {
                filter: {
                  ExpertFilter: expertUuid,
                  DateFilter: {
                    laterThan: startDateLastWeek,
                  },
                },
              },
            },
          ],
        });
      }
    },
    [createInterval, deleteSchedule, updateInterval, expertUuid, dataSchedule]
  );

  return {
    dataSchedule,
    selectExpert,
    loading,
    setSelectExpert,
    dataExperts: dataExperts,
    owners: !!owners?.length
      ? owners
      : [{ text: "Эксперт не выбран", id: 1, color: indigo }],
    commitChanges,
    selectIntervalCount,
    setSelectIntervalCount,
    getExpertsScheduleHandler,
  };
};
