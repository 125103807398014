import { ReactNode } from "react";

export type ReviewsCTX_Props = {
  children: ReactNode;
};

export enum ReviewsActionTypes {
  FILTER_EXPERT = "FILTER_EXPERT",
  FILTER_STATUS = "FILTER_STATUS",
  FILTER_GRADE = "FILTER_GRADE",
  EARLIER_THAN = "EARLIER_THAN",
  LATER_THAN = "LATER_THAN",
  IS_PINNED = "IS_PINNED",
  RESET_FILTERS = "RESET_FILTERS",
}
