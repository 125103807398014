import { Box } from "@mui/material";
import {
  StyledTabsContainer,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "./styles";
import { statusFilterBarData } from "./constants";
import { StatusFilterEnum } from "./types";

const StatusSearchBar = ({ handleChangeStatusBar, statusFilter }: any) => {
  return (
    <StyledTabsContainer>
      <Box>
        <StyledToggleButtonGroup
          value={statusFilter[0] || [StatusFilterEnum.ALL]}
          exclusive
          onChange={handleChangeStatusBar}
          aria-label="text alignment"
        >
          {statusFilterBarData.map((item) => (
            <StyledToggleButton key={item.title} value={item.value || 0}>
              {item.title}
            </StyledToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Box>
    </StyledTabsContainer>
  );
};

export default StatusSearchBar;
