import React, { FC } from "react";
import { BonusSearchPanelProps } from "./types";
import { Button, InputAdornment } from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import BonusTypeSelectInput from "./components/bonusTypeSelectInput";
import { StyledOutlinedInput, StyledSearchBlock, StyledSearchPanelWrapper } from "../../../../styles/common/styles";
import IconComponent from "../../../common/icon";

const BonusSearchPanel: FC<BonusSearchPanelProps> = ({
  filterBonuses,
  getFilterBonusesHandler,
  setFilterBonuses,
  toggleIsModalOpen,
}) => {
  const handlerSearchText = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFilterBonuses({
      ...filterBonuses,
      name: event.target.value,
    });
  };
  return (
    <StyledSearchPanelWrapper>
      <StyledSearchBlock>
        <StyledOutlinedInput
          placeholder="Поиск по названию бонуса"
          onChange={handlerSearchText}
          value={filterBonuses.name}
          endAdornment={
            <InputAdornment position="end">
              <IconComponent icon={faMagnifyingGlass} />
            </InputAdornment>
          }
        />
        <BonusTypeSelectInput
          filterBonuses={filterBonuses}
          setFilterBonuses={setFilterBonuses}
        />
        <Button
          variant="contained"
          color="info"
          onClick={getFilterBonusesHandler}
        >
          Поиск
        </Button>
      </StyledSearchBlock>
      <Button variant="contained" color="info" onClick={toggleIsModalOpen}>
        + Добавить
      </Button>
    </StyledSearchPanelWrapper>
  );
};

export default BonusSearchPanel;
