import { ErrorMessage, Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useLoginForm } from "./useLoginForm";
import { StylesErrorText } from "./styles";
import { initialValuesForm } from "./constants";
import { $colorVariables } from "../../../styles/colorVariables";
import IconComponent from "../../../components/common/icon";

const LoginForm = () => {
  const { loginHandler, setShowPassword, showPassword, loginLoading } =
    useLoginForm();

  return (
    <>
      <Formik
        initialValues={initialValuesForm}
        onSubmit={(values, { resetForm }) => {
          loginHandler({ variables: values });
          resetForm({ values: initialValuesForm });
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          touched,
          handleBlur,
          values,
          handleChange,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  error={!!errors.email && !!touched.email && !!values.email}
                  onBlur={handleBlur}
                  name="email"
                  value={values.email}
                  label="Эл. адрес"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="email"
                  render={(msg) => <StylesErrorText>{msg}</StylesErrorText>}
                />
                <>
                  <TextField
                    error={
                      !!errors.password &&
                      !!touched.password &&
                      !!values.password
                    }
                    onBlur={handleBlur}
                    name="password"
                    value={values.password}
                    label="Пароль"
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    autoComplete="on"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          <IconComponent
                            onClick={() => setShowPassword(!showPassword)}
                            icon={showPassword ? faEye : faEyeSlash}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage
                    name="password"
                    render={(msg) => <StylesErrorText>{msg}</StylesErrorText>}
                  />
                </>
                <Button
                  disabled={errors.email || errors.password ? true : false}
                  type="submit"
                  variant="contained"
                >
                  {!loginLoading ? (
                    "Авторизоваться"
                  ) : (
                    <CircularProgress
                      size={"25px"}
                      sx={{ color: $colorVariables.$white }}
                    />
                  )}
                </Button>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default LoginForm;
