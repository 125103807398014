import React, { FC, useContext } from "react";
import { PromoCodesItemProps } from "./types";
import { StylesContainerPromosCodes } from "./styles";
import { Box, Button, Typography } from "@mui/material";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import IconComponent from "../../../common/icon";
import { useNavigate } from "react-router-dom";

const PromoCodesItem: FC<PromoCodesItemProps> = ({
  code,
  setPromocodeForAffise,
}) => {
  const navigate = useNavigate();

  const { setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);

  const copyTextHandler = async () => {
    try {
      await navigator.clipboard.writeText(code!);
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Промо-код скопирован");
    } catch (error) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(`Не удалось скопировать Промо-код: ${error}`);
    }
  };

  return (
    <StylesContainerPromosCodes>
      <Typography component={"span"}>Имя: {code}</Typography>
      <Box sx={{ display: "flex", gap: "5px" }}>
        <Button
          variant="contained"
          onClick={copyTextHandler}
          startIcon={<IconComponent icon={faCopy} />}
        >
          Копировать промо-код
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (setPromocodeForAffise && code) {
              setPromocodeForAffise(code);
            }

            navigate("join-promo-to-effise", { state: { code } });
          }}
        >
          Привязать промокод к Affise
        </Button>
      </Box>
    </StylesContainerPromosCodes>
  );
};

export default PromoCodesItem;
