import { useState } from "react";
import { getAccessToken } from "../../sevices/securityService/securityServiceHelper";
import { UserCTX } from "./UserCTX";
import { UserContextPrvidertProps } from "./types";

const UserContextPrvidertApp = ({ children }: UserContextPrvidertProps) => {
  const [userID, setUserID] = useState<number | null>(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(
    !!getAccessToken()
  );

  return (
    <UserCTX.Provider
      value={{
        setUserID: setUserID,
        userID: userID,
        isUserLoggedIn: isUserLoggedIn,
        setIsUserLoggedIn: setIsUserLoggedIn,
      }}
    >
      {children}
    </UserCTX.Provider>
  );
};

export default UserContextPrvidertApp;
