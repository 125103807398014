import React, { FC } from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { ExpertSelectProps } from "./types";
import { useDataSelect } from "./useDataSelect";
import { StylesExpertContainer } from "./styles";
import { $colorVariables } from "../../../../../styles/colorVariables";

const ExpertSelect: FC<ExpertSelectProps> = ({ experts }) => {
  const { handleChange, expertFilter } = useDataSelect();
  const dataExperts = experts?.edges;

  return (
    <StylesExpertContainer>
      <FormControl fullWidth size="small">
        <InputLabel id="expert-select" sx={{ color: $colorVariables.$blue03 }}>
          Эксперт
        </InputLabel>
        <Select
          labelId="expert-select"
          id="demo-simple-select"
          value={expertFilter?.length ? expertFilter?.[0] : ""}
          label="Эксперт"
          onChange={handleChange}
          sx={{ borderRadius: "12px" }}
        >
          {dataExperts?.map((item) => {
            return (
              <MenuItem key={item?.node.id} value={item?.node.id}>
                {item?.node.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </StylesExpertContainer>
  );
};

export default ExpertSelect;
