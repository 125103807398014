import { FC } from "react";
import { StyledTableCellBody } from "./styles";
import Raiting from "../autherOptions/components/raiting";
import { seconsdConvertToDate } from "../../../utils/useDate";
import ReviewsControlButtons from "../reviewsControllersGroupButtons";
import AuthorUserOptions from "../autherOptions";
import { ReviewsTableBodyProps } from "./types";
import { Skeleton, TableRow } from "@mui/material";
import PinReviewsController from "../pinReviewsController";

const ReviewsTableBody: FC<ReviewsTableBodyProps> = ({ node, loading }) => {
  return (
    <>
      {node?.map((item) => {
        const {
          authorUser,
          id,
          createdAtTimestamp,
          status,
          text,
          rating,
          expert,
          isPinned,
        } = item?.node!;

        return !loading ? (
          <TableRow key={id}>
            <StyledTableCellBody indikator={status}>
              {seconsdConvertToDate(
                createdAtTimestamp / 1000,
                "D MMM YYYY HH:mm"
              )}
            </StyledTableCellBody>
            <StyledTableCellBody>{text}</StyledTableCellBody>
            <StyledTableCellBody>
              <Raiting rating={rating} fontWeight={600} />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <AuthorUserOptions
                clientImg={authorUser.image.onex}
                clientId={authorUser.name}
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <AuthorUserOptions isExpert expert={expert} />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <ReviewsControlButtons reviewID={id} status={status!} />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <PinReviewsController reviewID={id} isPinned={isPinned!} />
            </StyledTableCellBody>
          </TableRow>
        ) : (
          <TableRow key={id}>
            <StyledTableCellBody>
              <Skeleton variant="text" animation="wave" />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <Skeleton variant="text" animation="wave" />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <Skeleton variant="text" animation="wave" />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <Skeleton variant="text" animation="wave" />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <Skeleton variant="text" animation="wave" />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <Skeleton variant="text" animation="wave" />
            </StyledTableCellBody>
          </TableRow>
        );
      })}
    </>
  );
};

export default ReviewsTableBody;
