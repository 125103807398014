import { FC } from "react";
import { StyledButtonContainer, StyledSubmitButton } from "./styles";
import { FormButtonsPanelProps } from "./types";

const FormButtonsPanel: FC<FormButtonsPanelProps> = ({ isUpdatePromo }) => {
  return (
    <StyledButtonContainer>
      <StyledSubmitButton type="submit" variant="contained">
        {isUpdatePromo ? "Обновить" : "добавить"}
      </StyledSubmitButton>
    </StyledButtonContainer>
  );
};

export default FormButtonsPanel;
