import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../contexts/globalContext/types";
import { GET_SCHEDULE } from "./graphql/GET_SCHEDULE.graphql";
import {
  getScheduleVariables,
  getSchedule,
} from "./graphql/__generated__/getSchedule";

export const useExpertTimetable = () => {
  const { name, uuid, image } = useLocation().state;
  const { setNotificationMsg, setNotificationEvent, setLoading } =
    useContext(globalDataCTX);

  const [
    getSchedule,
    { data: expertSchedule, loading: scheduleLoading, error: errorSchedule },
  ] = useLazyQuery<getSchedule, getScheduleVariables>(GET_SCHEDULE);

  useEffect(() => {
    const startDateLastWeek = moment().subtract(1, "weeks").startOf("isoWeek");

    if (uuid) {
      getSchedule({
        variables: {
          filter: {
            ExpertFilter: [uuid],
            DateFilter: {
              laterThan: startDateLastWeek,
            },
          },
        },
      });
    }
  }, [uuid, getSchedule]);

  useEffect(() => {
    setLoading(scheduleLoading);
    if (errorSchedule) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(errorSchedule?.message || "");
    } else {
      setNotificationEvent(notificationEventType.noEvent);
    }
  }, [scheduleLoading, errorSchedule, setLoading, setNotificationEvent, setNotificationMsg]);

  return {
    expertName: name,
    expertAvatar: image.onex,
    expertSchedule: expertSchedule?.getSchedule,
  };
};
