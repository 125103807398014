import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  getBonuses,
  getBonusesVariables,
} from "./graphql/__generated__/getBonuses";
import { GET_BONUSES } from "./graphql/GET_BONUSES.graphql";
import { BonusType } from "../../globalTypes";
import { useKeyboardEvent } from "../../utils/useKeyboard";

export const useBonuses = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [filterBonuses, setFilterBonuses] = useState({
    name: "",
    type: "All" || BonusType.sale || BonusType.deposit,
  });

  const [getBonuses, { data: bonusesData }] = useLazyQuery<
    getBonuses,
    getBonusesVariables
  >(GET_BONUSES);

  useEffect(() => {
    getBonuses({ variables: { input: {} } });
  }, [getBonuses]);

  const getFilterBonusesHandler = () => {
    const isFilterAll =
      filterBonuses.type === "All" && filterBonuses.name === "";

    const filterWithName =
      filterBonuses.type === "All" && filterBonuses.name !== "";

    getBonuses({
      variables: {
        input: isFilterAll
          ? {}
          : filterWithName
          ? { name: filterBonuses.name }
          : filterBonuses,
      },
    });
  };

  useKeyboardEvent("Enter", getFilterBonusesHandler);

  const toggleIsModalOpen = () => {
    setIsOpenModal((prevState) => {
      return !prevState;
    });
  };

  return {
    toggleIsModalOpen,
    isOpenModal,
    bonusesData,
    filterBonuses,
    setFilterBonuses,
    getFilterBonusesHandler,
  };
};
