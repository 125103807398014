export const casheBachTabelNamesHeader = [
  {
    name: "ID",
  },
  {
    name: "Нижняя граница",
  },
  {
    name: "Верхняя граница",
  },
  {
    name: "Название бонуса",
  },
  {
    name: "Бонус формула ",
  },
  {
    name: "Статус",
  },
  {
    name: "Удалить",
  },
];
