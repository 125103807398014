import { Box, Drawer } from "@mui/material";
import NavSection from "./components/nav-section";
import { $colorVariables } from "../../../styles/colorVariables";
import NavigationData from "./constants";

export default function Nav() {
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
      }}
    >
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: {
            bgcolor: $colorVariables.$gray02,
          },
        }}
      >
        <NavSection data={NavigationData} />
      </Drawer>
    </Box>
  );
}
