import React from "react";
import Card from "@mui/material/Card";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { UserCardProps } from "./types";
import { editTypesEnum } from "../../types";
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  Checkbox,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import IconComponent from "../../../common/icon";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const UserCard = ({
  image,
  name,
  freeMinutesLeft,
  loginType,
  removeUserHandler,
  userId,
  checkUserHandler,
  isChecked,
  openModalHandler,
  isSomeEditClient,
  setSingleEditClientData,
  balance,
  blocked,
  balanceCurrency,
  session,
  userDataReferralStatus,
  isDeleted,
}: UserCardProps) => {
  return (
    <Card
      sx={{
        maxWidth: 345,
      }}
    >
      <CardActionArea
        onClick={() => {
          openModalHandler(editTypesEnum.SINGLE_EDIT);
          setSingleEditClientData({ id: userId, image: image || "" });
        }}
        disabled={isSomeEditClient}
      >
        <CardMedia
          component="img"
          height="200"
          image={image}
          alt=" у клиента нет фотографии "
        />
        <CardContent>
          <Typography gutterBottom variant="h5">
            {name}
          </Typography>
          <Typography gutterBottom variant="h5">
            ID: {userId}
          </Typography>
          <Typography gutterBottom variant={"body2"}>
            Баланс - {balance} {balanceCurrency}
          </Typography>
          <Typography gutterBottom variant={"body2"}>
            Бесплатные минуты - {freeMinutesLeft} мин
          </Typography>
          <Typography variant={"body2"}>Тип входа - {loginType}</Typography>
          <Typography variant={"body2"}>
            Сессия - {session ? "Да" : "Нет"}
          </Typography>
          <Typography variant={"body2"}>
            Реферальная программа -{" "}
            {userDataReferralStatus?.getUserReferralStatus || ""}
          </Typography>
          <Box
            // variant={"body2"}
            sx={{
              // color: `${blocked ? "red" : "green"}`,
              mt: 1,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            Удалён -
            {isDeleted ? (
              <Typography color={"red"}>Да</Typography>
            ) : (
              <Typography color={"green"}>Нет</Typography>
            )}
          </Box>
          <Typography
            variant={"body2"}
            sx={{
              color: `${blocked ? "red" : "green"}`,
              mt: 1,
              fontWeight: 600,
            }}
          >
            {blocked ? "Заблокирован" : "Разблокирован"}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Checkbox
            title="Выбрать клиента "
            {...label}
            size="small"
            checked={isChecked}
            onChange={() => checkUserHandler(userId)}
          />
        </Box>
        <Button
          sx={{ alignItems: "flex-end" }}
          onClick={() => removeUserHandler(userId)}
          title="Убрать из списка"
        >
          <IconComponent icon={faCircleXmark} color="red" size="xl" beat />
        </Button>
      </CardActions>
    </Card>
  );
};

export default UserCard;
