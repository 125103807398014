import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import {
  Avatar,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { SelectExpertsProps } from "./types";
import { MenuPropsSelectExperts } from "./constants";
import { getAllExpertsData_getExperts_edges_node } from "../../../graphql/__generated__/getAllExpertsData";

const SelectExperts: React.FC<SelectExpertsProps> = ({
  selectExpert,
  setSelectExpert,
  dataExperts,
}) => {
  const [allExpertsSelected, setAllExpertsSelected] =
    React.useState<boolean>(false);

  const isChecked = (node: { id: number }) => {
    return selectExpert.some((selectedNode) => selectedNode.id === node.id);
  };

  const handleSelectExpert = (
    event: SelectChangeEvent<getAllExpertsData_getExperts_edges_node[]>,
    child: React.ReactNode
  ) => {
    const selectedExperts = event.target.value;
    setSelectExpert(selectedExperts);
    setAllExpertsSelected(
      selectedExperts.length === dataExperts?.getExperts?.edges.length
    );
  };

  const handleSelectAllExperts = () => {
    if (allExpertsSelected) {
      setSelectExpert([]);
      setAllExpertsSelected(false);
    } else {
      const allExperts = dataExperts?.getExperts?.edges.map(
        (item) => item?.node
      );
      setSelectExpert(allExperts);
      setAllExpertsSelected(true);
    }
  };

  return (
    <FormControl
      sx={{
        mb: 2,
        height: "55px",
        minWidth: "50%",
        maxWidth: "50%",
      }}
    >
      <InputLabel id="demo-multiple-checkbox-label">Эксперты</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectExpert}
        onChange={handleSelectExpert}
        input={<OutlinedInput label={"Эксперты"} />}
        renderValue={(selected) =>
          selected
            ?.map((selExpert: { name: string }) => selExpert.name)
            .join(", ")
        }
        MenuProps={MenuPropsSelectExperts}
        sx={{ height: "inherit" }}
      >
        <MenuItem>
          <Checkbox
            checked={allExpertsSelected}
            onChange={handleSelectAllExperts}
            indeterminate={
              selectExpert.length > 0 &&
              selectExpert.length < dataExperts?.getExperts?.edges.length!
            }
          />
          <ListItemText primary="Выбрать всех" />
        </MenuItem>
        {dataExperts?.getExperts?.edges.map(({ node }: any) => {
          return (
            <MenuItem key={node?.id} value={node}>
              <Checkbox checked={isChecked(node)} />
              <ListItemText primary={node?.name} />
              <Avatar src={node?.image?.onex} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectExperts;
