import React from "react";
import FormButtonsPanel from "./components/formButtonsPanel";
import PromoTypeSelectInput from "./components/promoTypeSelectInput";
import { ErrorMessage, Form, Formik } from "formik";
import { useSubPageCreateUpdatePromo } from "./usePromoModalsContext";
import {
  validationCreatePromoSchema,
  validationUpdatePromoSchema,
} from "./validationSchema";
import { Box, Divider, TextField } from "@mui/material";
import { StyledBlockInput } from "./styles";
import { RedeemAbility } from "../../../../globalTypes";
import SelectBonusType from "./components/selectBonusType";
import DateTimeEditor from "./components/dateTimePicker";
import { HeaderTitle, StyledErrorText } from "../../../../styles/common/styles";
import BackButton from "../../../common/BackButton";

const SubPageCreateUpdatePromo = () => {
  const { submitHandler, isUpdatePromo, initialValues, dataBonuses } =
    useSubPageCreateUpdatePromo();

  return (
    <>
      <BackButton />
      <Divider textAlign="center" sx={{ mb: 5 }}>
        <HeaderTitle>
          {isUpdatePromo ? "Обновить" : "Создать"} промо кампанию
        </HeaderTitle>
      </Divider>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitHandler(values)}
        validationSchema={
          isUpdatePromo
            ? validationUpdatePromoSchema
            : validationCreatePromoSchema
        }
        enableReinitialize={true}
      >
        {({
          handleBlur,
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box display={"flex"} gap={3} flexDirection={"column"}>
              {!isUpdatePromo && (
                <StyledBlockInput>
                  <TextField
                    onBlur={handleBlur}
                    name="name"
                    value={values?.name}
                    label="Название Promo"
                    onChange={handleChange}
                    fullWidth
                  />
                  <ErrorMessage
                    name="name"
                    render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                  />
                </StyledBlockInput>
              )}

              {!isUpdatePromo && (
                <StyledBlockInput>
                  <PromoTypeSelectInput
                    initialValue={
                      values.redeemAbility || RedeemAbility.one_time
                    }
                    setFieldValue={setFieldValue}
                    valuesKey="redeemAbility"
                  />
                  <ErrorMessage
                    name="redeemAbility"
                    render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                  />
                </StyledBlockInput>
              )}

              <StyledBlockInput>
                <TextField
                  onBlur={handleBlur}
                  name="generateCount"
                  value={values?.generateCount}
                  label={
                    values.redeemAbility === RedeemAbility.one_time
                      ? "количество активаций"
                      : "Количество (уникальных) кодов"
                  }
                  onChange={handleChange}
                  type="number"
                  fullWidth
                />
                <ErrorMessage
                  name="generateCount"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>

              {!isUpdatePromo && (
                <StyledBlockInput>
                  <TextField
                    onBlur={handleBlur}
                    name="generatePattern"
                    value={values?.generatePattern}
                    label="Генерировать шаблон(Pattern)"
                    onChange={handleChange}
                    fullWidth
                    disabled={values.redeemAbility === RedeemAbility.one_time}
                  />
                  <ErrorMessage
                    name="generatePattern"
                    render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                  />
                </StyledBlockInput>
              )}

              {!isUpdatePromo && (
                <StyledBlockInput>
                  <TextField
                    onBlur={handleBlur}
                    name="codeLength"
                    value={values?.codeLength}
                    label="Количество знаков (codeLenght)"
                    onChange={handleChange}
                    type="number"
                    fullWidth
                    disabled={values.redeemAbility === RedeemAbility.one_time}
                  />
                  <ErrorMessage
                    name="codeLength"
                    render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                  />
                </StyledBlockInput>
              )}
              {!isUpdatePromo && (
                <StyledBlockInput>
                  <SelectBonusType
                    initialValue={values.bonusID || ""}
                    setFieldValue={setFieldValue}
                    valuesKey="bonusID"
                    dataBonuses={dataBonuses}
                  />
                  <ErrorMessage
                    name="bonusID"
                    render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                  />
                </StyledBlockInput>
              )}
              <StyledBlockInput>
                <DateTimeEditor
                  onValueChange={setFieldValue}
                  values={values.startDate}
                  valuesKey="startDate"
                />
                <ErrorMessage
                  name="startDate"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
              <StyledBlockInput>
                <DateTimeEditor
                  onValueChange={setFieldValue}
                  values={values.endDate}
                  valuesKey="endDate"
                />
                <ErrorMessage
                  name="endDate"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
              <FormButtonsPanel isUpdatePromo={isUpdatePromo} />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SubPageCreateUpdatePromo;
