import React, { FC } from "react";
import { Box, InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { DateSelectProps } from "./types";
import { useDataSelect } from "./useDataSelect";
import { $colorVariables } from "../../../../../styles/colorVariables";

const DateSelect: FC<DateSelectProps> = ({
  width,
  title = "",
  selectItems,
}) => {
  const { selectValue, handleChange } = useDataSelect();
  const dataExperts = selectItems?.getExperts?.edges;

  return (
    <Box
      sx={{
        minWidth: 120,
        marginLeft: "6px",
        width: { width },
      }}
    >
      <FormControl fullWidth size="small">
        <InputLabel
          sx={{ color: $colorVariables.$blue03 }}
          id="demo-simple-select-label"
        >
          {title}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectValue}
          label={title}
          onChange={handleChange}
          sx={{ borderRadius: "12px" }}
        >
          {dataExperts?.map((item) => {
            return (
              <MenuItem key={item?.node.userID} value={item?.node.userID}>
                {item?.node.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DateSelect;
