import { useCallback, useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { ChangeSet } from "@devexpress/dx-react-scheduler";
import { GET_SCHEDULE } from "../graphql/GET_SCHEDULE.graphql";
import { UPDATE_SCHEDULE_INTERVAL } from "../graphql/UPDATE_SCHEDULE_INTERVAL.graphql";
import globalDataCTX from "../../../contexts/globalContext/globalDataCTX";
import { ScheduleStatus, ScheduleType } from "../../../globalTypes";
import {
  createScheduleInterval,
  createScheduleIntervalVariables,
} from "../graphql/__generated__/createScheduleInterval";
import { CREATE_SCHEDULE_INTERVAL } from "../graphql/CREATE_SCHEDULE_INTERVAL.graphql";
import { DELETE_SCHEDULE_INTERVAL } from "../graphql/DELETE_SCHEDULE_INTERVAL.graphql";
import {
  deleteScheduleIntervals,
  deleteScheduleIntervalsVariables,
} from "../graphql/__generated__/deleteScheduleIntervals";
import {
  updateScheduleInterval,
  updateScheduleIntervalVariables,
} from "../graphql/__generated__/updateScheduleInterval";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { notificationEventType } from "../../../contexts/globalContext/types";
import { getSchedule_getSchedule } from "../graphql/__generated__/getSchedule";
import { SchedulesDataTypes } from "./types";
import { addDateSeconds, checkLastHour } from "../../../utils/useDate";

export const useSchedule = (
  scheduleData: (getSchedule_getSchedule | null | undefined)[]
) => {
  const [createInterval, { loading: createLoading, error: createError }] =
    useMutation<createScheduleInterval, createScheduleIntervalVariables>(
      CREATE_SCHEDULE_INTERVAL
    );

  const [updateInterval, { loading: updateLoading, error: updateError }] =
    useMutation<updateScheduleInterval, updateScheduleIntervalVariables>(
      UPDATE_SCHEDULE_INTERVAL
    );

  const [deleteSchedule, { loading: deleteLoading, error: deleteError }] =
    useMutation<deleteScheduleIntervals, deleteScheduleIntervalsVariables>(
      DELETE_SCHEDULE_INTERVAL
    );

  const [expertScheduleData, setExpertScheduleData] = useState<
    SchedulesDataTypes[]
  >([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const { uuid: expertUuid } = useLocation().state;
  const { setNotificationMsg, setLoading, setNotificationEvent, loading } =
    useContext(globalDataCTX);
  const startDateLastWeek = moment().subtract(1, "weeks").startOf("isoWeek");

  useEffect(() => {
    setLoading(createLoading || updateLoading || deleteLoading);
    if (createError || updateError || deleteError) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(
        createError?.message ||
          updateError?.message ||
          deleteError?.message ||
          ""
      );
    } else {
      setNotificationEvent(notificationEventType.noEvent);
    }
  }, [
    createError,
    createLoading,
    deleteError,
    deleteLoading,
    setLoading,
    setNotificationEvent,
    setNotificationMsg,
    updateError,
    updateLoading,
  ]);

  useEffect(() => {
    if (scheduleData) {
      const convertedData = [...scheduleData] as SchedulesDataTypes[];
      setExpertScheduleData(convertedData);
    }
  }, [scheduleData]);

  const onCommitChanges = useCallback(
    ({ added, changed, deleted }: ChangeSet) => {
      if (added) {
        const { startDate, endDate } = added;
        const input = {
          start: startDate,
          end: checkLastHour(endDate, false)
            ? addDateSeconds(endDate, 59)
            : endDate,
          expertID: expertUuid,
          status: ScheduleStatus.ScheduleStatusScheduled,
          type: ScheduleType.ScheduleTypePublic,
        };
        createInterval({
          variables: { input },
          refetchQueries: [
            {
              query: GET_SCHEDULE,
              variables: {
                filter: {
                  ExpertFilter: [expertUuid],
                  DateFilter: {
                    laterThan: startDateLastWeek,
                  },
                },
              },
            },
          ],
        });
      }
      if (changed) {
        // Find the appointment to be updated
        const changedID = Object.keys(changed)[0];
        const oldAppointment = expertScheduleData.find(
          (appointment: { id: string | number }) => appointment.id === changedID
        );

        const updateEndDate =
          changed[changedID]?.endDate || oldAppointment?.endDate;

        // Update the appointment directly
        const updatedAppointment = {
          ...oldAppointment,
          ...changed[changedID],
          startDate: changed[changedID]?.startDate || oldAppointment?.startDate,
          endDate: checkLastHour(updateEndDate, false)
            ? addDateSeconds(updateEndDate, 59)
            : updateEndDate,
        };

        // Update the appointment on the server
        updateInterval({
          variables: {
            input: {
              id: updatedAppointment.id,
              start: updatedAppointment.startDate,
              end: updatedAppointment.endDate,
              status: ScheduleStatus.ScheduleStatusScheduled,
              type: ScheduleType.ScheduleTypePublic,
            },
          },
          refetchQueries: [
            {
              query: GET_SCHEDULE,
              variables: {
                filter: {
                  ExpertFilter: [expertUuid],
                  DateFilter: {
                    laterThan: startDateLastWeek,
                  },
                },
              },
            },
          ],
        });
      }

      if (deleted !== undefined) {
        const { id } =
          expertScheduleData.find((item) => item.id === deleted) || {};

        deleteSchedule({
          variables: {
            input: [id],
          },
          refetchQueries: [
            {
              query: GET_SCHEDULE,
              variables: {
                filter: {
                  ExpertFilter: [expertUuid],
                  DateFilter: {
                    laterThan: startDateLastWeek,
                  },
                },
              },
            },
          ],
        });
      }
    },
    [
      expertScheduleData,
      expertUuid,
      startDateLastWeek,
      deleteSchedule,
      createInterval,
      updateInterval,
    ]
  );

  return {
    expertScheduleData,
    onCommitChanges,
    currentDate,
    loadingData: loading,
    setCurrentDate,
  };
};
