import * as React from "react";
import { Stack, Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import globalDataCTX from "../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../contexts/globalContext/types";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbars = () => {
  const { notificationMsg, notificationEvent, setNotificationEvent } =
    React.useContext(globalDataCTX);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setNotificationEvent(notificationEventType.noEvent);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={
          notificationEvent === notificationEventType.noEvent ? false : true
        }
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={notificationEvent === notificationEventType.error ? "error" : "success"}
          sx={{ width: "100%", right: 0 }}
        >
          {notificationMsg}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default CustomizedSnackbars;
