import React from "react";
import { faCamera, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { IconButton, Typography, Button, Box, Avatar } from "@mui/material";
import { $colorVariables } from "../../../../../../../../styles/colorVariables";
import IconComponent from "../../../../../../../common/icon";
import { StyledContainerUploadElements } from "../styles";
import { useUploadAvatar } from "./useUploadAvatar";
import { UploadAvatarProps } from "./types";
import { StyledDivider } from "../../../../../../../../styles/common/styles";

const UploadAvatar = ({ userId, uuid, currentAvatar, expertId }: UploadAvatarProps) => {
  const {
    handleAvatarFileChange,
    avatarFile,
    uploadAvatarHandler,
    loadingAvatar,
    resetStateAvatarFileHandler,
  } = useUploadAvatar(userId, uuid, expertId);

  return (
    <StyledContainerUploadElements>
      <Box width={"100%"}>
        <StyledDivider textAlign="right" sx={{ mt: "0px" }}>Загрузка аватара</StyledDivider>
        <Box>
          <IconButton color="primary" component="label">
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={handleAvatarFileChange}
            />
            <Typography sx={{ mr: 2 }}>Загрузить файл</Typography>
            <IconComponent icon={faCamera} />
          </IconButton>

          {avatarFile && (
            <Typography
              sx={{
                color: $colorVariables.$blue02,
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              File name: {avatarFile?.name}
            </Typography>
          )}

          <Box display={"flex"} gap={2}>
            <Button
              onClick={resetStateAvatarFileHandler}
              disabled={!avatarFile}
              variant="contained"
            >
              <Typography>{loadingAvatar ? "loading..." : "Reset"}</Typography>
            </Button>

            <Button
              onClick={uploadAvatarHandler}
              disabled={!avatarFile}
              variant="contained"
            >
              <Typography sx={{ mr: 2 }}>
                {loadingAvatar ? "loading..." : "Сохранить"}
              </Typography>
              <IconComponent
                icon={faPaperPlane}
                size={"2x"}
                title="send"
                cursor={"pointer"}
              />
            </Button>
            {currentAvatar && <Avatar src={currentAvatar.onex} />}
          </Box>
        </Box>
      </Box>
    </StyledContainerUploadElements>
  );
};

export default UploadAvatar;
