import React, { FC } from "react";
import { InputLabel, MenuItem, FormControl } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BonusTypesSelectProps } from "./types";
import { BonusFormula } from "../../../../../../globalTypes";

const BonusFormulaSelectInput: FC<BonusTypesSelectProps> = ({
  setFieldValue,
  valuesKey,
  initialValue,
}) => {
  const handleChange = (event: SelectChangeEvent<BonusFormula>) => {
    setFieldValue(valuesKey, event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">
        {"Бонусная формула"}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={initialValue}
        label={"Бонусная формула"}
        onChange={handleChange}
      >
        <MenuItem value={BonusFormula.fixed}>{BonusFormula.fixed}</MenuItem>
        <MenuItem value={BonusFormula.percent}>{BonusFormula.percent}</MenuItem>
      </Select>
    </FormControl>
  );
};
export default BonusFormulaSelectInput;
