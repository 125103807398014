export const $colorVariables = {
  $black: "#000000",
  $white: "#ffffff",

  $blue01: "#1f3075",
  $blue02: "#384fa3",
  $blue03: "#626e9e",
  $blue04: "#acb1c7",
  $blue05: "#d5dcec",
  $blue06: "#e8eff9",
  $blue07: "#f0f4fb",

  $green01: "#0eba63",
  $green02: "#93dbc2",
  $green03: "#cdf1e0",
  $green04: "#ebf9f2",
  $greenTransparent: "rgba(14, 186, 99, 0.16)",

  $red: "#ed5252",
  $redTransparent: "rgba(237, 82, 82, 0.16)",

  $gray01: "#454545",
  $gray02: "#303030",

  $pink01: "#bb5c7c",
  $pink02: "#dd98bc",
  $pink03: "#f9dbea",

  $yellow01: "#e4bf38",
  $yellow02: "#edd444",
  $yellow03: "#f3e79d",
  $yellow04: "#fbf6da",

  $borderBlue: "#d2d7e3",
};
