import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StyledInstructionsContainer = styled(Box)({
  borderRadius: "6px",
  backgroundColor: "rgba(0, 0, 0, 0.12)",
  padding: "12px 24px",
  marginBottom: "23px",
  width: "max-content",
});
