import { FC } from "react";
import { $colorVariables } from "../../../../../styles/colorVariables";
import { StyledNavLink } from "./styles";
import { NavLinkProps } from "./types";

const NavLink: FC<NavLinkProps> = ({ to, children }) => {
  return (
    <StyledNavLink
      to={to}
      style={({ isActive }) => {
        return {
          backgroundColor: isActive ? $colorVariables.$gray01 : "",
        };
      }}
    >
      {children}
    </StyledNavLink>
  );
};

export default NavLink;
