import { gql } from "@apollo/client";

export const GET_ALL_REVIEWS = gql`
  query getAllReviews($after: String, $first: Int, $filter: ReviewFilter) {
    getAllReviews(after: $after, first: $first, filters: $filter) {
      edges {
        node {
          id
          text
          status
          isPinned
          rating
          createdAtTimestamp
          expert {
            image(size: 100) {
              onex
              twox
              threex
            }
            rating
            name
            userID
          }
          authorUser {
            id
            name
            image(size: 100) {
              onex
              twox
              threex
            }
            voxImplantInfo {
              userID
              userName
            }
            freeMinutesLeft
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
