import { WeekView } from "@devexpress/dx-react-scheduler-material-ui";
import { alpha, styled } from "@mui/material";
import { $colorVariables } from "../../../../styles/colorVariables";
import { classes } from "../../scheduler/constants";

export const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)(
  ({ theme }) => ({
    [`&.${classes.today}`]: {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
    [`&.${classes.weekend}`]: {
      backgroundColor: alpha($colorVariables.$red, 0.7),

      [`& .Cell-dayOfWeek, .Cell-dayOfMonth`]: {
        color: $colorVariables.$white,
      },
    },
    [`& .Cell-dayOfWeek`]: {
      fontSize: "16px",
    },
    [`& .Cell-dayOfMonth`]: {
      fontSize: "30px",
      marginLeft: "10px",
    },
    [`& .Cell-dayView`]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
