import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import { ReportSpendsCountDataType } from "./types";
import { GET_REPORT } from "./graphql/GET_REPORT.graphql";
import { notificationEventType } from "../../contexts/globalContext/types";
import { SEND_REPORT } from "./graphql/SEND_REPORT.graphql";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { COMPOSE_REPORT } from "./graphql/COMPOSE_REPORT.graphql";
import {
  getReport,
  getReportVariables,
} from "./graphql/__generated__/getReport";
import {
  composeReport,
  composeReportVariables,
} from "./graphql/__generated__/composeReport";
import {
  sendReport,
  sendReportVariables,
} from "./graphql/__generated__/sendReport";
import { getErrorByCode, getErrorCode } from "../../utils/errorUtils";

export const useReport = () => {
  const [reportSearchDate, setReportSearchDate] = useState("");
  const [isFirstReportData, setIsFirstReportData] = useState(true);

  const [tabIndex, setTabIndex] = useState(0);
  const [reportSpendsCountData, setReportSpendsCountData] =
    useState<ReportSpendsCountDataType>({
      Date: "",
      NewUsersCount: null,
      PurchaseCount: null,
      Revenue: null,
      marketingSpendsCount: null,
      expertSpendsCount: null,
    });

  const { setNotificationEvent, setNotificationMsg, setLoading } =
    useContext(globalDataCTX);

  const [
    getReport,
    { data: reportData, loading: reportLoading, error: reportError },
  ] = useLazyQuery<getReport, getReportVariables>(GET_REPORT);

  const [
    getComposeReportData,
    { data: composeData, loading: composeLoading, error: composeError },
  ] = useLazyQuery<composeReport, composeReportVariables>(COMPOSE_REPORT);

  const [
    sendReport,
    { data: sendReportData, loading: sendLoading, error: sendError },
  ] = useMutation<sendReport, sendReportVariables>(SEND_REPORT);

  useEffect(() => {
    setLoading(sendLoading || composeLoading || reportLoading);

    if (sendError || reportError || composeError) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(
        getErrorByCode(
          getErrorCode(sendError || reportError || composeError)
        ) || ""
      );
    } else if (isFirstReportData && sendReportData) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Отчет отправлен");
      setTimeout(() => {
        setIsFirstReportData(false);
      }, 2000);
    } else {
      setNotificationEvent(notificationEventType.noEvent);
      setNotificationMsg("");
    }
  }, [
    sendLoading,
    composeLoading,
    reportLoading,
    sendError,
    reportError,
    composeError,
    sendReportData,
    reportData,
    composeData,
    isFirstReportData,
    setLoading,
    setNotificationEvent,
    setNotificationMsg,
  ]);

  const isReportReadyToSend = useMemo(() => {
    for (const value of Object.values(reportSpendsCountData)) {
      if (value === null || value === "") {
        return false;
      }
    }
    return true;
  }, [reportSpendsCountData]);

  const getReportsHandler = () => {
    if (!reportSearchDate) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg("Нужно задать дату отчета");
    }

    if (tabIndex === 0 && reportSearchDate) {
      //Создать отчет
      getComposeReportData({
        variables: { input: { date: reportSearchDate } },
        fetchPolicy: "no-cache",
      });
    } else if (tabIndex === 1 && reportSearchDate) {
      //Обновить отчет
      getReport({
        variables: { input: { date: reportSearchDate } },
        fetchPolicy: "no-cache",
      });
    }
  };

  useEffect(() => {
    if (composeData) {
      setReportSpendsCountData({
        Date: composeData.composeReport.Date,
        NewUsersCount: composeData.composeReport.NewUsersCount,
        PurchaseCount: composeData.composeReport.PurchaseCount,
        Revenue: composeData.composeReport.Revenue,
        expertSpendsCount: composeData.composeReport.ExpertSpends,
        marketingSpendsCount: composeData.composeReport.MarketingSpends,
      });
    }
    if (reportData) {
      setReportSpendsCountData({
        Date: reportData.getReport.Date,
        NewUsersCount: reportData.getReport.NewUsersCount,
        PurchaseCount: reportData.getReport.PurchaseCount,
        Revenue: reportData.getReport.Revenue,
        expertSpendsCount: reportData.getReport.ExpertSpends,
        marketingSpendsCount: reportData.getReport.MarketingSpends,
      });
    }
  }, [composeData, reportData]);

  useEffect(() => {
    setReportSpendsCountData({
      Date: "",
      NewUsersCount: null,
      PurchaseCount: null,
      Revenue: null,
      marketingSpendsCount: null,
      expertSpendsCount: null,
    });
    setReportSearchDate("");
  }, [tabIndex]);

  const sendReportHandler = () => {
    if (isReportReadyToSend) {
      sendReport({
        variables: {
          input: {
            ExpertSpends: Number(reportSpendsCountData.expertSpendsCount),
            MarketingSpends: Number(reportSpendsCountData.marketingSpendsCount),
            Date: reportSpendsCountData?.Date,
            NewUsersCount: reportSpendsCountData?.NewUsersCount!,
            PurchaseCount: reportSpendsCountData?.PurchaseCount!,
            Revenue: reportSpendsCountData?.Revenue!,
          },
        },
      });
      setReportSpendsCountData({
        Date: "",
        NewUsersCount: null,
        PurchaseCount: null,
        Revenue: null,
        marketingSpendsCount: null,
        expertSpendsCount: null,
      });
      setReportSearchDate("");
      setIsFirstReportData(true);
    } 
    else {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(
        "Необходимо установить дату отчета, Затраты на экспертов и Затраты на маркетинг"
      );
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return {
    tabIndex,
    reportData,
    composeData,
    reportLoading,
    reportSearchDate,
    reportSpendsCountData,
    setReportSpendsCountData,
    setReportSearchDate,
    sendReportHandler,
    getReportsHandler,
    handleChange,
    setTabIndex,
    a11yProps,
  };
};
