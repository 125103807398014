import { WeekView } from "@devexpress/dx-react-scheduler";
import { classes } from "../../scheduler/constants";
import { StyledWeekViewDayScaleCell } from "./styles";

const DayScaleCell = (props: WeekView.DayScaleCellProps) => {
  const { today, startDate } = props;

  if (today) {
    return <StyledWeekViewDayScaleCell {...props} className={classes.today} />;
  }
  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
    return (
      <StyledWeekViewDayScaleCell {...props} className={classes.weekend} />
    );
  }
  return <StyledWeekViewDayScaleCell {...props} />;
};

export default DayScaleCell;
