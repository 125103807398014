import { FC } from "react";
import { Skeleton, TableHead, TableRow } from "@mui/material";
import dataReviewsHeader from "./constants";
import { ReviewsTableHeadProps } from "./types";
import { StyledTableCell } from "../../../styles/common/styles";

const ReviewsTableHead: FC<ReviewsTableHeadProps> = ({
  ReviewsCount,
  loading,
}) => {
  return (
    <TableHead>
      <TableRow>
        {dataReviewsHeader.map(({ name }) => {
          return !loading ? (
            <StyledTableCell key={name} align="left">
              <>
                {name}
                {name === "Отзывы " && ReviewsCount}
              </>
            </StyledTableCell>
          ) : (
            <StyledTableCell key={name} align="left">
              <Skeleton animation="wave" />
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default ReviewsTableHead;
