import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { GET_ALL_EXPERTS_ADMIN_DASHBOARD } from "./graphql/GET_ALL_EXPERTS_ADMIN_DASHBOARD.graphql";
import { getExpertsAdminDashboard, getExpertsAdminDashboardVariables } from "./graphql/__generated__/getExpertsAdminDashboard";

export const useExportsTable = () => {
  const { setLoading, loading } = useContext(globalDataCTX);
  const [searchText, setSearchText] = useState<string>("");

  const [getAllExperts, { data, loading: loadingExperts }] = useLazyQuery<
  getExpertsAdminDashboard,
  getExpertsAdminDashboardVariables
  >(GET_ALL_EXPERTS_ADMIN_DASHBOARD);
  const allExpertData = data?.getExpertsAdminDashboard?.edges

  const searchExpertData = useMemo(() => {
    const searchExpertData = data?.getExpertsAdminDashboard?.edges.filter((item) => {
      return item?.node.name.toLowerCase().includes(searchText);
    });

    return searchExpertData;
  }, [data?.getExpertsAdminDashboard?.edges, searchText]);

  useEffect(() => {
    getAllExperts();
    setLoading(loadingExperts);
  }, [getAllExperts, loadingExperts, setLoading]);

  return {
    allExpertData: searchText ? searchExpertData : allExpertData,
    searchText,
    loading,
    setSearchText
  };
};
