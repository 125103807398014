import React from "react";
import SessionSearchPanel from "./components/sessionSearchPanel";
import { HeaderTitle } from "../../styles/common/styles";
import { useSession } from "./useSession";
import SessionCard from "./components/sessionCard";
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const Session = () => {
  const {
    sessionData,
    getSesionDataHandler,
    sessionId,
    setSessionId,
    reason,
    setReason,
    isReadyToChangeStatus,
    endSessionHandler,
    removeSessionCard,
  } = useSession();

  return (
    <>
      <HeaderTitle>Сессии</HeaderTitle>
      <SessionSearchPanel
        sessionId={sessionId}
        setSessionId={setSessionId}
        getSesionDataHandler={getSesionDataHandler}
      />
      {sessionData?.getSessionByAdmin ? (
        <SessionCard
          reason={reason}
          setReason={setReason}
          isReadyToChangeStatus={isReadyToChangeStatus}
          sessionData={sessionData}
          endSessionHandler={endSessionHandler}
          removeSessionCard={removeSessionCard}
        />
      ) : (
        <Typography color={grey[400]}>Сессия не выбрана</Typography>
      )}
    </>
  );
};

export default Session;
