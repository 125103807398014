import { gql } from "@apollo/client";

export const GET_BLOCKED_USERS = gql`
  query getBlockedUsers($input: BlockUserFilter!) {
    getBlockedUsers(filter: $input) {
      isBlocked
      userID
      reason
      blockUntil
    }
  }
`;
