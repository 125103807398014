import { useMutation, useQuery } from "@apollo/client";
import { GET_PAYMENT_FORM_TEMPLATES } from "./graphql/GET_PAYMENT_FORM_TEMPLATES";
import {
  getPaymentFormTemplates,
  getPaymentFormTemplatesVariables,
} from "./graphql/__generated__/getPaymentFormTemplates";
import {
  deletePaymentFormButtonTemplate,
  deletePaymentFormButtonTemplateVariables,
} from "./graphql/__generated__/deletePaymentFormButtonTemplate";
import { DELETE_PAYMENT_FORM_BUTTON_TEMPLATES } from "./graphql/DELETE_PAYMENT_FORM_BUTTON_TEMPLATES";
import { useContext, useEffect } from "react";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../contexts/globalContext/types";

export const useButtonTemplates = () => {
  const { setLoading, setNotificationEvent, setNotificationMsg } = useContext(globalDataCTX);
  const { data, loading, refetch: refetchTemplates } = useQuery<
    getPaymentFormTemplates,
    getPaymentFormTemplatesVariables
  >(GET_PAYMENT_FORM_TEMPLATES, {
    variables: { filter: {} },
    fetchPolicy: "network-only",
  });

  const [deletePaymentFormButtonTemplateMutation, {data: deleteData }] = useMutation<
    deletePaymentFormButtonTemplate,
    deletePaymentFormButtonTemplateVariables
  >(DELETE_PAYMENT_FORM_BUTTON_TEMPLATES);

  const deleteButtonTemplate = (id: number) => {
    deletePaymentFormButtonTemplateMutation({
      variables: { input: { buttonId: id } },
    });
  };

  useEffect(() => {
    setLoading(loading);
    if (deleteData) {
      setNotificationEvent(notificationEventType.success)
      setNotificationMsg("Успешно удалено");
      refetchTemplates()
    }
  }, [deleteData, loading, refetchTemplates, setLoading, setNotificationEvent, setNotificationMsg]);

  return {
    templatesData: data?.getPaymentFormTemplates,
    deleteButtonTemplate,
  };
};
