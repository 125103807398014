import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { HeaderNavDate } from "./types";
import { ReviewStatus } from "../../../../globalTypes";
import IconComponent from "../../../common/icon";

export const headerNavDate: HeaderNavDate[] = [
  {
    title: "Неразобранные",
    value: ReviewStatus.ON_MODERATION,
  },
  {
    title: "Опубликованные",
    icon: <IconComponent icon={faCheck} />,
    value: ReviewStatus.PUBLISHED,
  },
  {
    title: "Удалённые",
    icon: <IconComponent icon={faXmark} />,
    value: ReviewStatus.DELETED,
  },
  {
    title: "Все",
    value: 0,
  },
];

export default headerNavDate;
