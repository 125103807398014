import { FC } from "react";
import { Skeleton, TableHead, TableRow } from "@mui/material";
import { StyledTableCell } from "./styles";
import tabelHeaderNames from "./constants";
import { ReviewsTableHeadProps } from "./types";

const ReviewsTableHead: FC<ReviewsTableHeadProps> = ({ loadingExperts }) => {
  return (
    <TableHead>
      <TableRow>
        {tabelHeaderNames.map(({ name }) => {
          return !loadingExperts ? (
            <StyledTableCell key={name} align="left">
              {name}
            </StyledTableCell>
          ) : (
            <StyledTableCell key={name} align="left">
              <Skeleton   animation="wave"/>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default ReviewsTableHead;
