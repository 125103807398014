import { gql } from "@apollo/client";

export const UPLOAD_AUDIO = gql`
  mutation uploadExpertGreetingsAudioFile(
    $expertID: Int!
    $file: AudioFileInput!
  ) {
    uploadExpertGreetingsAudioFile(expertID: $expertID, file: $file)
  }
`;
