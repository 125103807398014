import { gql } from "@apollo/client";

export const UPLOAD_AVATAR_USER = gql`
  mutation setAvatar($user: idInput!, $avatar: AvatarInput!) {
    setAvatar(user: $user, avatar: $avatar) {
      xsmall
      small
      medium
      large
    }
  }
`;
