import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  Divider,
} from "@mui/material";
import { HeaderTitle } from "../../../schedule/allSchedules/styles";
import { useSubPagePrompJoinAffise } from "./useSubPagePrompJoinAffise";
import BackButton from "../../../common/BackButton";

const SubPagePrompJoinAffise = () => {
  const {
    dataEffiseOffers,
    countOfOffer,
    offerID,
    handleChange,
    countOfPartners,
    affiliateId,
    getPartnersForOffer,
    joinAffiseToPromoCode,
    loading,
  } = useSubPagePrompJoinAffise();

  return (
    <>
      <BackButton />
      <Box sx={{ width: "100%" }} mt={5}>
        <Divider textAlign="center">
          <HeaderTitle>Привязать промокод к Affise</HeaderTitle>
        </Divider>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            height: "max-content",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {`ID оффера (количество офферов ${countOfOffer})`}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={offerID}
              label={`ID оффера (количество офферов ${countOfOffer})`}
              onChange={(e) => handleChange(e, "offerID")}
            >
              {dataEffiseOffers?.map((dataAffise) => {
                return (
                  <MenuItem key={dataAffise.Id} value={dataAffise.Id}>
                    {dataAffise.Id}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {`Партнёр 
          (количество партнеров ${countOfPartners}
          )`}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={affiliateId}
              label={`Партнёр 
              (количество партнеров ${countOfPartners}
              )`}
              onChange={(e) => handleChange(e, "affiliateId")}
            >
              {!!getPartnersForOffer &&
                getPartnersForOffer[0].AllowedAffiliates?.map((dataAffise) => {
                  return dataAffise ? (
                    <MenuItem key={dataAffise.Id} value={dataAffise.Id}>
                      {dataAffise.Email}
                    </MenuItem>
                  ) : (
                    ""
                  );
                })}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            fullWidth
            onClick={joinAffiseToPromoCode}
            disabled={loading || !offerID || !affiliateId}
          >
            Добавить
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SubPagePrompJoinAffise;
