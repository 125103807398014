import { useMutation } from "@apollo/client";
import { CREATE_BONUS } from "../../graphql/CREATE_BONUS.graphql";
import {
  createBonus,
  createBonusVariables,
} from "../../graphql/__generated__/createBonus";
import { BonusFormula, BonusType, NewBonus } from "../../../../globalTypes";
import { GET_BONUSES } from "../../graphql/GET_BONUSES.graphql";
import { useContext, useEffect } from "react";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";

export const useBonusModalsContext = (toggleIsModalOpen: () => void) => {
  const { setLoading, setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);
  const [
    createBonusHandler,
    {
      data: createBonusData,
      loading: createBonusLoading,
      error: createBonusError,
    },
  ] = useMutation<createBonus, createBonusVariables>(CREATE_BONUS);

  useEffect(() => {
    setLoading(createBonusLoading);

    if (createBonusError) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(createBonusError.message || "");
    }

    if (createBonusData) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Бонус успешно создан");
    }
  }, [
    createBonusData,
    createBonusLoading,
    createBonusError,
    setLoading,
    setNotificationEvent,
    setNotificationMsg,
  ]);

  const bonusInitialValues: NewBonus = {
    name: "",
    type: BonusType.deposit || BonusType.sale,
    affectedObjects: [],
    formula: BonusFormula.fixed || BonusFormula.percent,
    amount: 0,
    lifetimePeriod: 0,
  };

  const submitHandler = (values: NewBonus) => {
    createBonusHandler({
      variables: {
        input: values,
      },
      refetchQueries: [
        {
          query: GET_BONUSES,
          variables: { input: {} },
        },
      ],
    });
    toggleIsModalOpen();
  };

  return {
    bonusInitialValues,
    submitHandler,
  };
};
