import { createContext } from "react";
import { GlobalDataContextType, notificationEventType } from "./types";

const globalDataCTX = createContext<GlobalDataContextType>({
  notificationEvent: notificationEventType.noEvent,
  setNotificationEvent: () => {},
  loading: false,
  setLoading: () => {},
  notificationMsg: "",
  setNotificationMsg: () => {},
});

export default globalDataCTX;
