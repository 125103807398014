import { SelectChangeEvent } from "@mui/material";
import { useContext } from "react";
import ReviewsCTX from "../../../../../contexts/reviewsConext/ReviewsCTX";
import { ReviewsActionTypes } from "../../../../../contexts/reviewsConext/types";

export const useDataSelect = () => {
  const {
    dispatch,
    state: { expertFilter },
  } = useContext(ReviewsCTX);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch({
      type: ReviewsActionTypes.FILTER_EXPERT,
      payload: { expertId: event.target.value },
    });
  };

  return { handleChange, expertFilter };
};
