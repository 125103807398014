import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(90deg, #e9e5fe 0%, #f6e3b8 100%)",
  borderRadius: "16px",
  color: "#514a7d",
  padding: "8px 26px",
  fontWeight: "bold",
  fontSize: "16px",
  textTransform: "none",
  position: "relative",
  boxShadow: "none",
  width: "200px",
  marginRight: 5,

  "&:before": {
    content: '"⚡"',
    position: "absolute",
    left: "4px",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "20px",
    color: "#FFC107",
  },

  "&:hover": {
    background: "linear-gradient(90deg, #FFF7E5 0%, #F5F5F5 100%)",
  },
}));
