// Данная функция formatNumber принимает один параметр number, который является числом или null. Она использует метод toLocaleString() для форматирования числа с разделителями тысяч.

export const formatNumber = (number: number | null): any => {
  if (!number) {
    return;
  }
  return number.toLocaleString(); // Вывод: "11,111,111"
};

//Функция acceptOnlyNumbers проверяет вводимые символы события event и предотвращает вставку символов, которые не являются числами или знаком минуса.

export const acceptOnlyNumbers = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  const input = event.target.value;
  // Удалить все символы, кроме цифр и минуса
  const sanitizedInput = input.replace(/[^0-9-]/g, "");
  // Обновить значение поля ввода
  event.target.value = sanitizedInput;
};
