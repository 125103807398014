import { DataReviewsHeaderType } from "./types";

const dataReviewsHeader: DataReviewsHeaderType[] = [
  {
    name: "Дата",
  },
  {
    name: "Отзывы ",
  },
  {
    name: "Оценка",
  },
  {
    name: "Клиент",
  },
  {
    name: "Эксперт",
  },
  {
    name: "Опубликован",
  },
  {
    name: "Закреплён",
  },
];

export default dataReviewsHeader;
