import { Box, OutlinedInput, styled } from "@mui/material";

export const StyledOutlinedInput = styled(OutlinedInput)({
    borderRadius: "12px",
    height: "40px",
    width: "316px",
  });

  export const StyledSearchBlock = styled(Box)({
    display: "flex",
    gap: 10,
  });
