import { FC } from "react";
import { UploadFilesForExpertsProps } from "./types";
import UploadAudio from "./uploadAudio/uploadAudio";
import UploadAvatar from "./uploadAvatar";
import UploadImages from "./uploadImages";

const UploadFilesForExperts: FC<UploadFilesForExpertsProps> = ({
  expertId,
  userId,
  uuid,
  currentFilesForExpert
}) => {
  return (
    <>
      <UploadImages expertId={expertId} coverImages={currentFilesForExpert.coverImages}/>
      <UploadAvatar userId={userId} expertId={expertId} uuid={uuid} currentAvatar={currentFilesForExpert.avatar}/>
      <UploadAudio expertId={expertId}  currentAudio={currentFilesForExpert.audio}/>
    </>
  );
};

export default UploadFilesForExperts;
