import { gql } from "@apollo/client";

export const COMPOSE_REPORT = gql`
  query composeReport($input: composeReportInput!) {
    composeReport(input: $input) {
      Date
      NewUsersCount
      Revenue
      PurchaseCount
      ExpertSpends
      MarketingSpends
    }
  }
`;
