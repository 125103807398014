import { BrowserRouter } from "react-router-dom";
import RoutesApp from "./routes/routes";
import GlobalDataContexPrvidertApp from "./contexts/globalContext";
import UserContextPrvidertApp from "./contexts/userContext";
import ReviewsCTXPrvidertApp from "./contexts/reviewsConext";
import PositionedSnackbar from "./components/common/notification";

function App() {
  return (
    <GlobalDataContexPrvidertApp>
      <UserContextPrvidertApp>
        <ReviewsCTXPrvidertApp>
          <BrowserRouter>
            <RoutesApp />
            <PositionedSnackbar />
          </BrowserRouter>
        </ReviewsCTXPrvidertApp>
      </UserContextPrvidertApp>
    </GlobalDataContexPrvidertApp>
  );
}

export default App;
