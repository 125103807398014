import React from 'react'
import { useDeleteClientAccount } from './useDeleteClientAccount'
import { StyledOutlinedInput } from '../../styles'
import { Box, Button } from '@mui/material'
import { DeleteClientAccountProps } from './types'

const DeleteClientAccount = ({ clientId }: DeleteClientAccountProps) => {
  const { deleteAccountClientHandler, reason, setReason } = useDeleteClientAccount()
  
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <StyledOutlinedInput
        placeholder="Опишите причину"
        onChange={(event) =>
          setReason(event.target.value)
        }
        value={reason}
      />
      <Button
        variant="contained"
        color='error'
        disabled={!reason}
        onClick={() => deleteAccountClientHandler(clientId)}
        sx={{ width: "maxContent" }}
      >
        Удалить аккаунт
      </Button>
    </Box>
  )
}

export default DeleteClientAccount
