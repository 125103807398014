import HeaderSchedulePage from "./components/headerSchedulePage/HeaderSchedulePage";
import SchedulePublished from "./schedulePublished";
import SchedulePanel from "./scheduler";
import { useExpertTimetable } from "./useExpertTimetable";

const ExpertScheduler = () => {
  const { expertName, expertSchedule, expertAvatar } = useExpertTimetable();

  return (
    <>
      <HeaderSchedulePage expertName={expertName} avatar={expertAvatar} />
      <SchedulePanel scheduleData={expertSchedule || []} />
      <SchedulePublished scheduleData={expertSchedule} />
    </>
  );
};

export default ExpertScheduler;
