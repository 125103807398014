import { FC } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Stack } from "@mui/material";
import { HtmlTextEditorProps } from "./types";

const HtmlTextEditor: FC<HtmlTextEditorProps> = ({
  setFieldValue,
  valuesKey,
  value,
}) => {
  return (
    <Stack spacing={5} mb={5}>
      <CKEditor
        editor={ClassicEditor}
        data={value || ""}
        onChange={(_event: any, editor: { getData: () => any }) => {
          const data = editor.getData();
          setFieldValue(valuesKey, data);
        }}
      />
    </Stack>
  );
};

export default HtmlTextEditor;
