import React from "react";
import { Box } from "@mui/material";
import BackButton from "../../../common/BackButton";
import CardAddIp from "../../components/cardAddIp";
import InstructionsBlock from "../../components/instructions";
import { HeaderTitle } from "../../../../styles/common/styles";
import { useLocation } from "react-router-dom";

const CreateUpdateIpToWhiteList = () => {
  const location = useLocation();
  const isUpdatePage = location?.state?.ipForUpdate;

  return (
    <>
      <Box mb={4} display={"flex"} gap={2}>
        <BackButton />
        <HeaderTitle>{`${
          isUpdatePage ? "Обновить" : "Добавить"
        } ip в whitelist`}</HeaderTitle>
      </Box>
      <InstructionsBlock />
      <CardAddIp />
    </>
  );
};

export default CreateUpdateIpToWhiteList;
