import React, { FC } from "react";
import { TabReportProps } from "./types";
import { Box } from "@mui/material";

const TabReport: FC<TabReportProps> = ({
  children,
  tabIndex,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={tabIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabIndex === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

export default TabReport;
