import React from "react";
import { useReport } from "./useReport";
import ReportTemplateBlock from "./components/reportTemplateBlock";
import TabsReportPanel from "./components/headerTabsReport";
import SearchReportPanel from "./components/reportHeaderSearchPanel";
import TabReport from "./components/reportTabPanel";
import { HeaderTitle } from "../../styles/common/styles";

const Report = () => {
  const {
    reportSearchDate,
    setReportSearchDate,
    getReportsHandler,
    reportSpendsCountData,
    setReportSpendsCountData,
    sendReportHandler,
    tabIndex,
    a11yProps,
    handleChange,
  } = useReport();
  return (
    <>
      <HeaderTitle>Отчет</HeaderTitle>
      <TabsReportPanel
        a11yProps={a11yProps}
        handleChange={handleChange}
        tabIndex={tabIndex}
      />

      <SearchReportPanel
        setDate={setReportSearchDate}
        reportSearchDate={reportSearchDate}
        getReportsHandler={getReportsHandler}
        sendReportHandler={sendReportHandler}
        tabIndex={tabIndex}
      />

      <TabReport tabIndex={tabIndex} index={0}>
        <ReportTemplateBlock
          reportSpendsCountData={reportSpendsCountData}
          setReportSpendsCountData={setReportSpendsCountData}
        />
      </TabReport>
      <TabReport tabIndex={tabIndex} index={1}>
        <ReportTemplateBlock
          reportSpendsCountData={reportSpendsCountData}
          setReportSpendsCountData={setReportSpendsCountData}
        />
      </TabReport>
    </>
  );
};

export default Report;
