import { useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import {
  uploadExpertGreetingsAudioFile,
  uploadExpertGreetingsAudioFileVariables,
} from "../../../../../../graphql/__generated__/uploadExpertGreetingsAudioFile";
import { UPLOAD_AUDIO } from "../../../../../../graphql/UPLOAD_AUDIO";
import globalDataCTX from "../../../../../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../../../../../contexts/globalContext/types";
import { GET_EXPERT_FOR_UPDATE } from "../../../../../graphql/GET_EXPERT_FOR_UPDATE";

export const useUploadAudio = (expertId: number) => {
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const { setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);

  const [
    uploadAudio,
    { data: uploadAudioData, error: uploadAudioError, loading: loadingUpdate },
  ] = useMutation<
    uploadExpertGreetingsAudioFile,
    uploadExpertGreetingsAudioFileVariables
  >(UPLOAD_AUDIO);

  const uploadAudioHandler = () => {
    uploadAudio({
      variables: {
        expertID: Number(expertId),
        file: {
          audioFile: audioFile,
        },
      },
      refetchQueries: [
        {
          query: GET_EXPERT_FOR_UPDATE,
          variables: { id: expertId }
        },
      ],
    });
  };
  const resetStateAudioFileHandler = () => {
    setAudioFile(null);
  };

  useEffect(() => {
    if (uploadAudioData && !uploadAudioError && !loadingUpdate) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Загружено");
    }

    if (uploadAudioError) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(uploadAudioError.message);
    }
  }, [
    loadingUpdate,
    setNotificationEvent,
    setNotificationMsg,
    uploadAudioData,
    uploadAudioError,
  ]);

  const handleAudioFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setAudioFile(event.target.files[0]);
    }
  };
  

  return {
    handleAudioFileChange,
    uploadAudioHandler,
    resetStateAudioFileHandler,
    audioFile,
    loadingUpdate,
  };
};
