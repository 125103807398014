import { FC } from "react";
import BonusFormulaSelectInput from "./components/bonusFormulaSelectInput";
import SelectCheckBox from "./components/selectCheckBox";
import FormButtonsPanel from "./components/formButtonsPanel";
import BonusTypeSelectInput from "./components/bonusTypeSelectInput";
import { ErrorMessage, Form, Formik } from "formik";
import { useBonusModalsContext } from "./useBonusModalsContext";
import { validationSchema } from "./validationSchema";
import { TextField } from "@mui/material";
import { BonusModalsContextProps } from "./types";
import { StyledBlockInput, StyledStackRowInputs } from "./styles";
import { StyledErrorText } from "../../../../styles/common/styles";

const BonusModalsContext: FC<BonusModalsContextProps> = ({
  toggleIsModalOpen,
}) => {
  const { bonusInitialValues, submitHandler } =
    useBonusModalsContext(toggleIsModalOpen);

  return (
    <>
      <Formik
        initialValues={bonusInitialValues}
        onSubmit={(values) => submitHandler(values)}
        validationSchema={validationSchema}
      >
        {({
          handleBlur,
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <StyledStackRowInputs direction="row" spacing={2}>
              <StyledBlockInput>
                <TextField
                  onBlur={handleBlur}
                  name="name"
                  value={values?.name}
                  label="Название бонуса"
                  onChange={handleChange}
                  fullWidth
                />
                <ErrorMessage
                  name="name"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
              <StyledBlockInput>
                <BonusTypeSelectInput
                  initialValue={values.type}
                  setFieldValue={setFieldValue}
                  valuesKey="type"
                />
                <ErrorMessage
                  name="type"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>

              <StyledBlockInput>
                <SelectCheckBox
                  setFieldValue={setFieldValue}
                  valuesKey="affectedObjects"
                  placeholder="affected objects"
                  initialValue={values.affectedObjects!}
                />
                <ErrorMessage
                  name="affectedObjects"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>

              <StyledBlockInput>
                <BonusFormulaSelectInput
                  initialValue={values.formula}
                  setFieldValue={setFieldValue}
                  valuesKey="formula"
                />
                <ErrorMessage
                  name="formula"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
            </StyledStackRowInputs>

            <StyledStackRowInputs direction="row" spacing={2}>
              <StyledBlockInput>
                <TextField
                  onBlur={handleBlur}
                  name="amount"
                  value={values?.amount}
                  label="Количество средств"
                  onChange={handleChange}
                  type="number"
                  fullWidth
                />
                <ErrorMessage
                  name="amount"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>

              <StyledBlockInput>
                <TextField
                  onBlur={handleBlur}
                  name="lifetimePeriod"
                  value={values?.lifetimePeriod}
                  label="Срок жизни в днях"
                  onChange={handleChange}
                  type="number"
                  fullWidth
                />
                <ErrorMessage
                  name="lifetimePeriod"
                  render={(msg) => <StyledErrorText>{msg}</StyledErrorText>}
                />
              </StyledBlockInput>
            </StyledStackRowInputs>
            <FormButtonsPanel />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BonusModalsContext;
