import { mockDataPromoCodesHeaderTypes } from "./types";

export const mockDataPromoCodesHeader: mockDataPromoCodesHeaderTypes[] = [
  {
    name: "Промо",
  },
  {
    name: "Бонус",
  },
  {
    name: "Статус",
  },
  {
    name: "Создан",
  },
  {
    name: "Дата начала",
  },
  {
    name: "Дата окончания",
  },
  {
    name: "Общее количество",
  },
  {
    name: "Активировано",
  },
  {
    name: "Тип использования",
  },
  {
    name: "Обновить",
  },
  {
    name: "Изменить статус",
  },
  {
    name: "Загрузить",
  },
];

