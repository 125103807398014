import { styled, Box, TableCell, tableCellClasses } from "@mui/material";
import { $colorVariables } from "../../../../styles/colorVariables";

export const StyledStatusColorByBoolean = styled(Box)<any>(({ status }) => ({
  fontWeight: 600,
  color: status ? $colorVariables.$green01 : $colorVariables.$red,
}));

export const StyledTableCellBody = styled(TableCell)<any>(({ status }) => ({
  width: "auto",
  maxWidth: "480px",

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: $colorVariables.$blue07,
    color: "red",
  },
  borderLeft:
    status === "active"
      ? `8px solid ${$colorVariables.$green01}`
      : status === "inactive"
      ? `8px solid ${$colorVariables.$redTransparent}`
      : "",

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },

  [`&.MuiTableRow-root`]: {
    borderCollapse: "inherit !important",
  },
}));
