import { Stack } from "@mui/material";
import StatusTabs from "./statusTabs";
import DateSelect from "./components/dateSelect";
import { useFiltersPanel } from "./useFiltersPanel";
import { StylesResetButton } from "./styles";
import DatePickers from "./components/datePicker";
import ExpertSelect from "./components/expertSelect";
import GradeSelect from "./components/gradeSelect";
import PinCheckbox from "./components/pinCheckbox";

const FiltersPanel = () => {
  const { allExperts, resetFiltersHandler } = useFiltersPanel();

  return (
    <>
      <StatusTabs />
      <Stack
        direction="row"
        mt="24px"
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction="row">
          <DatePickers />
          <ExpertSelect experts={allExperts} />
          <DateSelect title="Клиент" width={190} />
          <GradeSelect />
          <PinCheckbox />
        </Stack>
        <StylesResetButton onClick={resetFiltersHandler}>
          Сбросить
        </StylesResetButton>
      </Stack>
    </>
  );
};

export default FiltersPanel;
