import React, { FC } from "react";
import { Table } from "@mui/material";
import BonusTableHeader from "./components/bonusTableHeader";
import BonusTableBody from "./components/bonusTableBody";
import { BonuseTabelProps } from "./types";
import { StyledTableContainer } from "../../../../styles/common/styles";

const BonuseTabel: FC<BonuseTabelProps> = ({ bonusesData }) => {
  return (
    <div>
      <StyledTableContainer>
        <Table
          aria-label="customized table"
          size="medium"
          sx={{ borderCollapse: "inherit !important" }}
        >
          <BonusTableHeader />
          <BonusTableBody bonusesData={bonusesData} />
        </Table>
      </StyledTableContainer>
    </div>
  );
};

export default BonuseTabel;
