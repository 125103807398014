import {
  Box,
  OutlinedInput,
  TableContainer,
  Typography,
  styled,
  TableCell,
  tableCellClasses,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import { $colorVariables } from "../../styles/colorVariables";

export const HeaderTitle = styled(Typography)({
  color: $colorVariables.$blue01,
  fontSize: "24px",
  fontWeight: "600",
});

export const StyledSearchBlock = styled(Box)({
  display: "flex",
  gap: 10,
});

export const StyledSearchPanelWrapper = styled(Box)({
  margin: "16px 0 32px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minHeight: "50px",
});

export const StyledOutlinedInput = styled(OutlinedInput)({
  borderRadius: "12px",
  height: "40px",
  width: "316px",
});

export const StyledTableContainer = styled(TableContainer)({
  borderTopRightRadius: "16px",
  borderTopLeftRadius: "16px",
  marginTop: "24px",
});

export const StyledTableCell = styled(TableCell)(() => ({
  maxWidth: "480px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: $colorVariables.$blue07,
    color: $colorVariables.$blue02,
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledErrorText = styled(Box)({
  marginTop: "5px",
  fontSize: "13px",
  color: "red",
});

export const StyledButtonsForm = styled(Button)({
  color: "white",
  padding: "8px 22px",
  backgroundColor: $colorVariables.$blue02,
  "&:hover": {
    backgroundColor: $colorVariables.$blue02,
  },
});

export const StyledDivider = styled(Divider)<any>(({ textColor }) => ({
  margin: "37px 0",
  color: textColor ? textColor : $colorVariables.$blue01,
  fontSize: "18px",
  fontWeight: 600,
}));

export const StyledSearchBarWrapper = styled(Stack)({
  marginTop: "24px",
  justifyContent: "space-between",
});
