import { SelectChangeEvent } from "@mui/material";
import { useState } from "react";

export const useDataSelect = () => {
  const [selectValue, setSelectValue] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value as string);
  };
  return { handleChange, selectValue };
};
