export const locations = [{ text: "Эксперты", id: 1 }];

export const grouping = [
  {
    resourceName: "roomId",
  },
  {
    resourceName: "members",
  },
];
