import { createContext } from "react";
import { ReviewFilter } from "../../globalTypes";

export const ReviewsConextInitialState: ReviewFilter = {
  statusFilter: [],
  clientFilter: [],
  expertFilter: [],
  dateFilter: {
    earlierThan: null,
    laterThan: null,
  },
  gradeFilter: [],
  isPinned: false,
};

const ReviewsCTX = createContext<{
  state: ReviewFilter;
  dispatch: React.Dispatch<any>;
}>({
  state: ReviewsConextInitialState,
  dispatch: () => null,
});

export default ReviewsCTX;
