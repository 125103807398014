import { TableCell, tableCellClasses, styled, Box } from "@mui/material";
import { $colorVariables } from "../../../../../../styles/colorVariables";

export const StyledTableCellBody = styled(TableCell)<any>(({ indikator }) => ({
  width: "auto",
  maxWidth: "480px",

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: $colorVariables.$blue07,
    color: "red",
  },
  borderLeft:
    indikator === "active"
      ? `8px solid ${$colorVariables.$greenTransparent}`
      : indikator === "inActive"
      ? `8px solid ${$colorVariables.$redTransparent}`
      : "",
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },

  [`&.MuiTableRow-root`]: {
    borderCollapse: "inherit !important",
  },
}));

export const StyledTableStatusBlock = styled(Box)<any>(({ status }) => ({
  padding: "4px 8px",
  borderRadius: "4px",
  backgroundColor:
    status === "active"
      ? ` ${$colorVariables.$greenTransparent}`
      : ` ${$colorVariables.$redTransparent}`,
}));
