import { gql } from "@apollo/client";

export const GET_CHASHBACK_RULES = gql`
  query getCashbackRules(
    $filter: CashbackRulesFilter
    $filterBonus: BonusFilter!
  ) {
    getCashbackRules(filter: $filter) {
      id
      lowerBoundary
      upperBoundary
      bonusID
      isActive
    }
    getBonuses(filter: $filterBonus) {
      id
      name
      formula
      amount
    }
  }
`;
