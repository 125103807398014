import {
  ListItemIcon,
  ListItemButton,
  Typography,
  styled,
} from "@mui/material";
import { $colorVariables } from "../../../../../styles/colorVariables";

export const StyledNavItem = styled(ListItemButton)({
  height: "184px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});

export const StyledNavTypographyLink = styled(Typography)({
  fontWeight: 400,
  fontSize: "12px",
  color: $colorVariables.$white,
  marginTop: "10px",
  lineHeight: "130%",
});

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: $colorVariables.$white,
});

export const StyledNavSectionContainer = styled(ListItemIcon)({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const StyledLogOutButton = styled(ListItemIcon)({
  display: "block",
  alignItems: "center",
  textAlign: "center",
  fontSize: "16px",
  marginBottom: "20px",
  cursor: "pointer",
});
