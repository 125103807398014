import { gql } from "@apollo/client";

export const GET_EXPERT_METHODS_SCOPES_SKILLS = gql`
  query getExpertMethodsScopesSkills(
    $filterMethods: MethodFilter
    $filterScopes: ScopeFilter
    $filterSkills: SkillFilter
  ) {
    getMethods(filter: $filterMethods) {
      id
      name
    }
    getScopes(filter: $filterScopes) {
      id
      name
    }
    getSkills(filter: $filterSkills) {
      id
      name
    }
  }
`;
