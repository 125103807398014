import { FC } from "react";
import { Stack } from "@mui/system";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { ReatingProps } from "./types";
import { Typography } from "@mui/material";
import { $colorVariables } from "../../../../../styles/colorVariables";
import IconComponent from "../../../../common/icon";

const Rating: FC<ReatingProps> = ({ rating, fontWeight }) => {
  return (
    <Stack direction="row" alignItems="center">
      <Typography
        fontWeight={fontWeight}
        color={"black"}
        marginRight={"4px"}
        fontSize={"13px"}
      >
        {rating}
      </Typography>
      <IconComponent color={$colorVariables.$yellow01} icon={faStar} />
    </Stack>
  );
};

export default Rating;
