import { StyledButtonContainer, StyledSubmitButton } from "./styles";

const FormButtonsPanel = () => {
  return (
    <StyledButtonContainer>
      <StyledSubmitButton type="submit" variant="contained">
        добавить
      </StyledSubmitButton>
    </StyledButtonContainer>
  );
};

export default FormButtonsPanel;
