import React from "react";
import { ParticipationStatusBlockProps } from "./types";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Chip,
} from "@mui/material";
import { ReferralProgramStatus } from "../../../../globalTypes";
import { useParticipationStatus } from "./useParticipationStatus";
import Loader from "../../../common/loader";

const ParticipationStatusBlock = ({
  singleEditClientData,
  userDataReferralStatus
}: ParticipationStatusBlockProps) => {
  const {
    clientReferralProgramStatus,
    handleChange,
    updateReferalParticipationStatusHandler,
    loadingParticipation,
  } = useParticipationStatus(singleEditClientData, userDataReferralStatus);

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <FormControl sx={{ minWidth: "50%" }}>
        <InputLabel id="demo-select-small-label">
          Участие в реферальной программе
        </InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={clientReferralProgramStatus}
          label="Участия в реферальной программе"
          onChange={handleChange}
        >
          <MenuItem value={ReferralProgramStatus.active}>
            {ReferralProgramStatus.active}
          </MenuItem>
          <MenuItem value={ReferralProgramStatus.blocked}>
            {ReferralProgramStatus.blocked}
          </MenuItem>
          <MenuItem value={ReferralProgramStatus.not_available} disabled>
            {ReferralProgramStatus.not_available}
          </MenuItem>
          <MenuItem value={ReferralProgramStatus.unblocked} disabled>
            {ReferralProgramStatus.unblocked}
          </MenuItem>
        </Select>
      </FormControl>
      <Chip
          label={userDataReferralStatus === "active" ? "Активный" : "Неактивный"}
          color={userDataReferralStatus === "active" ? "success" : "error"}
          sx={{
            height: "unset",
            padding: "4px 12px",
            fontSize: "15px",
          }}
        />
      <Button
        variant="contained"
        onClick={updateReferalParticipationStatusHandler}
        sx={{ minWidth: "unset" }}
        size="large"
        disabled={loadingParticipation}
      >
        Изменить статус
        {loadingParticipation && <Loader />}
      </Button>
    </Box>
  );
};

export default ParticipationStatusBlock;
