import { Box, OutlinedInput, styled } from "@mui/material";

export const StyledOutlinedInput = styled(OutlinedInput)({
  borderRadius: "12px",
  height: "40px",
});

export const StyledContentBlock = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(4, auto)",
  gap: 2,
});