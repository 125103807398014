import { ApolloError } from "@apollo/client";

export enum ErrorCodeEnum {
  StatusCodePromoNameExists = 33,
  StatusCodePromoCodeActivated = 34,
  StatusCodePromoCodeLength = 35,
  StatusCodeReportNoSuchEntity = 4,
  StatusCodeReportIncorrectDate = 5,
  StatusCodeGetClientNoSuchUser = 21,
}

export function getErrorCode(error: ApolloError | undefined) {
  const errorJSON = JSON.parse(JSON.stringify(error)).graphQLErrors[0];
  return Number(errorJSON.extensions.code);
}

export function getErrorByCode(code: number) {
  switch (code) {
    case ErrorCodeEnum.StatusCodePromoNameExists:
      return `промо с таким названием уже существует`;
    case ErrorCodeEnum.StatusCodePromoCodeActivated:
      return `промокод уже активирован`;
    case ErrorCodeEnum.StatusCodePromoCodeLength:
      return `codeLength для promoType(OnePerUser) должен быть больше 0`;
    case ErrorCodeEnum.StatusCodeReportNoSuchEntity:
      return `Отчет не найден`;
    case ErrorCodeEnum.StatusCodeReportIncorrectDate:
      return `Не удается разобрать дату`;
    case ErrorCodeEnum.StatusCodeGetClientNoSuchUser:
      return `Нет такого пользователя`;
    default:
      return null;
  }
}
