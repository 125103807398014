import React from "react";
import { HeaderTitle } from "../../styles/common/styles";
import BonusSearchPanel from "./components/bonusSearchPanel";
import { useBonuses } from "./useBonuses";
import BasicModal from "../common/modal";
import BonuseTabel from "./components/bonusesTabel";
import BonusModalsContext from "./components/bonusModalsContext";

const Bonuses = () => {
  const {
    isOpenModal,
    bonusesData,
    filterBonuses,
    setFilterBonuses,
    toggleIsModalOpen,
    getFilterBonusesHandler,
  } = useBonuses();
  return (
    <>
      <HeaderTitle>Бонусы</HeaderTitle>
      <BonusSearchPanel
        toggleIsModalOpen={toggleIsModalOpen}
        filterBonuses={filterBonuses}
        setFilterBonuses={setFilterBonuses}
        getFilterBonusesHandler={getFilterBonusesHandler}
      />
      <BonuseTabel bonusesData={bonusesData} />
      <BasicModal
        isOpen={isOpenModal}
        handelClose={toggleIsModalOpen}
        title="Создать Бонус"
        width="80%"
      >
        <BonusModalsContext toggleIsModalOpen={toggleIsModalOpen} />
      </BasicModal>
    </>
  );
};

export default Bonuses;
