import { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GET_CHASHBACK_RULES } from "./graphql/GET_CHASHBACK_RULES";
import {
  getCashbackRules,
  getCashbackRulesVariables,
} from "./graphql/__generated__/getCashbackRules";
import { FormattedCashBackDataType } from "./types";

export const useCashback = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idForDeleteRule, setIdForDeleteRule] = useState<number | null>(null);

  const {
    data: cashBackData,
    loading: cashBackLoading,
    refetch: refetchGetCashBack,
  } = useQuery<getCashbackRules, getCashbackRulesVariables>(
    GET_CHASHBACK_RULES,
    {
      variables: { filter: { isActive: null }, filterBonus: {} },
      fetchPolicy: "network-only",
    }
  );

  const formattedCashBackData: FormattedCashBackDataType = useMemo(() => {
    return cashBackData?.getCashbackRules?.map((item) => {
      const findBonus = cashBackData.getBonuses?.find((itemBonus) => {
        if (itemBonus?.id === item.bonusID) {
          return itemBonus;
        }
        return null;
      });

      return {
        ...item,
        bonusName: findBonus?.name,
        bonusFormulaType: findBonus?.formula,
        bonusAmount: findBonus?.amount,
      };
    });
  }, [cashBackData]);

  const toggleOpenCloseModal = () => {
    setIsOpenModal((prevState) => {
      return !prevState;
    });
  };

  return {
    casheBackData: formattedCashBackData,
    loadingPage: cashBackLoading,
    refetchGetCashBack,
    toggleOpenCloseModal,
    isOpenModal,
    setIdForDeleteRule,
    idForDeleteRule,
  };
};
