import { Box, Tab, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import { $colorVariables } from "../../../../styles/colorVariables";

export const StyledTabsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: `1px solid ${$colorVariables.$blue05}`,
  width: "100%",
});

export const StyledTabItem = styled(Tab)({
  padding: 0,
  marginRight: "34px",
  color: $colorVariables.$blue03,
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "130%",
});

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  "&  .MuiToggleButtonGroup-grouped": {
    border: "none",
    borderBottom: `3px solid transparent`,
  },
  "& .Mui-selected": {
    borderBottom: `3px solid ${$colorVariables.$blue02}`,
    borderRadius: "2px",
    color: `${$colorVariables.$blue02} !important`,
    backgroundColor: `transparent !important`,
    fontWeight: 600,
  },
  "& :hover": {
    backgroundColor: `transparent !important`,
  },
});

export const StyledToggleButton = styled(ToggleButton)({
  color: $colorVariables.$blue03,
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "18px",
  marginRight: "30px",
  padding: "11px 0",
  textTransform: "initial",

  "& svg": {
    marginRight: "6px",
  },
});
