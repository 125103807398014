import { gql } from "@apollo/client";

export const GET_BONUSES = gql`
  query getBonuses($input: BonusFilter!) {
    getBonuses(filter: $input) {
      id
      name
      type
      affectedObjects
      formula
      amount
      lifetimePeriod
    }
  }
`;
