import { gql } from "@apollo/client";

export const GET_PROMOS = gql`
  query getPromos($input: PromoFilter!) {
    getPromos(filter: $input) {
      id
      bonusID
      name
      redeemAbility
      generatePattern
      generateCount
      bonusName
      status
      startDate
      endDate
      createdAt
      usageCount
    }

    getAffiseOffers {
      Id
      Status
      Privacy
      Title
      AllowedAffiliates {
        Id
        Email
      }
    }
  }
`;
