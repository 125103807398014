import {
  AppointmentForm,
  WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import { styled } from "@mui/material";
import { $colorVariables } from "../../../styles/colorVariables";

export const StyledWeekViewTimeScaleLabel = styled(WeekView.TimeScaleLabel)({
  [`&.Label-label`]: {
    height: "30px",
    lineHeight: "30px",
  },
  [`.Label-text`]: {
    color: $colorVariables.$blue04,
  },
  [`&.Label-emptyLabel`]: {
    display: "none",
  },
});

export const StyledTimeScaleTickCellComponent = styled(
  WeekView.TimeScaleTickCell
)({
  [`&.TickCell-cell`]: {
    height: "30px",
  },
});

export const StyledOverlayModalComponent = styled(AppointmentForm.Overlay)({
  [`.Overlay-paper`]: {
    top: "50%",
    left: " 50%",
    height: "auto",
    transform: "translate(-50%, -50%) !important",
    borderRadius: '15px'
  },
});
