import { FC } from "react";
import { Button, InputAdornment } from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { StyledWrapperExports } from "./styles";
import { StyledOutlinedInput } from "../../../styles/common/styles";
import { IExportsNav } from "./types";
import IconComponent from "../../common/icon";
import { useNavigate } from "react-router-dom";

export const ExpertsNav: FC<IExportsNav> = ({ searchText, setSearchText }) => {
  const handlerSearchText = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(event.target.value);
  };
  const navigate = useNavigate();

  return (
    <StyledWrapperExports direction="row">
      <StyledOutlinedInput
        placeholder="Поиск по имени"
        onChange={handlerSearchText}
        value={searchText}
        endAdornment={
          <InputAdornment position="end">
            <IconComponent icon={faMagnifyingGlass} />
          </InputAdornment>
        }
      />
      <Button
        variant="contained"
        color="info"
        onClick={() => navigate("details")}
      >
        + Добавить
      </Button>
    </StyledWrapperExports>
  );
};
