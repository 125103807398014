import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SESSION_BY_ADMIN } from "./graphql/GET_SESSION_BY_ADMIN.graphql";
import { useContext, useEffect, useState } from "react";
import { END_SESSION_BY_ADMIN } from "./graphql/END_SESSION_BY_ADMIN.graphql";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../contexts/globalContext/types";
import { useKeyboardEvent } from "../../utils/useKeyboard";
import {
  getSessionByAdmin,
  getSessionByAdminVariables,
} from "./graphql/__generated__/getSessionByAdmin";

export const useSession = () => {
  const [sessionId, setSessionId] = useState("");
  const [reason, setReason] = useState("");
  const [sessionData, setSessionData] = useState<getSessionByAdmin | null>(
    null
  );

  const { setNotificationEvent, setNotificationMsg, setLoading } =
    useContext(globalDataCTX);

  const [
    getSessionByAdminQuery,
    { data: sessionDataQuery, loading: sessionLoading, error: sessionError },
  ] = useLazyQuery<getSessionByAdmin, getSessionByAdminVariables>(
    GET_SESSION_BY_ADMIN,
    { fetchPolicy: "no-cache" }
  );

  const [
    endSessionMutation,
    {
      data: endedStatusData,
      loading: endedStatusLoading,
      error: endedStatusError,
    },
  ] = useMutation(END_SESSION_BY_ADMIN);

  const getSesionDataHandler = () => {
    getSessionByAdminQuery({ variables: { sessionId: Number(sessionId) } });
  };

  const endSessionHandler = () => {
    endSessionMutation({
      variables: {
        input: {
          sessionId: Number(sessionId),
          reason: reason,
        },
      },
    });
    setTimeout(() => {
      getSesionDataHandler();
      setReason("");
      setSessionId("");
    }, 1000);
  };

  useEffect(() => {
    if (sessionDataQuery && !sessionLoading && !sessionError) {
      setSessionData(sessionDataQuery);
    }
  }, [sessionDataQuery, sessionLoading, sessionError]);

  useEffect(() => {
    const loading = endedStatusLoading || sessionLoading;
    setLoading(loading);

    switch (true) {
      case endedStatusData:
        setNotificationEvent(notificationEventType.success);
        setNotificationMsg("Успех");
        break;
      case !!endedStatusError || !!sessionError:
        setNotificationEvent(notificationEventType.error);
        setNotificationMsg(
          `проверьте ID  сессии ${
            endedStatusError?.message || sessionError?.message
          }`
        );
        break;
      default:
        return;
    }
  }, [
    endedStatusData,
    endedStatusError,
    endedStatusLoading,
    sessionError,
    sessionDataQuery,
    sessionLoading,
    setNotificationEvent,
    setNotificationMsg,
    setLoading,
  ]);

  const removeSessionCard = () => {
    setSessionData(null)
    setReason("");
    setSessionId("");
  }

  useKeyboardEvent("Enter", getSesionDataHandler);

  return {
    sessionData,
    sessionId,
    reason,
    loading: endedStatusLoading || sessionLoading,
    isReadyToChangeStatus: !!reason,
    setReason,
    setSessionId,
    getSesionDataHandler,
    endSessionHandler,
    removeSessionCard
  };
};
