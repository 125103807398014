import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  Skeleton,
} from "@mui/material";
import CashBackTableHead from "../cashBackTableHead";
import { useNavigate } from "react-router-dom";
import { CashBackTableBodyProps } from "./types";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledTableCell } from "../../../../styles/common/styles";
import { StyledStatusColorByBoolean, StyledTableCellBody } from "./styles";
import { casheBachTabelNamesHeader } from "../cashBackTableHead/constants";

const CashBackTableBody = ({
  cashBackData,
  isLoading,
  toggleOpenCloseModal,
  setIdForDeleteRule,
}: CashBackTableBodyProps) => {
  const navigate = useNavigate();

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderTopRightRadius: "16px",
        borderTopLeftRadius: "16px",
        mt: "24px",
      }}
    >
      <Table
        aria-label="customized table"
        size="medium"
        sx={{ borderCollapse: "inherit !important" }}
      >
        <CashBackTableHead isLoading={isLoading}/>
        <TableBody>
          {cashBackData?.map(
            (
              {
                id,
                bonusName,
                bonusID,
                isActive,
                lowerBoundary,
                upperBoundary,
                bonusFormulaType,
                bonusAmount,
              },
              idx
            ) => {
              return (
                <TableRow
                  key={idx}
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("update", {
                      state: {
                        cashBackDataForUpdate: {
                          id,
                          bonusID,
                          bonusName,
                          isActive,
                          lowerBoundary,
                          upperBoundary,
                        },
                      },
                    })
                  }
                >
                  {!isLoading ? (
                    <>
                      <StyledTableCellBody
                        status={isActive ? "active" : "inactive"}
                      >
                        {id}
                      </StyledTableCellBody>
                      <StyledTableCell>{lowerBoundary} &#8381;</StyledTableCell>
                      <StyledTableCell>{upperBoundary} &#8381;</StyledTableCell>
                      <StyledTableCell>{bonusName}</StyledTableCell>
                      <StyledTableCell>
                        {bonusFormulaType} / {bonusAmount}
                      </StyledTableCell>
                      <StyledTableCell>
                        <StyledStatusColorByBoolean status={isActive}>
                          {isActive ? "Активный" : "Неактивный"}
                        </StyledStatusColorByBoolean>
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={(event) => {
                          event.stopPropagation();
                          toggleOpenCloseModal();
                          setIdForDeleteRule(id);
                        }}
                      >
                        <DeleteIcon color="error" />
                      </StyledTableCell>
                    </>
                  ) : (
                    casheBachTabelNamesHeader.map(() => {
                      return (
                        <StyledTableCell key={idx} align="left">
                          <Skeleton animation="wave" />
                        </StyledTableCell>
                      )
                    })
                  )}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CashBackTableBody;
