import moment from "moment";
import 'moment/locale/ru';

//Данная функция преобразует переданное количество секунд в форматированную дату и время,

export const seconsdConvertToDate = (date: number, format?: string) => {
  moment.locale('ru'); // Устанавливаем локаль на русский
  return moment(date * 1000).format(format); // Вывод: "2021-06-30 12:00:00"
};

export const addCurrentDateYears = (year: number) => {
  const currentDate = moment();
  return currentDate.add(year, "years").format();
};

export const addDateSeconds = (date: string | any, seconds: number) => {
  const currentDate = moment(date);
  return currentDate.add(seconds, "seconds").format();
};

//функция checkLastHour возвращает true, если переданная дата соответствует последнему часу (или последней секунде) дня, и false в противном случае.

export const checkLastHour = (endDate: string | any, whitSeconds: boolean = true) => {
  return whitSeconds
    ? moment(endDate).hours() === 23 &&
        moment(endDate).minutes() === 59 &&
        moment(endDate).seconds() === 59
    : moment(endDate).hours() === 23 && moment(endDate).minutes() === 59;
};
