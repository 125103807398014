import { StatusFilterBarDataType, StatusFilterEnum } from "./types";

export const statusFilterBarData: StatusFilterBarDataType[] = [
  {
    title: "Активные",
    value: StatusFilterEnum.ACTIVE,
  },
  {
    title: "Неактивные",
    value: StatusFilterEnum.IN_ACTIVE,
  },
  {
    title: "Созданные",
    value: StatusFilterEnum.CREATED,
  },
  {
    title: "Все",
    value: StatusFilterEnum.ALL,
  },
];
