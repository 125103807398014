import { useMutation } from "@apollo/client";
import { DELETE_ACCOUNT_BY_ADMIN } from "../../graphql/DELETE_ACCOUNT_BY_ADMIN.graphql ";
import {
  deleteAccountByAdmin,
  deleteAccountByAdminVariables,
} from "../../graphql/__generated__/deleteAccountByAdmin";
import { useContext, useEffect, useState } from "react";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";

export const useDeleteClientAccount = () => {
  const [reason, setReason] = useState("");
  const { setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);

  const [deleteAccountClient, { loading, data, error }] = useMutation<
    deleteAccountByAdmin,
    deleteAccountByAdminVariables
  >(DELETE_ACCOUNT_BY_ADMIN);

  const deleteAccountClientHandler = (clientId: number) => {
    deleteAccountClient({
      variables: {
        input: {
          userID: clientId,
          reason: reason,
        },
      },
    });
  };

  useEffect(() => {
    if (data && !error && !loading) {
      setNotificationEvent(notificationEventType.success)
      setNotificationMsg("Аккаунт успешно удалён")
    }
    if (error) {
      setNotificationEvent(notificationEventType.error)
      setNotificationMsg(error.message)
    }
  }, [data, error, loading, setNotificationEvent, setNotificationMsg]);

  return {
    setReason,
    reason,
    deleteAccountClientHandler,
  };
};
