import { FC } from "react";
import { Stack } from "@mui/material";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  StyledDeletLongButton,
  StyledDoneLongButton,
  StyledEditButton,
} from "./styles";
import { useReviewsControlButtons } from "./useReviewsControlButtons";
import { ReviewsControlButtonsProps } from "./types";
import { ReviewStatus } from "../../../globalTypes";
import { $colorVariables } from "../../../styles/colorVariables";
import IconComponent from "../../common/icon";
import ControlButtons from "../../common/controlButton";

const ReviewsControlButtons: FC<ReviewsControlButtonsProps> = ({
  status,
  reviewID,
}) => {
  const {
    handlePublishReview,
    handledeleteReview,
    show,
    toggleShowControlButton,
  } = useReviewsControlButtons(reviewID);

  const HandlerChangeStatus = () => {
    switch (status) {
      case ReviewStatus.PUBLISHED:
        return (
          <Stack>
            <StyledDoneLongButton variant="contained" color="success">
              <IconComponent color={$colorVariables.$green01} icon={faCheck} />
            </StyledDoneLongButton>
            <StyledEditButton onClick={toggleShowControlButton}>
              Изменить
            </StyledEditButton>
          </Stack>
        );
      case ReviewStatus.DELETED:
        return (
          <Stack>
            <StyledDeletLongButton variant="contained">
              <IconComponent color={$colorVariables.$red} icon={faXmark} />
            </StyledDeletLongButton>
            <StyledEditButton onClick={toggleShowControlButton}>
              Изменить
            </StyledEditButton>
          </Stack>
        );
      case ReviewStatus.ON_MODERATION:
        return (
          <ControlButtons
            firstClickHandler={handlePublishReview}
            secondClickHandler={handledeleteReview}
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    <Stack direction="row">
      {show ? (
        <ControlButtons
          firstClickHandler={handlePublishReview}
          secondClickHandler={handledeleteReview}
        />
      ) : (
        <HandlerChangeStatus />
      )}
    </Stack>
  );
};

export default ReviewsControlButtons;
