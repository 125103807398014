import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { $colorVariables } from "../../styles/colorVariables";

export const StyledButtonUpIcon = styled(Button)({
  marginTop: "16px",
  borderRadius: "50%",
  minWidth: "unset",
  padding: "8px 12px",
  position: "fixed",
  bottom: "30px",
  right: "40px",
  backgroundColor: $colorVariables.$blue01,
  "&:hover": {
    backgroundColor: $colorVariables.$blue01,
  },
});

