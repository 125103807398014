import { useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import {
  setAvatar,
  setAvatarVariables,
} from "../../../../../../graphql/__generated__/setAvatar";
import { UPLOAD_AVATAR_USER } from "../../../../../../graphql/UPLOAD_AVATAR_USER.graphql";
import globalDataCTX from "../../../../../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../../../../../contexts/globalContext/types";
import { GET_EXPERT_FOR_UPDATE } from "../../../../../graphql/GET_EXPERT_FOR_UPDATE";

export const useUploadAvatar = (userId: number, uuid: string, expertId: number) => {
  const { setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);

  const [
    uploadAvatar,
    { data: uploadAvatarData, error: errorAvatar, loading: loadingAvatar },
  ] = useMutation<setAvatar, setAvatarVariables>(UPLOAD_AVATAR_USER);

  useEffect(() => {
    if (uploadAvatarData && !errorAvatar && !loadingAvatar) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Загружено");
    }
    if (errorAvatar) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(errorAvatar.message);
    }
  }, [
    errorAvatar,
    loadingAvatar,
    setNotificationEvent,
    setNotificationMsg,
    uploadAvatarData,
  ]);

  const uploadAvatarHandler = () => {
    uploadAvatar({
      variables: {
        user: {
          id: userId,
          uuid: uuid,
        },
        avatar: {
          avatar: avatarFile,
        },
      },
      refetchQueries: [
        {
          query: GET_EXPERT_FOR_UPDATE,
          variables: { id: expertId }
        },
      ],
    });
  };

  const handleAvatarFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setAvatarFile(event.target.files[0]);
      // Сбрасываем значение input после обработки файла
      event.target.value = ""
    }
  };

  const resetStateAvatarFileHandler = () => {
    setAvatarFile(null);
  };

  return {
    handleAvatarFileChange,
    uploadAvatarHandler,
    resetStateAvatarFileHandler,
    avatarFile,
    loadingAvatar,
  };
};
