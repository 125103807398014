import { useContext, useEffect, useState } from "react";
import { limitAmount } from "../../utils";
import { useMutation } from "@apollo/client";
import { notificationEventType } from "../../../../contexts/globalContext/types";
import { CORRECT_BALANCE } from "../../graphql/CORRECT_BALANCE.graphql";
import { GET_USER } from "../../graphql/GET_USER.graphql";
import {
  correctBalance,
  correctBalanceVariables,
} from "../../graphql/__generated__/correctBalance";
import { singleEditClientType } from "../../types";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";

export const useBalanceClientContentModal = (
  singleEditClientData: singleEditClientType
) => {
  const [balanceClient, setBalanceClient] = useState("");
  const [reasonBalanceClient, setReasonBalanceClient] = useState("");
  const { setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);

  const [
    controleBalanceClient,
    {
      data: controlBalanceData,
      loading: controlBalanceLoading,
      error: controlBalanceError,
    },
  ] = useMutation<correctBalance, correctBalanceVariables>(CORRECT_BALANCE);

  const setAmountBalanceClientHandler = () => {
    if (
      limitAmount(balanceClient) &&
      reasonBalanceClient &&
      balanceClient &&
      reasonBalanceClient.length < 255
    ) {
      controleBalanceClient({
        variables: {
          input: {
            Amount: Number(balanceClient),
            Reason: reasonBalanceClient,
            userID: singleEditClientData.id,
          },
        },
        refetchQueries: [
          {
            query: GET_USER,
            variables: { getUserInfo: { UserID: singleEditClientData.id } },
          },
        ],
      });
    } else {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(
        "Необходимо указать причину до 255 символов и сумму не более 10.000 и не менее -10.000"
      );
    }
  };

  useEffect(() => {
    if (controlBalanceError) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(`ошибка: ${controlBalanceError.message}`);
    }

    if (controlBalanceData && !controlBalanceError) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg(
        `Успех: Общая сумма ${controlBalanceData?.correctBalance.amount} ${controlBalanceData?.correctBalance.currency}`
      );
      setReasonBalanceClient("");
      setBalanceClient("");
    }
  }, [
    controlBalanceData,
    controlBalanceError,
    setNotificationEvent,
    setNotificationMsg,
  ]);

  return {
    setAmountBalanceClientHandler,
    balanceClient,
    setBalanceClient,
    reasonBalanceClient,
    setReasonBalanceClient,
    controlBalanceLoading,
  };
};
