import { useState } from "react";
import globalDataCTX from "./globalDataCTX";
import { notificationEventType } from "./types";

const GlobalDataContexPrvidertApp = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationMsg, setNotificationMsg] = useState<string>("");
  const [notificationEvent, setNotificationEvent] =
    useState<notificationEventType>(notificationEventType.noEvent);

  return (
    <div>
      <globalDataCTX.Provider
        value={{
          notificationEvent,
          setNotificationEvent,
          setLoading,
          loading,
          notificationMsg,
          setNotificationMsg,
        }}
      >
        {children}
      </globalDataCTX.Provider>
    </div>
  );
};

export default GlobalDataContexPrvidertApp;
