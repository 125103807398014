import React from "react";
import { StyledOutlinedInput } from "../../../client/styles";
import { Button, InputAdornment } from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {
  StyledSearchBlock,
  StyledSearchPanelWrapper,
} from "../../../../styles/common/styles";
import { SessionProps } from "./types";
import { acceptOnlyNumbers } from "../../../../utils/numbersUtils";
import IconComponent from "../../../common/icon";

const SessionSearchPanel = ({
  setSessionId,
  sessionId,
  getSesionDataHandler,
}: SessionProps) => {
  const handlerSearchText = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSessionId(event.target.value);
  };
  return (
    <StyledSearchPanelWrapper>
      <StyledSearchBlock>
        <StyledOutlinedInput
          placeholder="Поиск по session ID"
          onChange={(event) => handlerSearchText(event)}
          value={sessionId}
          onInput={acceptOnlyNumbers}
          endAdornment={
            <InputAdornment position="end">
              <IconComponent icon={faMagnifyingGlass} />
            </InputAdornment>
          }
        />
        <Button variant="contained" color="info" onClick={getSesionDataHandler}>
          Поиск
        </Button>
      </StyledSearchBlock>
    </StyledSearchPanelWrapper>
  );
};

export default SessionSearchPanel;
