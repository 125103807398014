import { TableCell, tableCellClasses, styled } from "@mui/material";
import { ReviewStatus } from "../../../globalTypes";
import { $colorVariables } from "../../../styles/colorVariables";

export const StyledTableCellBody = styled(TableCell)<any>(({ indikator }) => ({
  width: "auto",
  maxWidth: "480px",

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: $colorVariables.$blue07,
    color: "red",
  },
  borderLeft:
    indikator === ReviewStatus.PUBLISHED
      ? `8px solid ${$colorVariables.$greenTransparent}`
      : indikator === ReviewStatus.DELETED
      ? `8px solid ${$colorVariables.$redTransparent}`
      : indikator === ReviewStatus.ON_MODERATION
      ? `8px solid #FFDE21`
      : "",
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },

  [`&.MuiTableRow-root`]: {
    borderCollapse: "inherit !important",
  },
}));
