import React from "react";
import PromoCodeSearchPanel from "./components/promoCodeSearchPanel";
import PromoCodeTabel from "./components/promoCodeTabel";
import { Box } from "@mui/material";
import { usePromoCodes } from "./usePromoCodes";
import { HeaderTitle } from "../../styles/common/styles";
import StatusSearchBar from "./components/statusSearchBar";

const PromoCodes = () => {
  const {
    searchPromoByName,
    setSearchPromoByName,
    dataPromos,
    statusFilter,
    getFilteredPromos,
    generateFile,
    handleChangeStatusBar,
    resetFilterForPromo,
  } = usePromoCodes();

  return (
    <>
      <Box>
        <HeaderTitle>Промо</HeaderTitle>
        <StatusSearchBar
          handleChangeStatusBar={handleChangeStatusBar}
          statusFilter={statusFilter}
        />
        <PromoCodeSearchPanel
          searchPromoByName={searchPromoByName}
          setSearchPromoByName={setSearchPromoByName}
          getFilteredPromos={getFilteredPromos}
          resetFilterForPromo={resetFilterForPromo}
        />
        <PromoCodeTabel dataPromos={dataPromos} generateFile={generateFile} />
      </Box>
    </>
  );
};

export default PromoCodes;
