import { gql } from "@apollo/client";

export const GET_ALL_IPS_FROM_WHITE_LIST = gql`
  query getWhiteListIPs(
    $limit: Int
    $page: Int
    $sorting: SortParameters
    $filter: GetWhiteListIPFilter!
  ) {
    getWhiteListIPs(
      limit: $limit
      page: $page
      sorting: $sorting
      filter: $filter
    ) {
      ips {
        id
        ip
        reason
        allowedAccountCount
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      pagination {
        page
        perPage
        totalPages
        totalCount
      }
    }
  }
`;
