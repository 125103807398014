import { WeekView } from "@devexpress/dx-react-scheduler-material-ui";
import { alpha, Box, styled } from "@mui/material";
import { $colorVariables } from "../../../../styles/colorVariables";
import { classes } from "../../scheduler/constants";

export const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(
  ({ theme }) => ({
    [`&.${classes.todayCell}`]: {
      height: "30px",
      backgroundColor: alpha($colorVariables.$blue02, 0.1),
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.14),
      },
      "&:focus": {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
    },
    [`&.${classes.weekendCell}`]: {
      "&:hover": {
        backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
      },
      "&:focus": {
        backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
      },
    },
  })
);

export const StyledCurrentHourLine = styled(Box)({
  fontSize: "9px",
  marginLeft: "5px",
  color: $colorVariables.$blue04,
});
