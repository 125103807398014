import React, { FC } from "react";
import { Button, TableBody, TableRow } from "@mui/material";
import { StyledTableCellBody, StyledTableStatusBlock } from "./styles";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import PromoCodeMenuButton from "../promoCodeMenuButton";
import { PromoCodeTableBodyProps } from "./types";
import moment from "moment";
import IconComponent from "../../../../../common/icon";
import { useNavigate } from "react-router-dom";

const PromoCodeTableBody: FC<PromoCodeTableBodyProps> = ({
  dataPromos,
  generateFile,
}) => {
  const navigate = useNavigate();

  return (
    <TableBody>
      {dataPromos?.getPromos?.map((promoData, idx) => {
        return (
          <TableRow
            hover
            key={idx}
            onClick={() => {
              navigate(`${promoData?.id}`, {
                state: { promoName: promoData?.name },
              });
            }}
            sx={{ cursor: "pointer" }}
          >
            <StyledTableCellBody indikator={promoData?.status}>{promoData?.name}</StyledTableCellBody>

            <StyledTableCellBody>{promoData?.bonusName}</StyledTableCellBody>

            <StyledTableCellBody>
              <StyledTableStatusBlock status={promoData?.status}>
                {promoData?.status.toUpperCase()}
              </StyledTableStatusBlock>
            </StyledTableCellBody>

            <StyledTableCellBody>
              {moment(promoData?.createdAt).format("MMM DD YYYY, HH:mm")}
            </StyledTableCellBody>

            <StyledTableCellBody>
              {moment(promoData?.startDate).format("MMM DD YYYY, HH:mm")}
            </StyledTableCellBody>

            <StyledTableCellBody>
              {moment(promoData?.endDate).format("MMM DD YYYY, HH:mm")}
            </StyledTableCellBody>
            <StyledTableCellBody>
              {promoData?.generateCount}
            </StyledTableCellBody>

            <StyledTableCellBody>{promoData?.usageCount}</StyledTableCellBody>
            <StyledTableCellBody>
              {promoData?.redeemAbility}
            </StyledTableCellBody>

            <StyledTableCellBody>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  navigate("promo-create-update", {
                    state: {
                      promoDataForUpdate: promoData,
                    },
                  });
                }}
                disabled={promoData?.status === "deleted" ? true : false}
                startIcon={
                  <IconComponent
                    icon={faPenToSquare}
                    style={{
                      color: ` ${
                        promoData?.status === "deleted" && "#0288d1"
                      } `,
                      cursor: "pointer",
                    }}
                  />
                }
              >
                обновить
              </Button>
            </StyledTableCellBody>

            <StyledTableCellBody>
              <PromoCodeMenuButton
                bonusID={promoData?.bonusID}
                promoId={promoData?.id}
              />
            </StyledTableCellBody>

            <StyledTableCellBody>
              <Button
                variant="contained"
                onClick={(event) => {
                  event.stopPropagation();
                  generateFile(promoData?.id);
                }}
              >
                Загрузить .csv
              </Button>
            </StyledTableCellBody>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default PromoCodeTableBody;
