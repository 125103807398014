import moment from "moment";
import { useContext } from "react";
import { ReviewsActionTypes } from "../../../../../contexts/reviewsConext/types";
import ReviewsCTX from "../../../../../contexts/reviewsConext/ReviewsCTX";

export const useDataPicker = () => {
  const {
    dispatch,
    state: { dateFilter },
  } = useContext(ReviewsCTX);

  const handleChange = (
    actionTypes:
      | ReviewsActionTypes.EARLIER_THAN
      | ReviewsActionTypes.LATER_THAN,
    value: any
  ) => {
    const formatDate = new Date(value).getTime() / 1000;

    dispatch({
      type: actionTypes,
      payload: formatDate,
    });
  };

  const changeLaterThanDate = dateFilter?.laterThan
    ? moment.unix(dateFilter?.laterThan).format()
    : null;

  const changeEarlierThanDate = dateFilter?.earlierThan
    ? moment.unix(dateFilter?.earlierThan).format()
    : null;

  return { handleChange, changeLaterThanDate, changeEarlierThanDate };
};
