import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { PromoCodeMenuButtonProps } from "./types";
import { usePromoCodeMenuButton } from "./usePromoCodeMenuButton";
import { PromoActionsStatus } from "../../../../../../globalTypes";

const PromoCodeMenuButton: React.FC<PromoCodeMenuButtonProps> = ({
  promoId,
}) => {
  const { handleClick, handleClose, changeStatusHandler, open, anchorEl } =
    usePromoCodeMenuButton();

  return (
    <>
      <Button
        variant="contained"
        color="info"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Изм. статус
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(event) =>
            changeStatusHandler(promoId, PromoActionsStatus.active, event)
          }
        >
          Активировать
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            changeStatusHandler(promoId, PromoActionsStatus.inactive, event)
          }
        >
          Деактивировать
        </MenuItem>
      </Menu>
    </>
  );
};

export default PromoCodeMenuButton;
