import React, { FC } from "react";
import { InputLabel, MenuItem, FormControl } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { GenderDateSelectProps } from "./types";
import { faVenusMars } from "@fortawesome/free-solid-svg-icons";
import { Gender } from "../../../../../../../globalTypes";
import IconComponent from "../../../../../../common/icon";

const GenderDateSelect: FC<GenderDateSelectProps> = ({
  setFieldValue,
  valuesKey,
  initialValue,
}) => {
  const handleChange = (event: SelectChangeEvent<Gender>) => {
    setFieldValue(valuesKey, event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">
        Пол эксперта{" "}
        <>
          <IconComponent icon={faVenusMars} />
        </>
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={initialValue}
        label={
          <>
            Telegram имя <IconComponent icon={faVenusMars} />
          </>
        }
        onChange={handleChange}
      >
        <MenuItem value={Gender.FEMALE}>{Gender.FEMALE}</MenuItem>
        <MenuItem value={Gender.MALE}>{Gender.MALE}</MenuItem>
      </Select>
    </FormControl>
  );
};
export default GenderDateSelect;
