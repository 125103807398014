import { ApolloQueryResult } from "@apollo/client";
import { useState } from "react";
import {
  getWhiteListIPsVariables,
  getWhiteListIPs,
} from "../../graphql/__generated__/getWhiteListIPs";
import { useKeyboardEvent } from "../../../../utils/useKeyboard";

export const useSearchBar = (
  refetchGetIpsData: (
    variables?: Partial<getWhiteListIPsVariables> | undefined
  ) => Promise<ApolloQueryResult<getWhiteListIPs>>
) => {
  const [searchIp, setSearchIp] = useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const resetForm = () => {
    setSearchIp("");
    setDateFrom(null);
    setDateTo(null);
  };

  const searchIpsHandler = () => {
    refetchGetIpsData({
      filter: {
        ip: searchIp || null,
        dateFrom,
        dateTo,
      },
    });
  };

  const onChangeInputsHandler = (inputType: string, event: any) => {
    if (inputType === "dateFrom") {
      setDateFrom(event);
    } else {
      setDateTo(event);
    }
  };

  useKeyboardEvent("Enter", searchIpsHandler);
  useKeyboardEvent("Escape", resetForm);

  return {
    searchIp,
    setSearchIp,
    setDateFrom,
    dateFrom,
    dateTo,
    searchIpsHandler,
    onChangeInputsHandler,
    resetForm,
  };
};
