import { useContext } from "react";
import ReviewsCTX from "../../../../contexts/reviewsConext/ReviewsCTX";
import { ReviewsActionTypes } from "../../../../contexts/reviewsConext/types";
import { ReviewStatus } from "../../../../globalTypes";

export const useTabPanel = () => {
  const {
    dispatch,
    state: { statusFilter },
  } = useContext(ReviewsCTX);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: ReviewStatus | number
  ) => {
    dispatch({
      type: ReviewsActionTypes.FILTER_STATUS,
      payload: { status: newValue === 0 ? [] : [newValue] },
    });
  };

  return {
    handleChange,
    statusFilter,
  };
};
