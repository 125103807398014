import { useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { RedeemAbility } from "../../../../globalTypes";
import { CREATE_PROMO } from "../../graphql/CREATE_PROMO.graphql";
import {
  createPromo,
  createPromoVariables,
} from "../../graphql/__generated__/createPromo";
import { GET_PROMOS } from "../../graphql/GET_PROMOS.graphql";
import { UPDATE_PROMO } from "../../graphql/UPDATE_PROMO.graphql";
import {
  updatedPromo,
  updatedPromoVariables,
} from "../../graphql/__generated__/updatedPromo";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";
import { useLocation } from "react-router-dom";
import {
  getBonuses,
  getBonusesVariables,
} from "../../../bonuses/graphql/__generated__/getBonuses";
import { GET_BONUSES } from "../../../bonuses/graphql/GET_BONUSES.graphql";

export const useSubPageCreateUpdatePromo = () => {
  const { state } = useLocation();
  const isUpdatePromo = !!state?.promoDataForUpdate;
  const promoDataForUpdate = state?.promoDataForUpdate;
  const { setLoading, setNotificationEvent, setNotificationMsg } =
    useContext(globalDataCTX);

  const [
    createPromoHandler,
    {
      data: createPromoData,
      loading: createPromoLoading,
      error: createPromoError,
    },
  ] = useMutation<createPromo, createPromoVariables>(CREATE_PROMO);

  const { data: dataBonuses } = useQuery<getBonuses, getBonusesVariables>(
    GET_BONUSES,
    { variables: { input: {} } }
  );

  const [
    updatePromoHandler,
    {
      data: updatePromoData,
      loading: updatePromoLoading,
      error: updatePromoError,
    },
  ] = useMutation<updatedPromo, updatedPromoVariables>(UPDATE_PROMO);

  const submitHandler = (values: any) => {
    // types UpdatePromo || NewPromo
    !isUpdatePromo
      ? createPromoHandler({
          variables: { input: values },
          refetchQueries: [
            {
              query: GET_PROMOS,
              variables: { input: {} },
            },
          ],
        })
      : updatePromoHandler({
          variables: { input: values },
          refetchQueries: [
            {
              query: GET_PROMOS,
              variables: { input: {} },
            },
          ],
        });
  };

  useEffect(() => {
    setLoading(createPromoLoading || updatePromoLoading);

    if (createPromoError || updatePromoError) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(
        createPromoError?.message || updatePromoError?.message || ""
      );
    } else if (createPromoData || updatePromoData) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg(
        `Промо успешно ${createPromoData ? "создано" : "обновлен"}` || ""
      );
    } else {
      setNotificationEvent(notificationEventType.noEvent);
    }
  }, [
    createPromoData,
    createPromoError,
    createPromoLoading,
    setLoading,
    setNotificationEvent,
    setNotificationMsg,
    updatePromoData,
    updatePromoError,
    updatePromoLoading,
  ]);

  const initialValues = isUpdatePromo
    ? {
        promoID: promoDataForUpdate.id,
        name: promoDataForUpdate?.name || "",
        startDate: new Date(promoDataForUpdate?.startDate) || "",
        endDate: new Date(promoDataForUpdate?.endDate) || "",
      }
    : {
        name: "",
        bonusID: "",
        redeemAbility: RedeemAbility.one_time || RedeemAbility.one_per_user,
        generateCount: null,
        codeLength: 0,
        startDate: "",
        endDate: "",
        generatePattern: "",
      };

  return {
    isUpdatePromo,
    submitHandler,
    initialValues,
    dataBonuses,
    promoDataForUpdate,
  };
};
