import { Button, styled } from "@mui/material";
import { $colorVariables } from "../../../styles/colorVariables";

export const StyledDoneButton = styled(Button)({
  backgroundColor: $colorVariables.$green01,
  height: "40px",
  borderRadius: "12px",
  "&:hover": {
    backgroundColor: $colorVariables.$green01,
  },
});

export const StyledDeletButton = styled(StyledDoneButton)({
  backgroundColor: $colorVariables.$red,
  "&:hover": {
    backgroundColor: $colorVariables.$red,
  },
});

export const StyledDoneLongButton = styled(Button)({
  height: "40px",
  borderRadius: "12px",
  minWidth: "130px",
  backgroundColor: $colorVariables.$greenTransparent,
  "&:hover": {
    backgroundColor: $colorVariables.$greenTransparent,
  },
});

export const StyledDeletLongButton = styled(Button)({
  height: "40px",
  borderRadius: "12px",
  minWidth: "130px",
  backgroundColor: $colorVariables.$redTransparent,
  "&:hover": {
    backgroundColor: $colorVariables.$redTransparent,
  },
});

export const StyledEditButton = styled(Button)({
  color: $colorVariables.$blue02,
  fontWeight: "600",
  fontSize: "13px",
  lineHeight: "120%",
  textTransform: "initial",
});
