import { gql } from "@apollo/client";

export const GET_ALL_EXPERTS_DATA = gql`
  query getAllExpertsData(
    $after: String
    $first: Int
    $filter: ExpertFilter
    $sorting: SortParameters
  ) {
    getExperts(
      after: $after
      first: $first
      filter: $filter
      sorting: $sorting
    ) {
      edges {
        node {
          id
          uuid
          name
          image(size: 100) {
            onex
          }
        }
      }
    }
  }
`;
