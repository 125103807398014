import React from "react";
import BackButton from "../../../common/BackButton";
import CreateUpdateCashBackForm from "../../components/createUpdateCashBackForm";

const CreateUpdateCashBack = () => {
  return (
    <>
      <BackButton />
      <CreateUpdateCashBackForm />
    </>
  );
};

export default CreateUpdateCashBack;
