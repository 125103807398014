import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <>
      <Box sx={{ width: "100%", position: "absolute", top: 3, left: 0, right: 0 }} >
        <LinearProgress color="info" />
      </Box>
      
      <CircularProgress color="info" variant="indeterminate" size={50} sx={{ position: "fixed", right: "20px", top: "20px" }} />
    </>
  );
};

export default Loader;
