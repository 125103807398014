import { Avatar, Box } from "@mui/material";
import React, { FC } from "react";
import { StylesDescription } from "./styles";
import { HeaderSchedulePagePropsProps } from "./types";
import { HeaderTitle } from "../../../../styles/common/styles";

const HeaderSchedulePage: FC<HeaderSchedulePagePropsProps> = ({
  expertName,
  avatar,
}) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <HeaderTitle>{expertName}</HeaderTitle>
        <Avatar src={avatar} variant="rounded" />
      </Box>
      <StylesDescription>Расписание</StylesDescription>
    </>
  );
};

export default HeaderSchedulePage;
