import { gql } from "@apollo/client";

export const GET_PAYMENT_FORM_TEMPLATES = gql`
  query getPaymentFormTemplates($filter: PaymentFormTemplatesFilter!) {
    getPaymentFormTemplates(filter: $filter) {
      ID
      type
      recommendedSumm
      buttons {
        id
        paymentFormID
        buttonType
        minutesAmount
        moneyAmount
      }
      description
    }
  }
`;
