import { WeekView } from "@devexpress/dx-react-scheduler";
import moment from "moment";
import { classes } from "../../scheduler/constants";
import { StyledCurrentHourLine, StyledWeekViewTimeTableCell } from "./styles";
import { Skeleton } from "@mui/material";

type TimeTableCellPropsWithIsToday = WeekView.TimeTableCellProps & {
  isLoading?: boolean;
};

const TimeTableCell = ({
  onDoubleClick,
  isLoading,
  ...restProps
}: TimeTableCellPropsWithIsToday) => {
  return (
    <StyledWeekViewTimeTableCell
      {...restProps}
      children={
        <>
          {isLoading ? (
            <Skeleton
              sx={{ bgcolor: "rgba(25, 118, 210, 0.16)" }}
              animation="wave"
              variant="rectangular"
              height={"28px"}
            />
          ) : (
            <StyledCurrentHourLine>
              {!isLoading && moment(restProps.startDate).format("HH:mm")}
            </StyledCurrentHourLine>
          )}
        </>
      }
      className={classes.todayCell}
      onDoubleClick={onDoubleClick}
    />
  );
};

export default TimeTableCell;
