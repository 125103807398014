import React, { FC } from "react";
import { StyledOutlinedInput, StyledSearchBlock } from "./styles";
import { Button, InputAdornment } from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { PromoCodeSearchPanelProps } from "./types";
import { StyledSearchPanelWrapper } from "../../../../styles/common/styles";
import IconComponent from "../../../common/icon";
import { useNavigate } from "react-router-dom";

const PromoCodeSearchPanel: FC<PromoCodeSearchPanelProps> = ({
  searchPromoByName,
  setSearchPromoByName,
  getFilteredPromos,
  resetFilterForPromo,
}) => {
  const navigate = useNavigate();

  const handlerSearchText = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchPromoByName(event.target.value);
  };

  return (
    <StyledSearchPanelWrapper>
      <StyledSearchBlock>
        <StyledOutlinedInput
          placeholder="Поиск по названию промокода"
          onChange={handlerSearchText}
          value={searchPromoByName}
          endAdornment={
            <InputAdornment position="end">
              <IconComponent icon={faMagnifyingGlass} />
            </InputAdornment>
          }
        />
        <Button variant="contained" color="info" onClick={resetFilterForPromo}>
          Сбросить
        </Button>
        <Button variant="contained" color="info" onClick={getFilteredPromos}>
          Поиск
        </Button>
      </StyledSearchBlock>
      <Button
        variant="contained"
        color="info"
        onClick={() => navigate("promo-create-update")}
      >
        + Добавить
      </Button>
    </StyledSearchPanelWrapper>
  );
};

export default PromoCodeSearchPanel;
