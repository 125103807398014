import React, { FC } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Button, TextField } from "@mui/material";
import { SearchReportPanelProps } from "./types";
import dayjs, { Dayjs } from "dayjs";

const SearchReportPanel: FC<SearchReportPanelProps> = ({
  setDate,
  reportSearchDate,
  tabIndex,
  placeholder = "YYYY-MM-DD",
  getReportsHandler,
  sendReportHandler,
}) => {
  return (
    <Box component={"div"} sx={{ mt: 3, display: "flex", gap: 2 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          inputFormat={placeholder}
          label="Дата отчета "
          value={reportSearchDate || null}
          onChange={(e: Dayjs | null) => setDate(dayjs(e).format("YYYY-MM-DD"))}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      <Button onClick={getReportsHandler} variant="contained" size="medium">
        {tabIndex === 0 ? "Сформировать Отчет" : "Получить отчет"}
      </Button>

      <Button onClick={sendReportHandler} variant="contained" size="medium">
        Отправить отчет в Эмбрию
      </Button>
    </Box>
  );
};

export default SearchReportPanel;
