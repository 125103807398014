import React from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { InputAdornment, Button, Box, TextField } from "@mui/material";
import { StyledOutlinedInput } from "../../../client/styles";
import IconComponent from "../../../common/icon";
import { StyledWrapperExports } from "../../../experts/exportNav/styles";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import DataPickerIcon from "../../../../assets/icons/DataPickerIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Dayjs } from "dayjs";
import { useSearchBar } from "./useSearchBar";
import { SearchBarProps } from "./types";

const SearchBar = ({ refetchGetIpsData }: SearchBarProps) => {
  const navigate = useNavigate();
  const {
    searchIp,
    setSearchIp,
    dateFrom,
    dateTo,
    searchIpsHandler,
    onChangeInputsHandler,
    resetForm,
  } = useSearchBar(refetchGetIpsData);

  return (
    <StyledWrapperExports direction="row">
      <Box display="flex" gap={1}>
        <StyledOutlinedInput
          placeholder="Поиск по IP"
          value={searchIp}
          onChange={(event) => setSearchIp(event.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconComponent icon={faMagnifyingGlass} />
            </InputAdornment>
          }
        />
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              components={{
                OpenPickerIcon: DataPickerIcon,
              }}
              inputFormat="DD.MM.YYYY"
              value={dateFrom}
              onChange={(event: Dayjs | null) =>
                onChangeInputsHandler("dateFrom", event)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    width: "160px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "12px 0px 0px 12px",
                    },
                  }}
                />
              )}
            />
            <DatePicker
              components={{
                OpenPickerIcon: DataPickerIcon,
              }}
              inputFormat="DD.MM.YYYY"
              value={dateTo}
              onChange={(event: Dayjs | null) =>
                onChangeInputsHandler("dateTo", event)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    width: "160px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0px 12px 12px 0px",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Button variant="contained" color="info" onClick={resetForm}>
          Сбросить
        </Button>
        <Button variant="contained" color="info" onClick={searchIpsHandler}>
          Поиск
        </Button>
      </Box>
      <Button
        variant="contained"
        color="info"
        onClick={() => navigate("add-ip")}
      >
        + Добавить ip в whitelist
      </Button>
    </StyledWrapperExports>
  );
};

export default SearchBar;
