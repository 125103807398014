import { $colorVariables } from "../../../styles/colorVariables";
import {
  faMessage,
  faUser,
  faCalendar,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCreditCard,
  faHotel,
  faMedal,
  faPhoneSlash,
  faTags,
  faUsers,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import IconComponent from "../../../components/common/icon";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AccountTreeIcon from '@mui/icons-material/AccountTree';

const NavigationData = [
  {
    title: "Эксперты",
    path: "experts",
    icon: <IconComponent icon={faUser} color={$colorVariables.$white} />,
  },
  {
    title: "Отзывы",
    path: "reviews",
    icon: <IconComponent icon={faMessage} color={$colorVariables.$white} />,
  },
  {
    title: "Расписание",
    path: "schedules",
    icon: <IconComponent icon={faCalendar} color={$colorVariables.$white} />,
  },
  {
    title: "Клиенты",
    path: "clients",
    icon: <IconComponent icon={faUsers} />,
  },
  {
    title: "Бонусы",
    path: "bonus",
    icon: <IconComponent icon={faMedal} color={$colorVariables.$white} />,
  },
  {
    title: "Промокоды",
    path: "promocode",
    icon: (
      <IconComponent icon={faTags} color={$colorVariables.$white} size="lg" />
    ),
  },
  {
    title: "Отчет",
    path: "report",
    icon: <IconComponent icon={faHotel} color={$colorVariables.$white} />,
  },
  {
    title: "Сессии",
    path: "session",
    icon: <IconComponent icon={faPhoneSlash} />,
  },
  {
    title: "Платежи",
    path: "payment",
    icon: <IconComponent icon={faCreditCard} />,
  },
  {
    title: `IP-адресa`,
    path: "ip-addresses",
    icon: <IconComponent icon={faClipboard} />,
  },
  {
    title: `Cashback`,
    path: "cashback",
    icon: <CurrencyExchangeIcon />,
  },
  {
    title: `Шаблоны`,
    path: "button-templates",
    icon: <AccountTreeIcon />,
  },
];

export default NavigationData;
