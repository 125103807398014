import React from "react";
import { HeaderTitle, StyledOutlinedInput } from "../../styles/common/styles";
import SearchPaymentPanel from "./components/searchPaymentPanel";
import { usePayment } from "./usePayment";
import PaymentCard from "./components/paymentCard";
import { Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import BasicModal from "../common/modal";

const Payment = () => {
  const {
    searchId,
    setSearchId,
    getPaymentsHandler,
    dataPayments,
    searchPaymentType,
    setSearchPaymentType,
    resetSearchFilters,
    paymentStatus,
    setPaymentStatus,
    paymentMethod,
    loadingPage,
    reasonRefundPayment,
    setReasonRefundPayment,
    setPaymentMethod,
    refundPaymentHandler,
    isOpenModal,
    setisOpenModal,
    closeModalHandle,
    setCurrentRefundPaymentId,
  } = usePayment();
  return (
    <>
      <HeaderTitle>Оплата</HeaderTitle>
      <SearchPaymentPanel
        searchId={searchId}
        setSearchId={setSearchId}
        getPaymentsHandler={getPaymentsHandler}
        searchPaymentType={searchPaymentType}
        setSearchPaymentType={setSearchPaymentType}
        resetSearchFilters={resetSearchFilters}
        paymentStatus={paymentStatus}
        setPaymentStatus={setPaymentStatus}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        loadingPage={loadingPage}
      />

      {dataPayments &&
        dataPayments?.getPayments?.payments.map((payment) => {
          return (
            <Box key={payment.id}>
              <PaymentCard
                paymentData={payment}
                loadingPage={loadingPage}
                setisOpenModal={setisOpenModal}
                setCurrentRefundPaymentId={setCurrentRefundPaymentId}
              />
            </Box>
          );
        })}
      {dataPayments && !dataPayments.getPayments.payments.length && (
        <Typography color={grey[400]}>
          У клиента нет истории платежей
        </Typography>
      )}
      {!dataPayments && (
        <Typography color={grey[400]}>Не выбрана оплата</Typography>
      )}

      <BasicModal handelClose={closeModalHandle} isOpen={isOpenModal} title="Причина">
        <Box>
          <StyledOutlinedInput
            placeholder="Причина"
            onChange={(event) => setReasonRefundPayment(event.target.value)}
            value={reasonRefundPayment}
          />
             <Button
          sx={{ ml: 2 }}
          variant="contained"
          color="error"
          onClick={() => {
            setisOpenModal(true);
            refundPaymentHandler();
          }}
        >
          Отменить транзакцию
        </Button>
        </Box>
      </BasicModal>
    </>
  );
};

export default Payment;
