import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ACTION_STATUS_PROMO } from "../../../../graphql/ACTION_STATUS_PROMO.graphql";
import {
  actionsPromo,
  actionsPromoVariables,
} from "../../../../graphql/__generated__/actionsPromo";
import { PromoActionsStatus } from "../../../../../../globalTypes";
import { GET_PROMOS } from "../../../../graphql/GET_PROMOS.graphql";

export const usePromoCodeMenuButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [changeStatusPromo] = useMutation<actionsPromo, actionsPromoVariables>(
    ACTION_STATUS_PROMO
  );

  const changeStatusHandler = (
    promoId: string,
    status: PromoActionsStatus,
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    event.stopPropagation();
    changeStatusPromo({
      variables: {
        promoID: promoId,
        status: status,
      },
      refetchQueries: [
        {
          query: GET_PROMOS,
          variables: { input: {} },
        },
      ],
    });
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    handleClick,
    handleClose,
    changeStatusHandler,
    open,
    anchorEl,
  };
};
