import ExpertsTableBody from "./expertsTableBody";
import ReviewsTableHead from "./expertsTableHead";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import { ExpertsNav } from "./exportNav/ExportsNav";
import { HeaderTitle } from "../../styles/common/styles";
import { useExportsTable } from "./useExpertsTable";

const ExpertsTable = () => {
  const { allExpertData, searchText, loading, setSearchText } =
    useExportsTable();

  return (
    <>
      <HeaderTitle>Эксперты</HeaderTitle>
      <ExpertsNav searchText={searchText} setSearchText={setSearchText} />
      <TableContainer
        component={Paper}
        sx={{
          borderTopRightRadius: "16px",
          borderTopLeftRadius: "16px",
          mt: "24px",
        }}
      >
        <Table
          aria-label="customized table"
          size="medium"
          sx={{ borderCollapse: "inherit !important" }}
        >
          <ReviewsTableHead loadingExperts={loading} />
          <TableBody>
            <ExpertsTableBody
              allExpertData={allExpertData ? allExpertData : []}
              loadingExperts={loading}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ExpertsTable;
