import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { $colorVariables } from "../../../../styles/colorVariables";

export const StylesDescription = styled(Typography)({
  color: $colorVariables.$blue01,
  fontSize: 16,
  marginBottom: "15px",
  fontWeight: "600",
});
