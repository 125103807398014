import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Button, CardActionArea, CardActions, TextField } from "@mui/material";
import { useAddIpToWhiteList } from "./useAddIpToWhiteList";
import { Enum_inputs } from "./types";

const CardAddIp = () => {
  const {
    onChangeInputsHandler,
    addIpToWhiteListHandler,
    updateIpDataHandler,
    isDisabledButton,
    allowedAccountCount,
    reason,
    ip,
    isUpdateFlag,
  } = useAddIpToWhiteList();

  return (
    <Card sx={{ maxWidth: "70%" }}>
      <CardActionArea>
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            value={allowedAccountCount}
            label="Количество разрешённых аккаунтов"
            placeholder="Количество разрешённых аккаунтов"
            variant="outlined"
            onChange={(event) =>
              onChangeInputsHandler(event, Enum_inputs.allowed_account_count)
            }
          />
          <TextField
            value={reason}
            label="Причина"
            placeholder="Причина"
            variant="outlined"
            onChange={(event) =>
              onChangeInputsHandler(event, Enum_inputs.reason)
            }
          />
          <TextField
            type="text"
            value={ip}
            label="IP"
            placeholder="IP"
            variant="outlined"
            onChange={(event) => onChangeInputsHandler(event, Enum_inputs.ip)}
            disabled={isUpdateFlag}
          />
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ float: "right", marginRight: 1 }}>
        <Button
          size="medium"
          variant="contained"
          onClick={isUpdateFlag ? updateIpDataHandler : addIpToWhiteListHandler}
          disabled={!isDisabledButton}
        >
          {`${isUpdateFlag ? "Обновить" : "Добавить"} ip в whitelist`}
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardAddIp;
