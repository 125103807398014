import { gql } from "@apollo/client";

export const GET_USER = gql`
  query getUserInfo($getUserInfo: getUserInfoInput!) {
    getUserInfo(input: $getUserInfo) {
      user {
        id
        uuid
        name
        freeMinutesLeft
        loginType
        image(size: 100) {
          onex
          twox
          threex
        }
        isDeleted
      }
      balance {
        ... on Money {
          amount
          currency
        }
      }
      HasActiveSession
      blocked
    }
  }
`;
