export enum Enum_inputs {
  reason = "reason",
  allowed_account_count = "allowed_account_count",
  ip = "ip",
}

export type ipDataForUpdateType = {
  allowedAccountCount: string;
  id: string;
  ip: string;
  reason: string;
};
