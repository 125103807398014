import { gql } from "@apollo/client";

export const GET_PROMO_CODES = gql`
  query getPromoCodes($input: PromoCodeFilter!) {
    getPromoCodes(filter: $input) {
      id
      promoID
      userID
      code
      createdAt
    }
  }
`;
