import { TableCell } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ExpertActivityStatus, ExpertDisplayStatus } from "../../../globalTypes";
import { $colorVariables } from "../../../styles/colorVariables";

export const StyledTableCellBody = styled(TableCell)<any>(({ status }) => ({
  maxWidth: "200px",
  minWidth: "100px",

  color:
    status === ExpertDisplayStatus.visible_for_admin || status ===  ExpertActivityStatus.inactive
      ? `${$colorVariables.$yellow01}`
      : status === ExpertDisplayStatus.visible_false  || status ===  "deleted"
      ? `${$colorVariables.$red}`
      : status === ExpertDisplayStatus.visible_for_all || status ===  ExpertActivityStatus.active
      ? `${$colorVariables.$green01}`
      : "",
  fontWeight: status ? 900 : "",

  [`&.MuiTableRow-root`]: {
    borderCollapse: "inherit !important",
  },
}));
