import React, { FC } from "react";
import { Avatar, Box, Button } from "@mui/material";
import { FreeMinutesContentModalProps } from "./types";
import { StyledOutlinedInput } from "../../styles";
import GroupAvatars from "../groupAvatars/GroupAvatars";
import { TypeInputNameEnum, editTypesEnum } from "../../types";
import { acceptOnlyNumbers } from "../../../../utils/numbersUtils";
import { filteredText } from "../../utils";

const FreeMinutesContentModal: FC<FreeMinutesContentModalProps> = ({
  setReason,
  setAmount,
  setAmountHandler,
  reason,
  amount,
  userImage,
  userData,
  openModalName,
}) => {
  const changeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: TypeInputNameEnum
  ) => {
    const inputText = event.target.value;
    if (type === TypeInputNameEnum.REASON) {
      setReason(filteredText(inputText));
    } else {
      setAmount(inputText);
    }
  };

  const isNegativeNumber = amount.trim().slice(0, 1) === "-";

  return (
    <>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <StyledOutlinedInput
            placeholder="Опишите причину"
            onChange={(e) => changeHandler(e, TypeInputNameEnum.REASON)}
            value={reason}
          />
          <StyledOutlinedInput
            placeholder="Количество минут"
            onChange={(e) => changeHandler(e, TypeInputNameEnum.AMOUNT)}
            value={amount}
            onInput={acceptOnlyNumbers}
          />
          <Button
            disabled={!(reason && amount)}
            variant="contained"
            onClick={setAmountHandler}
          >
            {isNegativeNumber ? "Снимать" : "Добавить"}
          </Button>
        </Box>
        {openModalName === editTypesEnum.SOME_EDIT ? (
          <GroupAvatars selectedUsers={userData} />
        ) : (
          <Avatar src={userImage} />
        )}
      </Box>
    </>
  );
};

export default FreeMinutesContentModal;
