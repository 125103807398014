import React, { FC } from "react";
import { Table } from "@mui/material";
import PromoCodeTableHeader from "./components/promoCodeTableHeader";
import PromoCodeTableBody from "./components/promoCodeTableBody";
import { PromoCodeTabelProps } from "./types";
import { StyledTableContainer } from "../../../../styles/common/styles";

const PromoCodeTabel: FC<PromoCodeTabelProps> = ({
  dataPromos,
  generateFile,
}) => {
  return (
    <StyledTableContainer>
      <Table
        aria-label="customized table"
        size="medium"
        sx={{ borderCollapse: "inherit !important" }}
      >
        <PromoCodeTableHeader />
        <PromoCodeTableBody
          dataPromos={dataPromos}
          generateFile={generateFile}
        />
      </Table>
    </StyledTableContainer>
  );
};

export default PromoCodeTabel;
