import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getPayments,
  getPaymentsVariables,
} from "./graphql/__generated__/getPayments";
import { useKeyboardEvent } from "../../utils/useKeyboard";
import {
  OrderEnum,
  PaymentMethod,
  PaymentProvider,
  PaymentState,
  SortByEnum,
} from "../../globalTypes";
import { REFUND_PAYMENTS } from "./graphql/REFUND_PAYMENT.graphql";
import {
  refundPayment,
  refundPaymentVariables,
} from "./graphql/__generated__/refundPayment";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../contexts/globalContext/types";
import { GET_PAYMENTS } from "./graphql/GET_PAYMENTS.graphql";

export const usePayment = () => {
  const [searchId, setSearchId] = useState("");
  const [currentRefundPaymentId, setCurrentRefundPaymentId] =
    useState<number>(0);

  const [reasonRefundPayment, setReasonRefundPayment] = useState("");
  const [isOpenModal, setisOpenModal] = useState(false);
  const { setNotificationEvent, setNotificationMsg, setLoading } =
    useContext(globalDataCTX);
  const [paymentStatus, setPaymentStatus] = useState<PaymentState | null>(
    PaymentState.Success
  );
  const [searchPaymentType, setSearchPaymentType] =
    useState<null | PaymentProvider>(PaymentProvider.SomPayments);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    null
  );

  const [
    getPaymentsQuery,
    { data: dataPayments, error: errorPayments, loading: loadingPayments },
  ] = useLazyQuery<getPayments, getPaymentsVariables>(GET_PAYMENTS, {
    fetchPolicy: "no-cache",
    variables: {
      sorting: {
        sortBy: SortByEnum.id,
        order: OrderEnum.desc,
      },
    },
  });

  const [
    refundPaymentMutation,
    { data: refundData, error: refundError, loading: refundLoading },
  ] = useMutation<refundPayment, refundPaymentVariables>(REFUND_PAYMENTS);

  const getPaymentsHandler = () => {
    getPaymentsQuery({
      variables: {
        params: {
          userID: !!searchId ? Number(searchId) : null,
          provider: searchPaymentType,
          state: paymentStatus,
          method: paymentMethod,
        },
      },
    });
  };

  useEffect(() => {
    const loading = refundLoading || loadingPayments;
    setLoading(loading);

    switch (true) {
      case refundData && !refundError && !refundLoading:
        setNotificationEvent(notificationEventType.success);
        setNotificationMsg("успех");
        break;
      case !!errorPayments || !!refundError:
        setNotificationEvent(notificationEventType.error);
        setNotificationMsg(
          `Что-то пошло не так ${refundError?.message || refundError?.message}`
        );
        break;

      default:
        break;
    }
  }, [
    dataPayments,
    errorPayments,
    loadingPayments,
    refundData,
    refundError,
    refundLoading,
    setLoading,
    setNotificationEvent,
    setNotificationMsg,
  ]);

  const resetSearchFilters = () => {
    setSearchId("");
    setSearchPaymentType(null);
    setPaymentStatus(null);
    setPaymentMethod(null);
  };

  const closeModalHandle = () => {
    setisOpenModal(false);
    setReasonRefundPayment("");
  };

  const refundPaymentHandler = () => {
    refundPaymentMutation({
      variables: {
        paymentId: currentRefundPaymentId,
        reason: reasonRefundPayment,
      },
    });
    setTimeout(() => {
      getPaymentsQuery({
        variables: {
          params: {
            userID: !!searchId ? Number(searchId) : null,
            provider: searchPaymentType,
            state: paymentStatus,
            method: paymentMethod,
          },
        },
      });
    }, 2000);
    closeModalHandle();
  };

  useKeyboardEvent("Enter", getPaymentsHandler);

  return {
    loadingPage: refundLoading || loadingPayments,
    searchId,
    dataPayments,
    searchPaymentType,
    paymentStatus,
    paymentMethod,
    reasonRefundPayment,
    isOpenModal,
    setisOpenModal,
    setReasonRefundPayment,
    setSearchId,
    getPaymentsHandler,
    setSearchPaymentType,
    resetSearchFilters,
    setPaymentStatus,
    setPaymentMethod,
    refundPaymentHandler,
    closeModalHandle,
    setCurrentRefundPaymentId,
  };
};
