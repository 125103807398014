import { Box, Stack, Typography } from "@mui/material";
import { FC } from "react";
import NavLink from "../navLink";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { NavSectionProps } from "./types";
import { useNavSection } from "./useNavSection";
import { StyledLogOutButton, StyledNavSectionContainer } from "./styles";
import { $colorVariables } from "../../../../../styles/colorVariables";
import IconComponent from "../../../../../components/common/icon";

const NavSection: FC<NavSectionProps> = ({ data }) => {
  const { isUserLoggedIn, logOutHandler } = useNavSection();

  return (
    <StyledNavSectionContainer>
      <Box>
        {data.map((item) => (
          <Stack key={item.title} height={"74px"}>
            <NavLink to={item.path} key={item.title}>
              <Typography>{item.icon}</Typography>
              <Typography>{item.title}</Typography>
            </NavLink>
          </Stack>
        ))}
      </Box>

      {isUserLoggedIn && (
        <StyledLogOutButton onClick={logOutHandler}>
          <IconComponent
            icon={faArrowRightFromBracket}
            width={"20px"}
            height={"20px"}
            color={$colorVariables.$white}
          />
          <Typography color={$colorVariables.$white}>Выйти</Typography>
        </StyledLogOutButton>
      )}
    </StyledNavSectionContainer>
  );
};

export default NavSection;
