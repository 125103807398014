import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent, TextField } from "@mui/material";
import { selectIntervalCountData } from "./constants";
import { selectIntervalCountDataProps } from "./types";

const SelectIntervalCount = ({
  selectIntervalCount,
  setSelectIntervalCount,
}: selectIntervalCountDataProps) => {
  return (
    <TextField
      select
      defaultChecked
      label="Интервал"
      required
      SelectProps={{
        value: selectIntervalCount,
        onChange: (event: SelectChangeEvent<unknown>) =>
          setSelectIntervalCount(event.target.value as number),
      }}
      sx={{
        mb: 2,
        ml: 2,
        minWidth: "200px",
      }}
      children={selectIntervalCountData.map((item: number) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    />
  );
};

export default SelectIntervalCount;
