import { useState } from "react";

export const useAuthorUserOptions = () => {

     const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
     const open = Boolean(anchorEl);

     const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
       setAnchorEl(event.currentTarget);
     };
     const handleClose = () => {
       setAnchorEl(null);
     };

    return {
      handleClick,
      handleClose,
      open,
      anchorEl,
    };
}







