import React from "react";
import { Box } from "@mui/material";
import { HeaderTitle } from "../../styles/common/styles";
import SearchBar from "./components/searchBar";
import { useIpAddresses } from "./useIpAddresses";
import IpsListComponent from "./components/ipsListComponent";
import PaginationExample from "../common/pagination";

const IpAddresses = () => {
  const {
    dataIpsFromWhiteList,
    refetchGetIpsData,
    setPage,
    totalPages,
    page,
    isLoading,
  } = useIpAddresses();

  return (
    <Box>
      <HeaderTitle>IP-адресa</HeaderTitle>
      <SearchBar refetchGetIpsData={refetchGetIpsData} />
      <IpsListComponent
        dataIpsFromWhiteList={dataIpsFromWhiteList}
        isLoading={isLoading}
      />
      <PaginationExample
        setPage={setPage}
        totalPages={totalPages}
        page={page}
      />
    </Box>
  );
};

export default IpAddresses;
