const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuPropsSelectExperts = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10.7 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
