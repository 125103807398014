import React from 'react'
import { Box } from '@mui/material'
import BackButton from '../../../common/BackButton'
import CreateUpdateTemlateForm from '../../components/createUpdateTemlateForm'

const CreateUpdateButtonTemplates = () => {
  return (
    <Box>
      <BackButton />
      <CreateUpdateTemlateForm />
    </Box>
  )
}

export default CreateUpdateButtonTemplates
