import { useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import globalDataCTX from "../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../contexts/globalContext/types";
import { UserCTX } from "../../../contexts/userContext/UserCTX";
import {
  setAccessToken,
  setRefreshToken,
} from "../../../sevices/securityService/securityServiceHelper";
import { LOGIN_MUTATION } from "./graphql/LOGIN_MUTATION.graphql";

export const useLoginForm = () => {
  const navigate = useNavigate();
  const { setNotificationMsg, setNotificationEvent } =
    useContext(globalDataCTX);
  const [showPassword, setShowPassword] = useState(false);
  const { setUserID, setIsUserLoggedIn } = useContext(UserCTX);
  const [
    loginHandler,
    { data: loginData, loading: loginLoading, error: loginError },
  ] = useMutation(LOGIN_MUTATION);

  useEffect(() => {
    if (loginError) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(loginError.message);
    }

    if (!loginError && loginData && !loginLoading) {
      document.addEventListener("token_changed", () => {
        setIsUserLoggedIn(!!localStorage.getItem("access_token"));
      });
      setUserID(loginData.login.userID);
      setAccessToken(loginData.login.tokens.accessToken);
      setRefreshToken(loginData.login.tokens.refreshToken);
      navigate("/dashboard", { replace: true });
      setNotificationEvent(notificationEventType.noEvent);
    }
  }, [
    loginData,
    loginError,
    loginLoading,
    navigate,
    setIsUserLoggedIn,
    setNotificationEvent,
    setNotificationMsg,
    setUserID,
  ]);

  return {
    showPassword,
    setShowPassword,
    loginHandler,
    loginLoading,
  };
};
