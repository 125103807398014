import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { SelectCheckBoxProps } from "./types";
import { MenuProps } from "./constats";
import { FC } from "react";

const SelectCheckBox: FC<SelectCheckBoxProps> = ({
  setFieldValue,
  valuesKey,
  placeholder,
  initialValue,
}) => {
  const dataAffectedObjectsList = ["balance", "calls", "chats", "video_calls"];

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    setFieldValue(
      valuesKey,
      typeof value === "string" ? value.split(",") : value
    );
  };

  const isChecked = (item: string) => {
    return !!initialValue.find((initItem) => {
      if (initItem === item) {
        return true;
      }
      return false;
    });
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-checkbox-label">{placeholder}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={initialValue}
          onChange={handleChange}
          input={<OutlinedInput label={placeholder} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {dataAffectedObjectsList?.map((item) => {
            return (
              <MenuItem key={item} value={item} disabled={item !== "balance"}>
                <Checkbox checked={isChecked(item)} />
                <ListItemText primary={item} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectCheckBox;
