import React, { Fragment } from "react";
import BasicModal from "../common/modal";
import UserCard from "./components/userCard/UserCard";
import FreeMinutesContentModal from "./components/freeMinutesContentModal";
import BlockUserContentModal from "./components/blockUserContentModal";
import ClientSearchPanel from "./components/clientSearchPanel/ClientSearchPanel";
import { StyledContentBlock } from "./styles";
import { useClient } from "./useClient";
import { Typography } from "@mui/material";
import { editTypesEnum } from "./types";
import BalanceClientContentModal from "./components/balanceClientContentModal";
import { HeaderTitle, StyledDivider } from "../../styles/common/styles";
import { grey } from "@mui/material/colors";
import ParticipationStatusBlock from "./components/participationStatus";
import DeleteClientAccount from "./components/deleteClientAccount";

const Clients = () => {
  const {
    searchTextId,
    userData,
    initUserData,
    isSomeEditClient,
    openModal,
    reasonForFreeMinutes,
    freeMinutesAmount,
    selectedUsers,
    singleEditClientData,
    userDataReferralStatus,
    setSearchTextId,
    getUserHandler,
    setReasonForFreeMinutes,
    setFreeMinutesAmount,
    removeUserHandler,
    checkUserHandler,
    openModalHandler,
    setFreeMinutersHandler,
    setSingleEditClientData,
    closeModalHandler,
  } = useClient();

  return (
    <>
      <HeaderTitle>Клиенты</HeaderTitle>
      <ClientSearchPanel
        searchTextId={searchTextId}
        setSearchTextId={setSearchTextId}
        getUserHandler={getUserHandler}
        isSomeEditClient={isSomeEditClient}
        openModalHandler={openModalHandler}
        selectedUsers={selectedUsers}
      />

      <StyledContentBlock>
        {userData.length ? (
          userData.map((itemUser) => {
            return (
              <Fragment key={itemUser.user.id}>
                <UserCard
                  userId={itemUser?.user.id}
                  image={itemUser?.user?.image.onex}
                  name={itemUser?.user.name}
                  balance={itemUser.balance.amount}
                  freeMinutesLeft={itemUser.user.freeMinutesLeft}
                  loginType={itemUser.user.loginType}
                  removeUserHandler={removeUserHandler}
                  checkUserHandler={checkUserHandler}
                  isChecked={itemUser.checked}
                  openModalHandler={openModalHandler}
                  isSomeEditClient={isSomeEditClient}
                  setSingleEditClientData={setSingleEditClientData}
                  blocked={itemUser.blocked}
                  balanceCurrency={itemUser?.balance?.currency}
                  session={itemUser?.HasActiveSession}
                  userDataReferralStatus={userDataReferralStatus}
                  isDeleted={itemUser.user.isDeleted}
                />
              </Fragment>
            );
          })
        ) : (
          <Typography color={grey[400]}>
            Нам нужно найти клиента по ID
          </Typography>
        )}
      </StyledContentBlock>

      <BasicModal
        isOpen={openModal.status}
        handelClose={closeModalHandler}
        title={
          openModal.modalName === editTypesEnum.SOME_EDIT
            ? "Редактировать данные клиентов"
            : "Редактировать данные клиента"
        }
        width="85%"
        children={
          <>
            <StyledDivider>
              Добавляем или снимаем бесплатные минуты
            </StyledDivider>

            <FreeMinutesContentModal
              amount={freeMinutesAmount}
              reason={reasonForFreeMinutes}
              setAmount={setFreeMinutesAmount}
              setReason={setReasonForFreeMinutes}
              userImage={singleEditClientData.image}
              userData={selectedUsers}
              setAmountHandler={setFreeMinutersHandler}
              openModalName={openModal.modalName}
            />

            {openModal.modalName !== editTypesEnum.SOME_EDIT && (
              <>
                <StyledDivider>Баланс</StyledDivider>
                <BalanceClientContentModal
                  singleEditClientData={singleEditClientData}
                />
              </>
            )}

            {openModal.modalName !== editTypesEnum.SOME_EDIT && (
              <>
                <StyledDivider>
                  Редактирование статуса для аффилиата
                </StyledDivider>
                <ParticipationStatusBlock
                  singleEditClientData={singleEditClientData}
                  userDataReferralStatus={
                    userDataReferralStatus?.getUserReferralStatus
                  }
                />
              </>
            )}

            {openModal.modalName !== editTypesEnum.SOME_EDIT && (
              <>
                <StyledDivider>
                  Блокировка / Разблокировка клиентов
                </StyledDivider>
                <BlockUserContentModal
                  initUserData={initUserData}
                  singleEditClientData={singleEditClientData}
                />
              </>
            )}
            <>
              <StyledDivider textColor="red">Удалить аккаунт</StyledDivider>
              <DeleteClientAccount clientId={singleEditClientData.id} />
            </>
          </>
        }
      />
    </>
  );
};

export default Clients;
