import { gql } from "@apollo/client";

export const GET_PAYMENTS = gql`
  query getPayments(
    $sorting: SortParameters
    $limit: Int
    $params: PaymentsFilter
  ) {
    getPayments(limit: $limit, sorting: $sorting, params: $params) {
      payments {
        id
        amount {
          amount
          currency
        }
        state
        provider
        externalID
        datetime
        card {
          number
        }
      }
    }
  }
`;
