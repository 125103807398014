import { FC } from "react";
import { Stack } from "@mui/material";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { $colorVariables } from "../../../styles/colorVariables";
import IconComponent from "../../common/icon";
import ControlButtons from "../../common/controlButton";
import { usePinReviewsController } from "./usePinReviewsController";
import {
  StyledDoneLongButton,
  StyledEditButton,
  StyledDeletLongButton,
} from "../reviewsControllersGroupButtons/styles";
import { PinReviewsControllerProps } from "./types";

const PinReviewsController: FC<PinReviewsControllerProps> = ({
  isPinned,
  reviewID,
}) => {
  const {
    pinReviewHandler,
    unPinReviewHandler,
    show,
    toggleShowControlButton,
  } = usePinReviewsController(reviewID);

  const HandlerChangeStatus = () => {
    if (isPinned) {
      return (
        <Stack>
          <StyledDoneLongButton variant="contained" color="success">
            <IconComponent color={$colorVariables.$green01} icon={faCheck} />
          </StyledDoneLongButton>
          <StyledEditButton onClick={toggleShowControlButton}>
            Изменить
          </StyledEditButton>
        </Stack>
      );
    }

    return (
      <Stack>
        <StyledDeletLongButton variant="contained">
          <IconComponent color={$colorVariables.$red} icon={faXmark} />
        </StyledDeletLongButton>
        <StyledEditButton onClick={toggleShowControlButton}>
          Изменить
        </StyledEditButton>
      </Stack>
    );
  };

  return (
    <Stack direction="row">
      {show ? (
        <ControlButtons
          firstClickHandler={pinReviewHandler}
          secondClickHandler={unPinReviewHandler}
        />
      ) : (
        <HandlerChangeStatus />
      )}
    </Stack>
  );
};

export default PinReviewsController;
