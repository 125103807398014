import React, { FC } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { PaymentCardProps } from "./types";
import { seconsdConvertToDate } from "../../../../utils/useDate";
import { PaymentProvider } from "../../../../globalTypes";

const PaymentCard: FC<PaymentCardProps> = ({
  paymentData,
  loadingPage,
  setisOpenModal,
  setCurrentRefundPaymentId,
}) => {
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>ID {paymentData.id}</Typography>
        <Typography>{paymentData.state}</Typography>
        <Typography>
          {seconsdConvertToDate(paymentData.datetime, "D MMM YYYY HH:mm")}
        </Typography>
        <Typography>{paymentData.provider}</Typography>
        <Typography>
          {paymentData.amount.amount} {paymentData.amount.currency}
        </Typography>
      </CardContent>

      <CardActions sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          disabled={
            loadingPage ||
            paymentData.provider === PaymentProvider.CloudPayments
          }
          variant="contained"
          color="info"
          onClick={() => {
            setisOpenModal(true);
            setCurrentRefundPaymentId(paymentData.id);
          }}
        >
          Отменить транзакцию
        </Button>
      </CardActions>
    </Card>
  );
};

export default PaymentCard;
