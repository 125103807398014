import { styled } from "@mui/system";
import { NavLink } from "react-router-dom";

export const StyledNavLink = styled(NavLink)({
  textDecoration: "none",
  color: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  padding: '0 6px'
});
