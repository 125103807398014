import { useMutation, useQuery } from "@apollo/client";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { CREATE_CHASHBACK_RULES } from "../../graphql/CREATE_CHASHBACK_RULES";
import {
  createCashbackRule,
  createCashbackRuleVariables,
} from "../../graphql/__generated__/createCashbackRule";
import { GET_BONUSES_LIST_FOR_CACH_BACK } from "../../graphql/GET_BONUSES_LIST_FOR_CACH_BACK";
import {
  getBonusesListForCacheBackCreateUpdate,
  getBonusesListForCacheBackCreateUpdateVariables,
} from "../../graphql/__generated__/getBonusesListForCacheBackCreateUpdate";
import { useLocation } from "react-router-dom";
import { Enum_inputs } from "./types";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";
import { UPDATE_CHASHBACK_RULES } from "../../graphql/UPDATE_CHASHBACK_RULES";
import {
  updateCashbackRule,
  updateCashbackRuleVariables,
} from "../../graphql/__generated__/updateCashbackRule";

export const useCreateUpdateCashBackForm = () => {
  //data for update
  const { state } = useLocation();
  const isUpdatePage = !!state?.cashBackDataForUpdate;
  const cacheBackDataForUpdate = state?.cashBackDataForUpdate;

  //global context state

  const { setLoading, setNotificationMsg, setNotificationEvent } =
    useContext(globalDataCTX);

  //state
  const [lowerBoundary, setLowerBoundary] = useState(
    cacheBackDataForUpdate ? cacheBackDataForUpdate.lowerBoundary : "1000"
  );
  const [upperBoundary, setUpperBoundary] = useState(
    cacheBackDataForUpdate ? cacheBackDataForUpdate.upperBoundary : ""
  );
  const [bonusId, setBonusId] = useState<string>(
    cacheBackDataForUpdate ? cacheBackDataForUpdate.bonusID : ""
  );

  const [isActiveCashBack, setIsActiveCashBack] = useState<boolean>(
    cacheBackDataForUpdate ? cacheBackDataForUpdate.isActive : false
  );

  //querys
  const [
    createCashBackMutation,
    {
      data: createCashBackData,
      loading: createCashBackLoading,
      error: createCashBackError,
    },
  ] = useMutation<createCashbackRule, createCashbackRuleVariables>(
    CREATE_CHASHBACK_RULES
  );

  const [
    updateCashBackMutation,
    {
      data: updateCashBackData,
      loading: updateCashBackLoading,
      error: updateCashBackError,
    },
  ] = useMutation<updateCashbackRule, updateCashbackRuleVariables>(
    UPDATE_CHASHBACK_RULES
  );

  const { data: bonusesListData, loading: bonusesListLoading } = useQuery<
    getBonusesListForCacheBackCreateUpdate,
    getBonusesListForCacheBackCreateUpdateVariables
  >(GET_BONUSES_LIST_FOR_CACH_BACK, { variables: { filterBonus: {} } });

  //functions

  useEffect(() => {
    const isLoading =
      bonusesListLoading || createCashBackLoading || updateCashBackLoading;
    setLoading(isLoading);

    if (createCashBackData && !createCashBackLoading && !createCashBackError) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Успешно добавлено");
    } else if (
      updateCashBackData &&
      !updateCashBackLoading &&
      !updateCashBackError
    ) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Успешно обновлено");
    } else if (createCashBackError || updateCashBackError) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(
        createCashBackError?.message || updateCashBackError?.message || ""
      );
    }
  }, [
    createCashBackData,
    createCashBackLoading,
    createCashBackError,
    bonusesListLoading,
    updateCashBackData,
    updateCashBackLoading,
    updateCashBackError,
    setLoading,
    setNotificationEvent,
    setNotificationMsg,
  ]);

  const onChangeInputsHandler = (
    event: ChangeEvent<HTMLInputElement> | any,
    input: Enum_inputs
  ) => {
    switch (input) {
      case Enum_inputs.LOWER_BOUNDARY:
        setLowerBoundary(event.target.value);
        break;
      case Enum_inputs.UPPER_BOUNDARY:
        setUpperBoundary(event.target.value);
        break;
      case Enum_inputs.BONUS_ID:
        setBonusId(event.target.value);
        break;
      case Enum_inputs.IS_ACTIVE:
        setIsActiveCashBack(event.target.checked);
        break;
      default:
        break;
    }
  };

  const createUpdateHandler = () => {
    if (isUpdatePage) {
      updateCashBackMutation({
        variables: {
          input: {
            id: cacheBackDataForUpdate.id,
            isActive: isActiveCashBack,
            bonusID: bonusId,
            lowerBoundary,
            upperBoundary,
          },
        },
      });
    } else {
      createCashBackMutation({
        variables: {
          input: {
            bonusID: bonusId,
            lowerBoundary: Number(lowerBoundary),
            upperBoundary: Number(upperBoundary),
          },
        },
      });
    }
  };

  const isDisabledButton = lowerBoundary && upperBoundary && bonusId;

  return {
    onChangeInputsHandler,
    createUpdateHandler,
    setIsActiveCashBack,
    isActiveCashBack,
    lowerBoundary,
    upperBoundary,
    bonusesListData: bonusesListData?.getBonuses,
    isDisabledButton,
    isUpdatePage,
    bonusId,
  };
};
