import React from "react";
import BackButton from "../../../common/BackButton";
import CreateUpdateExpertForm from "./createUpdateExpertForm";
import { Avatar, Box } from "@mui/material";
import { useCreateUpdateExpert } from "./useCreateUpdateExpert";
import Loader from "../../../common/loader";

const CreateUpdateExpertPage = () => {
  const { expertDataForUpdate, loadingExpertData } = useCreateUpdateExpert();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <BackButton />
        {expertDataForUpdate && (
          <Avatar
            src={expertDataForUpdate.image.onex}
            sx={{ width: "100px", height: "100px" }}
          />
        )}
      </Box>
      {loadingExpertData ? (
        <Loader />
      ) : (
        <Box mt={4}>
          <CreateUpdateExpertForm expertDataForUpdate={expertDataForUpdate} />
        </Box>
      )}
    </>
  );
};

export default CreateUpdateExpertPage;
