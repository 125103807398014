import React from "react";
import { faCamera, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { $colorVariables } from "../../../../../../../../styles/colorVariables";
import IconComponent from "../../../../../../../common/icon";
import { uploadImagesCoverTypes } from "../constants";
import { StyledBlockUploadElements, StyledContainerUploadElements } from "../styles";
import { useUploadImages } from "./useUploadImages";
import { UploadImagesProps } from "./types";
import { StyledDivider } from "../../../../../../../../styles/common/styles";
import {
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Avatar,
} from "@mui/material";

const UploadImages = ({ expertId, coverImages }: UploadImagesProps) => {
  const {
    coverType,
    fileImage,
    uploadImageHandler,
    handleInputFileChange,
    handleCoverTypeChange,
    loadingImage,
    resetStateAudioFileHandler,
    disabledResetButton,
  } = useUploadImages(expertId);

  return (
    <>
      <StyledContainerUploadElements>
        <Box width={"100%"}>
          <StyledDivider sx={{ mt: 0 }} textAlign="right">Добавить изображения эксперту</StyledDivider>
          <StyledBlockUploadElements>
            <FormControl sx={{ width: "200px", }}>
              <InputLabel id="demo-simple-select-label">
                {coverType || "Тип обложки"}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={coverType}
                label={coverType || "Тип обложки"}
                onChange={handleCoverTypeChange}
              >
                {uploadImagesCoverTypes.map((coverType) => (
                  <MenuItem key={coverType} value={coverType}>
                    {coverType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <IconButton color="primary" component="label" sx={{ padding: "0px" }}>
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleInputFileChange}
              />
              <Typography sx={{ mr: 2 }}>Загрузить файл</Typography>
              <IconComponent icon={faCamera} />
            </IconButton>

            {fileImage && (
              <Typography
                sx={{
                  color: $colorVariables.$blue02,
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Имя файла: {fileImage?.name}
              </Typography>
            )}

            <Box display={"flex"} gap={1} >
              <Button
                onClick={resetStateAudioFileHandler}
                disabled={!disabledResetButton}
                variant="contained"
              >
                <Typography>{!loadingImage ? "Очистить" : "Загрузка..."}</Typography>
              </Button>

              <Button
                onClick={uploadImageHandler}
                disabled={!fileImage || !coverType}
                variant="contained"
              >
                <Typography sx={{ mr: 1 }}>
                  {!loadingImage ? "Сохранить" : "Загрузка..."}
                </Typography>
                <IconComponent
                  icon={faPaperPlane}
                  size={"2x"}
                  title="send"
                  cursor={"pointer"}
                />
              </Button>
              {coverImages && Object.values(coverImages).map((img, idx) => {
                if (Object.keys(coverImages)[idx] === "__typename") {
                  return <></>
                }

                return (
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    {Object.keys(coverImages)[idx]}
                    <Avatar src={img || ""} variant="rounded" />
                  </Box>
                )
              })}
            </Box>
          </StyledBlockUploadElements>
        </Box>
      </StyledContainerUploadElements>
    </>
  );
};

export default UploadImages;