import { QueryOptions, useMutation } from "@apollo/client";
import { useState } from "react";
import { DELETE_REVIEW } from "../graphql/DELETE_REVIEW.graphql";
import { GET_ALL_REVIEWS } from "../graphql/GET_ALL_REVIEWS.graphql";
import { PUBLISH_REVIEWS } from "../graphql/PUBLISH_REVIEWS.graphql";
import {
  deleteReview,
  deleteReviewVariables,
} from "../graphql/__generated__/deleteReview";

export const useReviewsControlButtons = (reviewID: number) => {
  const [show, setShow] = useState(false);

  const [deleteReview] = useMutation<deleteReview, deleteReviewVariables>(
    DELETE_REVIEW,
    {
      variables: {
        reviewID,
      },
      refetchQueries: [
        {
          query: GET_ALL_REVIEWS,
        } as QueryOptions,
      ],
    }
  );

  const [publishReview] = useMutation<deleteReview, deleteReviewVariables>(
    PUBLISH_REVIEWS,
    {
      variables: {
        reviewID,
      },
      refetchQueries: [
        {
          query: GET_ALL_REVIEWS,
        } as QueryOptions,
      ],
    }
  );

  const toggleShowControlButton = () => {
    setShow((prevStatus) => {
      return !prevStatus;
    });
  };

  const handlePublishReview = () => {
    publishReview();
    setShow(!show);
  };

  const handledeleteReview = () => {
    deleteReview();
    setShow(!show);
  };

  return {
    handledeleteReview,
    handlePublishReview,
    toggleShowControlButton,
    show,
  };
};
