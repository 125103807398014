import { FC } from "react";
import { Skeleton, TableHead, TableRow } from "@mui/material";
import { mockDataPromoCodesHeader } from "./constants";
import { StyledTableCell } from "../../../../../../styles/common/styles";

const BonusTableHeader: FC<any> = ({ loading }) => {
  return (
    <TableHead>
      <TableRow>
        {mockDataPromoCodesHeader.map(({ name }) => {
          return !loading ? (
            <StyledTableCell key={name} align="left">
              {name}
            </StyledTableCell>
          ) : (
            <StyledTableCell key={name} align="left">
              <Skeleton animation="wave" />
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default BonusTableHeader;
