import React, { FC } from "react";
import { InputLabel, MenuItem, FormControl } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BonusTypesSelectProps } from "./types";
import { BonusType } from "../../../../../../globalTypes";

const BonusTypeSelectInput: FC<BonusTypesSelectProps> = ({
  setFieldValue,
  valuesKey,
  initialValue,
}) => {
  const handleChange = (event: SelectChangeEvent<BonusType>) => {
    setFieldValue(valuesKey, event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Тип бонуса</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={initialValue}
        label="Тип бонуса"
        onChange={handleChange}
      >
        <MenuItem value={BonusType.deposit}>{BonusType.deposit}</MenuItem>
        <MenuItem value={BonusType.sale} disabled={true}>
          {BonusType.sale}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
export default BonusTypeSelectInput;
