export const ipSectionsNamesForTabelHeader = [
  {
    name: " IP-адрес",
  },
  {
    name: "Создан ",
  },
  {
    name: "Обновлено",
  },
  {
    name: "Раз. учетных записей",
  },
  {
    name: "Причина",
  },
];
