import { DateNavigator } from "@devexpress/dx-react-scheduler-material-ui";
import styled from "@emotion/styled";
import { $colorVariables } from "../../../../styles/colorVariables";

export const StyledDateNavigator = styled(DateNavigator.NavigationButton)(
  ({ theme, type }) => ({
    [`&`]: {
      "& svg": {
        display: "none",
      },
      "& span": {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage:
          type === "back"
            ? "url('/assets/Left_icon.svg')"
            : "url('/assets/Right_icon.svg')",
      },
      backgroundColor: $colorVariables.$white,
      border: `1px solid ${$colorVariables.$blue05}`,
      margin: "8px",
      width: "40px",
      height: "40px",
    },
  })
);
