import React, { FC } from "react";
import { InputLabel, MenuItem, FormControl } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ExpertActivityStatusSelectProps } from "./types";
import { ExpertActivityStatus } from "../../../../../../../globalTypes";

const ExpertActivityStatusSelect: FC<ExpertActivityStatusSelectProps> = ({
  initialValue,
  setExpertActivityStatus,
}) => {
  const handleChange = (event: SelectChangeEvent<ExpertActivityStatus>) => {
    setExpertActivityStatus(event.target.value as ExpertActivityStatus);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Статус активности</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={!initialValue ? "" : initialValue}
        onChange={handleChange}
        label="Статус активности"
      >
        <MenuItem value={ExpertActivityStatus.active}>
          {ExpertActivityStatus.active}
        </MenuItem>
        <MenuItem value={ExpertActivityStatus.inactive}>
          {ExpertActivityStatus.inactive}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
export default ExpertActivityStatusSelect;
