import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, TextField } from "@mui/material";
import { useDataPicker } from "./useDataPicker";
import DataPickerIcon from "../../../../../assets/icons/DataPickerIcon";
import { ReviewsActionTypes } from "../../../../../contexts/reviewsConext/types";

const DatePickers = () => {
  const { handleChange, changeLaterThanDate, changeEarlierThanDate } =
    useDataPicker();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display={"flex"}>
        <DatePicker
          components={{
            OpenPickerIcon: DataPickerIcon,
          }}
          inputFormat="DD.MM.YYYY"
          value={changeLaterThanDate}
          onChange={(event: Dayjs | null) =>
            handleChange(ReviewsActionTypes.LATER_THAN, event)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{
                width: "160px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "12px 0px 0px 12px",
                },
              }}
            />
          )}
        />
        <DatePicker
          components={{
            OpenPickerIcon: DataPickerIcon,
          }}
          inputFormat="DD.MM.YYYY"
          value={changeEarlierThanDate}
          onChange={(event: Dayjs | null) =>
            handleChange(ReviewsActionTypes.EARLIER_THAN, event)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{
                width: "160px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "0px 12px 12px 0px",
                },
              }}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DatePickers;
