export enum notificationEventType {
  error = "error",
  success = "success",
  noEvent = "noEvent",
}

export type GlobalDataContextType = {
  notificationEvent: notificationEventType;
  setNotificationEvent: (event: notificationEventType) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  notificationMsg: string;
  setNotificationMsg: (msg: string) => void;
};
