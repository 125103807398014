import { SelectChangeEvent } from "@mui/material";
import { ReferralProgramStatus } from "../../../../globalTypes";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_AFFILIATE_REFERRAL_PARTICIPATION } from "../../graphql/UPDATE_AFFILIATE_REFERRAL_PARTICIPATION";
import {
  updateAffiliateReferralParticipation,
  updateAffiliateReferralParticipationVariables,
} from "../../graphql/__generated__/updateAffiliateReferralParticipation";
import { singleEditClientType } from "../../types";
import { GET_USER_REFERRAL_STATUS } from "../../graphql/GET_USER_REFERRAL_STATUS.graphql";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";

export const useParticipationStatus = (
  singleEditClientData: singleEditClientType,
  userDataReferralStatus: string | undefined
) => {
  const [clientReferralProgramStatus, setClientReferralProgramStatus] =
    useState(userDataReferralStatus === "active" ? ReferralProgramStatus.active : ReferralProgramStatus.blocked);


  const {setNotificationEvent, setNotificationMsg } =
  useContext(globalDataCTX);

  const handleChange = (event: SelectChangeEvent) => {
    setClientReferralProgramStatus(event.target.value as ReferralProgramStatus);
  };

  const [updateReferalParticipationStatus, { data: dataParticipationData ,loading: loadingParticipation, error: errorParticipationData }] =
    useMutation<
      updateAffiliateReferralParticipation,
      updateAffiliateReferralParticipationVariables
    >(UPDATE_AFFILIATE_REFERRAL_PARTICIPATION);

    useEffect(() => {
      if (dataParticipationData && !loadingParticipation && !errorParticipationData) {
        setNotificationEvent(notificationEventType.success)
        setNotificationMsg("статус успешно изменен");
      }
    },[dataParticipationData, errorParticipationData, loadingParticipation, setNotificationEvent, setNotificationMsg])

  const updateReferalParticipationStatusHandler = () => {
    updateReferalParticipationStatus({
      variables: {
        userID: singleEditClientData.id,
        status: clientReferralProgramStatus,
      },
      refetchQueries: [
        {
          query: GET_USER_REFERRAL_STATUS,
          variables: { userID: singleEditClientData.id },
        },
      ],
    });
  };

  return {
    handleChange,
    clientReferralProgramStatus,
    updateReferalParticipationStatusHandler,
    loadingParticipation,
  };
};
